.RadioSelect {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.RadioButton {
  margin-bottom: 10px;
  &:not(last-child) {
    margin-right: 25px;
  }
}
