@import '../../css-variables/variables.css';

.SectionDividerHorz {
  width: 100%;
  max-width: 1250px;
  border-top: none;
  margin: 33px auto;
  border-bottom: var(--hc-border);
  box-sizing: border-box;
}
