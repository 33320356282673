@import '../../css-variables/variables.css';

.CopyInput {
  height: 40px;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: var(--hc-border-radius);
}

.InputCell {
  align-self: stretch;
  flex: 1;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  padding: 0 15px 0 15px;
  overflow-y: hidden;
}

.Input {
  line-height: 38px;
  height: 38px;
  border: none;
  font-weight: 300;
  font-family: 'Avenir', sans-serif;
  width: 100%;
}

.CopyCell {
  position: relative;
  flex: 0 1 130px;
  width: 130px;
}

.Button {
  width: 130px;
  min-width: 130px;
  height: 100%;
  text-align: center;
  text-transform: capitalize;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 900;
  font-family: var(--hc-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  padding: 1em 1.25em;
  position: relative;
  transition: transform 0.125s, border 0.45s, background 0.45s, color 0.45s,
    -webkit-transform 0.125s, -webkit-box-shadow 0.125s;
  border-radius: 0.1875rem;
  line-height: 1.15;
  margin: 0;
  text-decoration: none;
  /* Default Primary Button Styles */
  background-color: #fff;
  border: 0 solid transparent;
  color: var(--hc-blue);

  &:hover {
    background-color: var(--hc-gray-light);
  }
}
