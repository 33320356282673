@import '../styles/common/variables.css-module.css';

.NumberRange {
  width: calc(50% - 20px);
}

.adjusterEnabled {
  width: 302px;
}
.Inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.InputCell {
  flex: 1;
}

.Input {
  width: 100%;
  border-radius: 4px;
  line-height: 40px;
  height: 40px;
  border: var(--hc-border);
  padding: 0 5px;
  flex: 1;
  box-sizing: border-box;
  font-family: var(--hc-font);
}

.InputDivider {
  text-align: center;
  flex: 0 0 20px;
  font-weight: 500;
}

.Adjuster {
  font-size: 13px;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  opacity: 1;
  transition: opacity 0.3s;
}

.AdjusterButton {
  flex: 0 0 50px;
  user-select: none;
  margin-right: 9px;
  &:last-child {
    margin-right: 0px;
  }
  > button {
    font-family: 'Avenir', sans-serif;
    height: 40px;
    line-height: 40px;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    letter-spacing: 0px;
    font-weight: 500;
    font-size: 14px;
    background-color: #fff;
    cursor: pointer;
    border: var(--hc-border);
    border-radius: 3px;
    box-sizing: border-box;

    &.loading {
      text-align: left;
      padding-left: 18px;
      cursor: not-allowed;
      &:after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        animation: ellipsis steps(4, end) 2000ms infinite;
        content: '\2026'; /* ascii code for the ellipsis character */
        width: 0px;
      }
    }
  }
}

.active {
  border-color: var(--hc-orange) !important;
}

.AdjusterLabel {
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 300;
}

.disabledAdjuster {
  opacity: 0.5;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
