@import '../../css-variables/variables.css';

.RangeChart {
  width: 100%;
}

.ValueTop {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}

.ValueBottom {
  font-size: 12px;
  font-weight: 500;
  color: var(--text-light-color);
}

.Bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 12px;
  border-radius: 6px;
  background-image: linear-gradient(to right, #c0ee83, #11ddb3);
  margin-bottom: 8px;
}

.Labels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.Label {
  font-weight: 700;
  font-size: 12px;
}

.MainRow {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.MainIndicator {
  background-color: #7de69b;
  height: 14px;
  width: 3px;
  border-radius: 25%;
}

.HighLowLabels {
  display: flex;
  text-align: left;
  justify-content: space-between;
  & > div:last-child {
    text-align: right;
  }
}
