@import '../styles/common/variables.css-module.css';

:root {
  /* Certain sections of the page "pop out", extending to the left and right of the
   * main content column at large screen widths
   */
  --popoutSectionMaxWidth: 1550px;
  --mainContentMaxWidth: 1250px;
  --popoutSectionDiff: calc(
    var(--popoutSectionMaxWidth) - var(--mainContentMaxWidth)
  );
  --popoutSectionNegativeMargin: calc(var(--popoutSectionDiff) * -1 / 2);
}

.SubjectPhotoCard {
  position: absolute;
  bottom: -30px;
  left: 33px;
  z-index: 2;
}

.page-wrapper {
  padding-bottom: 60px;
  background-color: white;
  z-index: 1;
}
.property-explorer-page {
  color: var(--text-color);
  max-width: 1250px;
  margin: auto;
  width: 100%;
  padding: 0 0 80px 0;
  box-sizing: border-box;

  & hr {
    width: 100%;
    border-top: none;
    margin: 33px 0;
    border-bottom: var(--hc-border);
    max-width: 100%;
    box-sizing: border-box;
  }
}
.top-map-section {
  max-width: 100%;
  position: relative;
  height: 550px;
  margin-bottom: 60px;
  &.without-photo {
    margin-bottom: 50px;
  }
}
.top-map-section--map {
  position: absolute;
  top: 0;
  left: var(--popoutSectionNegativeMargin);
  right: var(--popoutSectionNegativeMargin);
  bottom: 0;
  overflow: hidden;
  box-shadow: 0px 0px 3px #efefef;
  z-index: 1;
  /* Placeholder for map while subject loads */
  background-color: #ececec;
}
.ValueLoading {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 100px;
}
.cobranding-section {
  margin: 25px 33px 10px 33px;
}
.mobile-page-title {
  display: none;
}
.static-details-value-section {
  display: flex;
  position: relative;
}
.page-title-section {
  position: absolute;
  display: flex;
  justify-content: center;
  right: 10px;
  bottom: 10px;
  z-index: 1;
  animation: var(--animation-fade-in);
}
.page-title-section--heading {
  font-size: 30px;
  margin: 0 0 0 10px;
  text-transform: uppercase;
  font-weight: 900;
}
.page-title-section--icon {
  width: 30px;
  /* IE11 needs a defined height */
  height: 32px;
}
.static-details-section:not(:only-child) {
  padding-left: 60px;
  padding-top: 10px;
}
.value-section {
  width: 380px;
  max-width: 380px;
  flex: 1 0 380px;
  padding-right: 60px;
  border-right: var(--hc-border);
  justify-content: flex-start;
  text-align: center;
}
.edit-details-forecast-section {
  justify-content: space-between;
}
.edit-details-section {
  flex: 1 0 450px;
  max-width: 768px;
}
.forecast-chart {
  padding-left: 60px;
  box-sizing: border-box;
  justify-content: flex-start;
  width: calc(100% - 450px);
}
.section-heading {
  composes: section-heading from './Common.css-module.css';
}
.show-mobile {
  display: none;
}
.share-buttons-section {
  margin: 20px 0 15px;
}
.PageDividerInSection {
  /* Adjust page divider size and position to account for section padding */
  width: calc(100% + 66px) !important;
  margin-left: -33px !important;
  max-width: none !important;
}
.map-container {
  width: 100%;
  height: 350px;
  background: #ececec;
  position: relative;
  margin-bottom: 10px;
  z-index: 1;
}

.Legal {
  text-align: center;
}

/* Full-width popout sections */
@media (max-width: 1550px) {
  .top-map-section {
    height: 400px;
  }
  .top-map-section--map {
    /* Dynamically calculate margins to keep this section full viewport width */
    left: calc(-1 * calc(100vw - var(--mainContentMaxWidth)) / 2);
    right: calc(-1 * calc(100vw - var(--mainContentMaxWidth)) / 2);
  }
}

/* When main container is full viewport width */
@media (max-width: 1250px) {
  .top-map-section--map {
    left: 0;
    right: 0;
  }
  .value-section {
    padding-right: 33px;
  }
  .static-details-section:not(:only-child) {
    padding-left: 33px;
  }
}

/* Forecast Chart */
@media (max-width: 1000px) {
  .edit-details-section {
    flex-basis: 100%;
    min-width: initial;
    width: 100%;
    flex: none;
  }
  .forecast-chart {
    width: 100%;
    max-width: 100%;
    margin: auto;
    padding-left: 0;
    flex: none;
  }
  .edit-details-forecast-section {
    flex-direction: column;

    .show-mobile {
      display: block;
    }
  }
}

/* Mobile */
@media (max-width: 970px) {
  .value-section {
    max-width: none;
  }
  .top-map-section {
    height: 250px;
    margin: 0 0 50px 0;
    &.without-photo {
      margin-bottom: 30px;
    }
  }
  .share-buttons-section {
    margin: 15px 0 15px;
  }
  .cobranding-section {
    margin: 25px 33px 5px 33px;
  }
  .page-title-section--heading {
    font-size: 20px;
    line-height: 30px;
    padding-right: 10px;
  }
  .static-details-value-section {
    margin-bottom: 10px;
    flex-direction: column;

    & .static-details-section,
    .value-section {
      width: 100%;
      flex: none;
      padding-left: 0;
      padding-right: 0;
      border-left: none;
      border-right: none;
    }
    & .value-section {
      flex: none;
      display: block;
      padding-left: 0;
      margin-bottom: 20px;
    }
  }
  .forecast-chart-chart {
    width: 100%;
    margin: 0 0 0 -20px;
  }
  .map-container {
    width: calc(100% + 66px);
    margin-left: -33px;
    height: 350px;
  }
  .hide-mobile {
    display: none;
  }
  .show-mobile {
    display: block;
  }
}
@media print {
  @page {
    /* Setting this larger page size results in the page printing the way that it
     * appears on a large screen */
    size: 12.75in 16.5in;
    margin: 0.5in 0.5in 0.5in 0.5in;
  }
  /* General print rules that we don't mind applying to the whole site */
  html,
  body {
    height: initial;
    overflow: visible !important;
  }
  /* Ensure that headings aren't separated from their content by a page break */
  h2,
  h3 {
    page-break-after: avoid;
  }
  button,
  a {
    display: none !important;
  }
  .edit-details-section,
  svg {
    page-break-inside: avoid;
  }
  /* Force browsers to print background images, for when background images are necessary */
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  .static-details-value-section .value-section {
    margin-bottom: 0;
  }
  .forecast-chart {
    width: 75vw;
  }
  .share-buttons-section {
    display: none;
  }
}
