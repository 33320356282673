.CompFilterBar {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.FiltersCell {
  position: relative;
  overflow: hidden;
  flex: 1;
  height: 100%;
  align-items: center;
  display: flex;
}

.MoreFilters {
  flex: 0;
  padding: 0 5px;
}
