@import '../../css-variables/variables.css';

.SizeToggle {
  display: flex;
  transition: opacity 0.5s;
  align-items: center;
  width: 204px;
}

.ToggleIcon {
  height: 18px;
  width: 18px;
  box-sizing: border-box;
  flex: 0 0 40px;
}

.PriceCell {
  flex: 1;
}

.hidden {
  display: none;
}

.SizeToggleCell {
  flex: 0 0 18px;
}

.SliderCell {
  flex: 0 0 140px;
  padding: 0 15px;
}

.ToggleIconBlock {
  float: left;
  margin: 1px;
  border-radius: 1px;
  background-color: var(--text-color);

  &.sm {
    width: 4px;
    height: 4px;
  }

  &.lg {
    width: 14px;
    height: 14px;
  }
}

.RangeSlider {
  .activeTrack {
    display: none;
  }

  .slider {
    background-color: var(--text-color);
    height: 18px;
    width: 18px;
    box-shadow: none;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    margin: 0;
  }
  .track {
    height: 2px;
    border-radius: 1px;
    background: var(--border-color);
    position: relative;

    &::after {
      display: none;
    }
  }
}
