.MapControlClusters {
  &.HorizontalToggle {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 600;

    &.hidden {
      visibility: hidden;
    }
  }
}

@media (max-width: 768px) {
  .MapControlClusters {
    &.HorizontalToggle {
      bottom: 130px;
    }
  }
}
