@import '../../css-variables/variables.css';

.activeTrack {
  flex: none;
  background: transparent;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  box-shadow: 0px 1px 6px #b7b7b7;
}

.labelContainer {
  background-color: #fff;
  padding: 0 5px;
}

.slider {
  height: 28px;
  width: 28px;
  transform: translate(-50%, -50%);
  background: var(--hc-orange);
  border: none;
  border-radius: 50%;
  box-shadow: 2px 3px 5px #cccccc;
  cursor: grab;
}

.track {
  display: flex;
  background: white;
  height: 6px;
  border-radius: 3px;
  background: linear-gradient(90deg, #f8db5c 0%, #feb913 100%);
  position: relative;

  &:not(.input-range__track--active)::after {
    content: '';
    flex: 1;
    background: white;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 0px 1px 6px #b7b7b7;
  }
}

.valueLabel,
.maxLabel,
.minLabel {
  display: none;
}

/* Unused classnames supported by library here for easy reference */
.sliderContainer {
}
.disabledInputRange {
}
.inputRange {
}
