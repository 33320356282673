@import '../styles/common/variables.css-module.css';

.CompsFilters {
  box-sizing: border-box;
  flex: 0 0 345px;
  box-sizing: border-box;
  background: #ffffff;
  position: relative;
  z-index: 3;
  overflow-x: hidden;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
}

.CompsFiltersWrapper {
  height: 100%;
  overflow: auto;
  padding: 10px 13px 15px 7px;
}

.ControlBar {
  height: 70px;
  box-sizing: border-box;
  z-index: 2;
  background: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  display: block;
  align-items: center;
  justify-content: space-between;
  max-width: 100% !important;
}

.SaveFiltersCell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
  padding: 0 15px 0 15px;
}

.SaveFilters {
  font-size: 14px;
  font-weight: 600;
}

.SaveFiltersCheckbox {
  position: relative;
  top: 4px;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.3;
}
