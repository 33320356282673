.AvmTooltip {
  max-width: 200px;
}

.Tooltip {
  margin: 0;
}

.Label {
  font-size: 12px;
  margin-top: 0;
}

.Description {
  font-size: 10px;
}
