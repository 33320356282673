/* Component-Lib Style Overrides */
.dropdown {
  display: inline-block;
}

.values {
  text-align: left;
}

.value {
  padding: 0;

  input {
    border-width: 0px 1px 0 0;
    border-radius: 4px 0px 0px 4px;
    border-color: #eaeaea !important;
  }
}
