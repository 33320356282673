.CompsMap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
}

.Map {
  flex: 1;
  position: relative;
}

.Controls {
  flex: 0 0 0px;
  transition: flex 0.3s;
  display: none;
  overflow: hidden;

  &.controlsVisible {
    flex: 0 0 120px;
    display: block;
  }
}
