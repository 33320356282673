.ClientIdSearch {
  display: flex;
  height: 100%;
  width: 100%;

  > input {
    flex: 1;
    padding: 0 15px;
  }
}

.Hits {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 0 0 4px 4px;
  max-height: calc(100vh - 300px);
  overflow: auto;
  box-sizing: border-box;
  &.noResults {
    padding: 15px;
  }
}

.Address {
  font-weight: normal;
  padding: 10px 15px 10px 30px;
  cursor: pointer;
  border-bottom: 1px solid #dedede;
  max-height: calc(100vh - 140px);
  &.active {
    background-color: #eaeaea;
  }
}

.Hit {
  text-align: left;
  font-weight: bold;
}

.HitLabel {
  padding: 15px 15px 5px 15px;
}

.Input {
  color: #4a4a4a;
  font-size: 16px;
  font-family: 'Avenir', sans-serif;
  &::placeholder {
    color: #d0d0d0;
  }
}
