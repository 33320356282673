/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(0px);
  }
  to {
    opacity: 1;
    transform: translateY(15px);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(0px);
  }
  to {
    opacity: 1;
    transform: translateY(-15px);
  }
}
:root {
  --animation-fade-in: fadeIn 0.3s;
  --animation-fade-in-down: fadeInDown 0.3s;
  --animation-fade-in-up: fadeInUp 0.3s;
}
