@import '~hc-pattern-lib/src/hc-pattern-lib';
$max-width: rem(400);
$min-width: rem(300);

.SignupPage {
  color: $dark-grey;
  height: 100%;
  width: 100%;

  .input {
    position: relative;
    margin-bottom: rem(10);
  }

  .inputElement {
    margin-bottom: rem(20);
  }

  .error {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.BlueGradHeader {
  background: #0a62ff !important;
}

.HeaderContainer {
  text-align: center;
  max-width: 500px !important;
  min-width: $min-width;

  * {
    color: #ffffff;
  }
}

.SignupPageContent {
  padding: 0 1rem;
}

.SignupForm {
  min-width: $min-width;
  max-width: $max-width;
  margin-top: rem(20);
  margin-left: auto;
  margin-right: auto;
}
