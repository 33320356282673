.TransactionDetails {
  display: inline;
  color: #494949;
  font-size: 16px;
  font-weight: 700;
}

.Bullet {
  display: inline-block;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  margin: 0 6px 1px 0;
}

.leased,
.sold {
  background-color: #fd8a5f;
}

.listed {
  background-color: #70d48a;
}

.pending {
  background-color: #feb913;
}
