@import '../../css-variables/variables.css';
.Skeleton {
  position: relative;
  background: transparent;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
}

.Animation {
  display: block;
  background-color: rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: var(--hc-border-radius);

  &:after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    animation: Wave 1.6s linear 0.5s infinite;
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.6),
      transparent
    );
  }
}

/* Children primarily used for sizing */
.Children {
  visibility: hidden;
}

.button {
  width: 100px;
  height: 40px;
  display: inline-block;
}

.input {
  height: 40px;
  width: 100%;
  display: inline-block;
}

.h1 {
  height: 60px;
  width: 100%;
  max-width: 600px;
  padding: 15px;
}

.text {
  height: 15px;
  width: 100%;
}

.fill {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}

@keyframes Wave {
  0% {
    transform: translateX(-100%);
  }
  50% {
    /* +0.5s of delay between each loop */
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}
