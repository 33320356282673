@import '../styles/common/variables.css-module.css';

.SelectFieldPropertyDetails {
  &.dropdown {
    top: -1px;
    width: 100%;
    max-width: 200px;

    .ValueWrapper .value {
      padding: 0 !important;
    }

    .ValueWrapper:after {
      top: 10px !important;
      border-color: var(--text-color) !important;
    }
  }
  input {
    height: 27px !important;
  }
}
