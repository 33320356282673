@import '../styles/common/variables.css-module.css';

.ThumbnailWrapper {
  display: none;
}

.PropertyCard {
  width: 100% !important;
  box-sizing: border-box;

  .ChildContentWrapper {
    padding: 0;
  }

  .PropertyDetailsWrapper {
    box-sizing: border-box;
    font-size: 14px;
    padding: 12px 0;
  }
}

.LimitedReport {
  font-size: 14px;
  font-weight: 300;
  border-top: var(--hc-border);
  width: 100%;
  line-height: 21px;
  padding: 30px 0 15px 0;
  box-sizing: border-box;
  margin-top: 4px;
}

.limitedNoListPrice {
  margin-top: 30px;
}

.LimitedReportHeading {
  font-weight: 900;
}
