.StreetAddress {
  font-size: 28px;
  font-weight: 900;
  line-height: 36px;
}

.CityStateZip {
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
  font-weight: 600;
}

.Details {
  padding-top: 10px;
  font-size: 14px;
  line-height: 25px;
  font-weight: 500;
  white-space: nowrap;
  & span {
    display: inline-block;
    vertical-align: middle;
  }
}

.Detail {
  margin-right: 25px;
}
