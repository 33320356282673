.listings--chip {
  display: inline-block;
  position: relative;
  left: 10px;
  width: 37px;
  box-sizing: border-box;
  height: 37px;
  border-radius: 50%;
  text-align: center;
  border: 10px;
  text-decoration: none;
  margin: 0px;
  padding: 0px;
  outline: none;
  background-color: rgb(224, 224, 224);
  white-space: nowrap;
  font-weight: bold;
  font-size: 10px;
  line-height: 38px;
  user-select: none;
}

.listings--heading-wrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.listings--heading {
  cursor: pointer;
  flex: 1;
  flex-basis: 50%;
}

.listings--open-icon {
  position: relative;
  left: 15px;
}

.rental-avm-outer-wrapper {
  display: flex;
  flex: 1;
  flex-basis: 50%;
  justify-content: flex-end;
  margin: 20px 0;
}
.rental-avm-inner-wrapper {
  flex: 0 0 0;
  flex-basis: 400px;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .rental-avm-outer-wrapper {
    flex-basis: 100%;
    margin-bottom: 0;
  }
  .rental-avm-inner-wrapper {
    flex: 1;
    flex-basis: 100%;
  }
  .listings--heading {
    flex-basis: 100%;
  }
}
