@import '../../../css-variables/variables.css';

.Popover {
  position: absolute;
  top: calc(100% - 15px);
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 12%) 0 1px 6px, rgb(0 0 0 / 12%) 0 1px 4px;
  border-radius: 2px;
  padding: 15px;
  z-index: 2001;
  display: block;
  animation: fadeInDown 0.3s;
  top: 32%;
  left: 23%;
}

.PopoverAccent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 15px;
  background-color: #555;
}

.AVMSelectorItem {
  padding: 30px 10px 0;
  width: 605px;
  vertical-align: top;
  white-space: normal;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.AVMSelectionItem {
  box-shadow: 0px 2px 15px 0px rgb(0 0 0 / 10%);
  white-space: normal;
  border-radius: 4px;
  position: relative;
  height: 155px;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 55px;
  width: calc(50% - 10px);
  padding: 15px 20px 35px 20px;
}

.AVMSelectionItem:nth-child(odd) {
  margin-right: 20px;
}

.AVMSelectionItem:nth-child(3) {
  text-align: center;
}
