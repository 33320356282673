@import '../styles/common/variables.css-module.css';

.Icon {
  svg {
    height: 20px;
    width: 20px;
  }
}

.CompsListViewControl {
  height: 40px;

  .Option {
    padding: 8px 20px;
  }
}

.Check {
  color: #3f3f3f;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  white-space: nowrap;
  padding: 5px 0;

  .CompsListViewSelectedControl {
    cursor: pointer;

    .check {
      height: 15px;
      width: 15px;
      margin-right: 5px;
      border-width: 2px;
      box-sizing: border-box;
      border-radius: 2px;
    }
  }
}

.SelectedMask {
  background-color: var(--hc-blue) !important;
}

.Selected {
  svg {
    > g {
      > path {
        fill: #fff !important;
      }
    }
  }
}
