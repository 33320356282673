@import '../styles/common/variables.css-module.css';

.DownloadReportButton {
  height: 30px;
  width: 30px;

  > div {
    margin: 0;
  }
}

.DownloadIcon {
  display: block;
  text-align: center;
}

.DownloadIconContainer {
  text-align: center;
}

.IconButton {
  margin-top: 2px;
  padding: 0px;
  display: flex;
  flex-direction: column;
}
