@import '../styles/common/variables.css-module.css';

.info-pill {
  display: flex;
  align-items: center;
  border: 0;
  padding: 8px 20px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  background: white;
  box-sizing: border-box;
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.info-message {
  margin-left: 5px;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-color);
}

.info-icon svg {
  width: 20px;
  height: 20px;
}
