@import '../../css-variables/variables.css';

@keyframes fadeInFlipped {
  from {
    opacity: 0;
    transform: translateY(-15px) scaleX(-1);
  }
  to {
    opacity: 1;
    transform: translateY(0) scaleX(-1);
  }
}

.ThumbnailCarousel {
  height: 100%;
  width: 100%;
  position: relative;
  font-family: var(--hc-font);
  user-select: none;
}

.CurrentImage {
  z-index: 2;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.Controls {
  height: 100%;
  width: 100%;
  position: relative;
}

.Controls > * {
  animation: var(--animation-fade-in);
  opacity: 1;

  &.right {
    animation: fadeInFlipped 0.3s;
  }
}

.Arrow {
  display: block;
  position: absolute;
  top: calc(50% - 15px);
  height: 30px;
  left: 0;
  z-index: 2;
  cursor: pointer;
  &.right {
    transform: scaleX(-1);
    right: 0;
    left: auto;
  }
  & svg {
    height: 100%;
  }
}

.Loading {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
}

.Counter {
  background-color: rgba(255, 255, 255, 0.75);
  text-align: center;
  position: absolute;
  bottom: 5px;
  border-radius: 5px;
  width: 76px;
  left: 50%;
  margin-left: -38px; /* Not using translate due to animation */
  transition: opacity 0.5s;
  z-index: 3;
  white-space: nowrap;
}

.Overlay {
  position: relative;
  z-index: 3;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}
