@import '../styles/common/variables.css-module.css';

.Heading {
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 25px;
  letter-spacing: 0;
  text-align: left;
  color: var(--text-color);
}
