@import '../../../css-variables/variables.css';

.SubjectMapBanner {
  height: 400px;
  width: 100%;
  max-width: 1550px;
  position: relative;
  margin: 0 auto 45px auto;

  background: var(--hc-gray-light);
}

.SubjectPhotoCard {
  position: absolute;
  bottom: 0;
  left: 35px;
  transform: translateY(30px);
  pointer-events: all;
}

.Content {
  position: relative;
  height: 100%;
  z-index: 2;
  pointer-events: none;
}

.Map {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
}
