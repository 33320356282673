@import '../styles/common/variables.css-module.css';

.LimitedReportWarning {
  display: flex;
  align-items: center;
  background-color: rgb(148, 154, 162);
  padding: 4px 8px 3px;
  border-radius: 2px;
  width: 100px;
}

.LimitedReportWarningText {
  font-size: 0.875rem;
  font-weight: 600;
  color: white;
}
