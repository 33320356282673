@import 'src/legacy/styles/common/variables';
@import 'src/legacy/styles/common/colors';

.archived-report-versions {
  .report-icon {
    width: 17px;
    height: 21px;
    margin: 0;
    display: inline-block;
  }

  .report-label {
    padding-left: $px-rem-10;
    display: inline;
  }

  .report-row {
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
  }
  .report-link {
    color: $hc-blue;
    cursor: pointer;
    text-align: right;
  }
  .view-all-link {
    margin: 15px 0;
    display: flex;
    color: $hc-blue;
  }
}
