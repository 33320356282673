@import '../styles/common/variables.css-module.css';

.EffectiveDateWarning {
  margin-top: 6px;
}

.StreetViewIcon {
  position: absolute;
  bottom: 40px;
  right: 5px;
  z-index: 2;
}

.SubjectPhotoCard {
  width: 210px;
  height: 200px;
  border-radius: 10px;
  box-shadow: var(--hc-box-shadow);
  overflow: hidden;
  border: 4px solid white;
  background: white;

  &.clickable {
    cursor: pointer;
  }
}
.Photo {
  width: 100%;
  height: 100%;
  &:not(:only-child) {
    height: calc(100% - 35px);
  }
}
.Info {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  font-size: 16px;
  font-weight: 700;
  padding-top: 2px;
}
.AccentText {
  color: var(--hc-green);
  margin-right: 10px;
}

.ThumbnailChevron {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: auto;
}

.ThumbnailChevronLeft {
  left: 0;
}

.ThumbnailChevronRight {
  right: 0;
}

@media (max-width: 768px) {
  .SubjectPhotoCard {
    width: 100px;
    height: 100px;
    bottom: -20px;
    border: 2px solid white;
  }
  .Info {
    height: 20px;
    font-size: 11px;
    letter-spacing: -0.05em;
  }
  .AccentText {
    margin-right: 5px;
  }
  .Photo:not(:only-child) {
    height: calc(100% - 20px);
  }
}
