@import 'src/legacy/styles/common/colors';
@import 'src/legacy/styles/common/variables';

/* Ensure that sibling element sits below fixed app-header */
.app-header + * {
  margin-top: $top-nav-height;
  height: calc(100% - 50px);
}

.app-header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: $top-nav-height;
  min-height: $top-nav-height;
  width: 100vw;
  border-bottom: 3px solid #f3f3f3;
  z-index: 3;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: white;

  &.no-auth {
    background-color: #02242c !important;
  }
  .navbar-hc-brand {
    cursor: pointer;
    display: block;
    height: 30px;
    svg {
      height: 30px;
    }

    &.inline {
      display: inline-block;
    }
  }
  img.share-VR {
    width: 30px;
    height: 30px;
    &.margin-right {
      margin-right: 40px;
    }
  }
  /* Account for slim app header */
  .autocomplete {
    input {
      line-height: 30px;
      border-bottom: 1px solid #eee;
      padding-bottom: 5px;
      box-sizing: border-box;
    }
    button {
      padding: 5px;
      height: 40px;
    }
    .autocomplete--suggestions {
      top: 40px;
    }
  }
}

.app-header-column {
  display: flex;
  flex: auto;
  flex-direction: row;
  align-items: center;
  &.right {
    justify-content: flex-end;
  }
  &.left {
    justify-content: space-between;
    &.hc-layout-lg {
      justify-content: flex-start;

      .navbar-hc-brand {
        margin: 0 25px 0 35px;
      }
    }
  }
}

.trials {
  padding: $padding-2;
  .heading {
    font-size: 0.875rem;
    font-weight: 900;
    color: #4a4a4a;
    text-transform: uppercase;
  }
  .sub-heading {
    font-size: $font-size-14;
    color: $hc-dark-blue;
  }
  &.expired {
    padding: $padding-10;
  }
}
.search-component-column {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  padding: 0 8px;
  background-color: $white;
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  box-sizing: border-box;
  input.search-input {
    min-height: 30px;
  }
}

.toolbar-search-container {
  width: 380px;
  font-size: $font-size-14;
  margin-left: $margin-15;
}

.unauth-buttons {
  display: inline-block;
  vertical-align: top;
  padding: 5px 0 0 15px;

  > * {
    margin-left: 15px;
  }
}

@media print {
  .navbar-hc-brand {
    margin-left: 0 !important;
  }
  .hc-header-icon,
  .address-autocomplete-component {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .app-header .autocomplete {
    input {
      line-height: 45px;
      height: 45px;
    }
    button {
      padding: 10px;
    }
    .autocomplete--suggestions {
      top: 45px;
    }
  }
}
