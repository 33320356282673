@import '../../../lib/css-variables/variables.css';
.Main {
  display: flex;
}

.ValueSection {
  flex: 0 0 380px;
  padding-right: 60px;
  border-right: var(--hc-border);
  justify-content: flex-start;
}

.SummarySection {
  padding-left: 60px;
  padding-top: 10px;
  position: relative;
  flex: 1;
}

.Actions {
  position: absolute;
  right: 0;
  top: 0;
}

.CobrandingHeader {
  max-width: 1250px;
  width: 100%;
  padding: 0 33px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  margin: 25px auto 22px auto;
}

@media screen and (max-width: 1024px) {
  .Main {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .ValueSection {
    padding-right: 0;
    border: none;
  }
  .SummarySection {
    padding-left: 0;
  }
}
