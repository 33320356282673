@import '../../css-variables/variables.css';

.Popover {
  position: absolute;
  top: calc(100% - 15px);
  background-color: #fff;
  -webkit-box-shadow: rgb(0 0 0 / 12%) 0 1px 6px, rgb(0 0 0 / 12%) 0 1px 4px;
  box-shadow: rgb(0 0 0 / 12%) 0 1px 6px, rgb(0 0 0 / 12%) 0 1px 4px;
  border-radius: 2px;
  padding: 15px;
  z-index: 2001;
  display: block;
  animation: fadeInDown 0.3s;
  top: 32%;
  left: 23%;
}

.PopoverAccent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 15px;
  background-color: #555;
}

.AVMSelectorItem {
  padding: 30px 10px 0;
  width: 605px;
  vertical-align: top;
  white-space: normal;
  width: calc(50% - 10px);
}

.Link {
  color: var(--hc-blue);
  cursor: pointer;
  line-height: 16px;
  font-size: 12px;
  font-weight: 900;
  margin-left: 8px;
}

.Chevron > * {
  margin-left: 10px;
}
