@import '~hc-pattern-lib';
$max-width: rem(800);

.PersonalizationModal.Dialog {
  margin-top: 30px;
  height: calc(100vh - 90px);
  overflow: auto;
}
.title {
  font-size: 24px;
  margin: 0;
}

.subtitle {
  font-size: 14px;
}

.PersonalizationModal.small {
  width: 375px;
  box-sizing: border-box;
}
.StateColumn {
  width: 50%;
}
.SelectFieldState {
  max-width: 100%;
}

.CloseButton {
  position: absolute;
  top: 17px;
  right: 17px;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
}

.DisabledSubmitMessage {
  margin-top: 15px;
}

.PersonalizationForm {
  padding-bottom: rem(75);
  background-color: $white;

  .HeaderContainer {
    max-width: $max-width;
    text-align: center;
  }

  .PersonalizationFormContent {
    margin-left: auto;
    margin-right: auto;
    max-width: $max-width;
  }
}

.FormRow {
  display: flex;
  flex-direction: column;
}

.FormRowInline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 450px) {
  .FormRowInline {
    flex-direction: column;

    .FormColumn {
      padding: initial;
      &:first-child,
      &:only-child {
        padding-left: initial;
      }
      &:last-child,
      &:only-child {
        padding-right: initial;
      }
    }
  }
}

.FormColumn {
  &:first-child,
  &:only-child {
    padding-left: 0;
  }
  &:last-child,
  &:only-child {
    padding-right: 0;
  }
  flex: 1;
  padding: rem(10) rem(20) rem(10) 0;
}

.PersonalizationFormFooter {
  text-align: center;
}

.ImageFileReqs {
  color: $faded-grey;
  font-size: 12px;
  text-align: center;
  margin: 20px 0;
}
