.PopoverButton {
  height: 36px;
  line-height: 36px;
  min-width: 88px;
  user-select: none;
  padding-left: 16px;
  padding-right: 16px;
  vertical-align: middle;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.popover-button-label {
  font-weight: 100;
  white-space: nowrap;
}
.popover-button-label--active,
.popover-button-label--highlighted,
.popover-button-label--active .popover-button-label-icon {
  color: #feb913;
  font-weight: 600;
}
.popover-button-label-icon {
  vertical-align: middle;
  margin: -4px 0 0 5px;
  color: #c6c6c6;
}
