.Chip.disabled {
  background-color: #f7f9fa;
  border-color: #f7f9fa;
  color: #cccdcd;
  cursor: not-allowed;
  > span {
    pointer-events: none;
    > span {
      > svg {
        fill: #cccdcd;
      }
    }
  }
}
