.AVMSelectionItem {
  box-shadow: 0px 2px 15px 0px rgb(0 0 0 / 10%);
  padding: 15px 20px 35px;
  white-space: normal;
  border-radius: 4px;
  position: relative;
  height: 155px;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 55px;
}

.AVMSelectionItem:nth-child(odd) {
  margin-right: 20px;
}

.AVMSelectionItem:nth-child(3) {
  text-align: center;
  margin-right: auto;
  margin-left: 25%;
}

.Title {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 6px;
  text-align: center;
}

.Content {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
}

.Value {
  text-align: center;
  font-size: 30px;
  font-weight: 900;
  line-height: 41px;
  padding-bottom: 5px;
}
