@import '../../css-variables/variables.css';

@keyframes loading {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
@keyframes loadingCircle {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -600;
  }
}
.LoadingSpinner {
  position: relative;
  margin: 0 auto;
  text-align: center;
  display: inline-block;
}

.Svg {
  position: relative;
  animation: loading 4s linear infinite;
}

.Circle {
  stroke-dashoffset: 0;
  stroke-dasharray: 300;
  stroke-width: 5.334%;
  stroke-miterlimit: 10;
  stroke-linecap: round;
  animation: loadingCircle 3s linear infinite;
  stroke: var(--hc-blue);
  fill: transparent;
}

.large {
  width: 75px;
}

.small {
  width: 30px;
}

.AbsoluteCenterContainer {
  height: 100%;
  width: 100%;
  position: relative;
}

.absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
