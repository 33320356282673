@import '../../css-variables/variables.css';

.Table {
  height: 100%;
  width: 100%;
  overflow: auto;
  position: relative;
  background: #ffffff;
  box-sizing: border-box;

  > table {
    width: 100%;
    background-color: #ffffff;
    border-collapse: collapse;
    font-size: 14px;
    box-sizing: border-box;
    font-smoothing: antialiased;
    text-size-adjust: 100%;
    position: relative;
    box-sizing: border-box;
  }
}

.TableHeader {
  &.sticky {
    th {
      box-sizing: border-box;
      position: sticky;
      top: 0;
    }
  }
}

.TableHeaderCell {
  background: #fafafa;
  box-sizing: border-box;
  padding: 5px 10px;
  vertical-align: bottom;
  font-weight: 500;

  &.sticky {
    position: sticky;
    z-index: 5;
  }

  .Content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.Sort {
  width: 15px;
  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    display: block;
    margin-left: 5px;
  }
  &.ASC {
    &::after {
      border-bottom: 5px solid var(--hc-orange);
    }
  }

  &.DESC {
    &::after {
      border-top: 5px solid var(--hc-orange);
    }
  }
}

.TableCell {
  background: #f6f7f8;
  box-sizing: border-box;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 300;
}

.TableRow {
  box-sizing: border-box;

  &.odd {
    .TableCell {
      background: #f6f7f8;
    }
  }

  &.even {
    .TableCell {
      background: #ffffff;
    }
  }
}

.scrolledHorz {
  .TableCell.sticky {
    box-shadow: 2px 0px 3px -3px;
  }

  .TableRow.sticky .TableCell.sticky {
    box-shadow: 2px 0 3px -3px, 0 3px 0 #f3f3f3;
  }

  .TableHeaderCell.sticky {
    box-shadow: 2px 0 3px -3px, 0 3px 0 #f3f3f3;
  }
}

.LazySpacer {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.noWrap {
  .TableCell {
    white-space: nowrap;
  }
}

.TableRow.sticky {
  .TableCell {
    position: sticky;
    z-index: 4;
    box-shadow: 0 3px 0 #f3f3f3;

    &.sticky {
      z-index: 5;
    }
  }
}

.TableCell.sticky {
  position: sticky;
  z-index: 4;
}

.sticky {
  overflow: hidden;
  text-overflow: ellipsis;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.clickable {
  cursor: pointer;
}
