@import '../styles/common/variables.css-module.css';

.component {
  max-width: 100%;
  width: 100%;
  left: 0;
  position: static !important;

  .search-button {
    height: 100%;
    padding: 0 12px;
    > span {
      position: relative;
      top: 2px;

      svg {
        fill: #c4c4c4;
        transition: fill 0.3s;
      }
    }
  }

  .suggestions {
    top: 100%;
    position: absolute;
    width: calc(100% + 2px);
    left: -1px;
    border-width: 1px 1px 1px 1px;
    border-color: var(--border-color);
    border-radius: 0 0 4px 4px;
    max-height: calc(100vh - 140px);
  }

  .suggestion {
    font-size: 16px;
    line-height: 45px;
    height: 45px;

    &:nth-last-child(2) {
      border-bottom: none;
    }

    &:last-child {
      height: 30px;
      line-height: 25px;
    }
  }

  .prefilled-suggestions-label {
    font-size: 10px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.44);
    padding: 5px 15px 0 15px;
  }

  .view-more-suggestions {
    font-size: 12px;
    font-weight: 900;
  }

  input {
    padding: 8px 10px 7px 15px;
  }

  .no-results-link {
    color: var(--hc-blue);
    font-size: 12px;
    font-weight: 900;
  }
}

.focused {
  .component {
    .search-button {
      > span {
        svg {
          fill: var(--text-color);
        }
      }
    }
  }
}

.Overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 100;
  left: 0;
  margin: 0;
  padding: 0;
  background-color: var(--hc-overlay);
}

.AddressSearch {
  .MobileToggleButton {
    display: none;
    position: relative;
    margin-right: 20px;
    top: -1px;

    > span {
      position: absolute;
      top: 0;
      left: 0;

      > svg {
        fill: var(--text-color);
        width: 25px;
      }
    }
  }

  &.loggedOut {
    .MobileToggleButton {
      top: 8px;
    }
  }
}

.SearchComponent {
  z-index: 101;
}

.InputOverlay {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  width: 100%;
  height: 100%;
  z-index: 2000;
}

@media (max-width: 895px) {
  .AddressSearch {
    &.mobileCollapse {
      .SearchComponent {
        position: fixed;
        top: 64px;
        left: 5%;
        width: 90%;
        display: none;
        float: none;

        &.mobileExpanded {
          display: block;
        }
      }

      .MobileToggleButton {
        display: block;
      }
    }
  }

  .suggestions {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
  }
}
