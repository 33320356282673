@import '../../../css-variables/variables.css';
.CompFilterClearButton {
  border: none;
  background: transparent;
  cursor: pointer;
  color: var(--hc-blue);
  font-size: 12px;
  font-weight: 500;
  font-family: var(--hc-font);
  margin-top: 10px;
  padding: 0;
}
