@import '../styles/common/variables.css-module.css';

.Dialog {
  width: auto !important;
  max-width: 100% !important;
  margin-top: 30px;
  height: calc(100vh - 90px);
}

.DialogHeaderHeading {
  font-size: 20px !important;
  color: var(--text-color) !important;
}
