@import 'src/legacy/styles/common/variables';
@import 'src/legacy/styles/common/colors';

.address-autocomplete-component {
  z-index: 3;
  transition: width 0.2s ease-in;
}

.autocomplete {
  /* Account for 0.3125rem whitespace padding on parent container */
  left: -0.3125rem;
  width: calc(100% + 2 * 0.3125rem);
  z-index: 1000;
  background: white;
  border-radius: 4px;

  input {
    line-height: 36px;
    height: 36px;
    border: none;
    padding: 7px 10px 7px 10px;
    margin-left: 5px;
    width: calc(100% - 50px);
    font-size: 16px;
    font-family: 'Avenir', sans-serif !important;
    /* Fixes container border from being cut off in certain browser zoom levels */
    margin: 1px;

    &::-webkit-input-placeholder {
      color: $placeholder-color;
    }
    &::-moz-placeholder {
      color: $placeholder-color;
    }
    &:-ms-input-placeholder {
      color: $placeholder-color;
    }
  }
  .autocomplete--suggestions {
    position: absolute;
    top: 50px;
    border: 0;
    border-top: 1px solid #ddd;
    box-shadow: none;
    width: 100%;
    border: 1px solid #ccc;

    li:last-child {
      border-bottom: 0;
    }
  }
  .autocomplete--prefilledSuggestionsLabel {
    font-size: 14px;
  }
  button {
    position: absolute;
    right: 0;
    border: none;
    background: transparent;
    padding: 12px;
    height: 50px;
    svg {
      fill: #666;
      width: 25px;
    }
  }
  li {
    font-size: 16px;
  }
  .autocomplete--viewMorePrefilledSuggestions {
    color: $hc-blue;
  }
}
.search-component-column .autocomplete {
  /* Account for 10px whitespace padding on parent container when in mobile width */
  left: -10px;
  width: calc(100% + 2 * 10px);
}

/* Mobile */
@media (max-width: 768px) {
  /* Have suggestions take less space to show "View all" link without needing to
   * scroll on most handsets (iOS bug prevents clicking "View all" after scroll) */
  .autocomplete--suggestions li {
    height: 3.4rem;
    line-height: 3.4rem;
  }
}
