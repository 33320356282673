@import '../../css-variables/variables.css';

.Input {
  display: block;
  outline: none;
  border: 1px solid #c9c9c9;
  border-radius: 3px;
  width: 100%;
  height: 40px;
  padding: 3px 12px 0;
  box-sizing: border-box;
  font-family: var(--hc-font);
  font-size: 18px;
  font-weight: 900;
  padding-top: 10px;
  padding-bottom: 10px;
  height: auto;
  margin: 0 0 7px;
  &:focus {
    border-color: var(--hc-blue);
  }
}

.AlertIcon {
  position: absolute;
  top: -13px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.WarningText {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}
