.Circle1 {
  transform: translateX(0px);
  transition: transform 0.75s;
  fill: #ffffff;
}
.Circle2 {
  transform: translate(12px, 0.5px);
  transition: transform 0.75s;
  fill: #ffffff;
}
.LineGroup1 {
  transform: translate(1px, 1px);
}
.LineGroup2 {
  transform: translate(1px, 10px);
}
.Line {
  transform: translateY(2.5px);
}
.filled {
  .Circle1 {
    fill: #293b4e;
    transform: translateX(12px);
  }
  .Circle2 {
    fill: #293b4e;
    transform: translate(0px, 0.5px);
  }
}

.FilterIcon {
  height: 100%;
  width: 100%;
}
