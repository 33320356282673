@import '../styles/common/variables.css-module.css';

/** To be used for fonts, colors, spacing, and other common stylistic items for
 * which we don't want to create dedicated components */

.section-heading {
  font-size: 18px;
  font-weight: 700;
}
.section-heading-small {
  font-size: 16px;
  font-weight: 900;
  line-height: 22px;
  margin: 15px 0;
}
.link-button {
  font-size: 16px;
  color: var(--hc-blue);
  cursor: pointer;
  font-weight: 600;
  user-select: none;
}
.link-button-secondary {
  composes: link-button;
  color: #aaa;
}
.link-button-small {
  composes: link-button;
  font-size: 14px;
  color: var(--hc-blue);
  cursor: pointer;
  font-weight: 600;
  user-select: none;
}
.normal-text {
  font-size: 16px;
}
.small-text {
  font-size: 14px;
}
.tiny-text {
  font-size: 12px;
}
.placeholder-text {
  color: var(--text-light-color);
}
