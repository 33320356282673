@import '../styles/common/variables.css-module.css';

.PrimaryFilterCheckbox {
  cursor: pointer;
  display: flex;
  padding: 3px;
}

.Svg {
  .CheckedFill {
    fill: var(--hc-orange);
  }
  .UncheckedFill {
    fill: rgba(0, 0, 0, 0.24);
  }
  &:hover {
    .UncheckedFill {
      fill: var(--hc-orange);
    }
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
