@import '../styles/common/variables.css-module.css';

.Info {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  color: var(--text-light-color);
}

.List {
  margin: 0 0 30px 0;
  padding: 0;
  list-style: none;
}

.ListItem {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
}

.AlertIcon {
  position: absolute;
  margin-left: -30px;
  > svg {
    width: 20px !important;
    height: 20px !important;
  }
}

.ListItemLabel {
  font-size: 14px;
  padding-left: 30px;
  font-weight: 700;
  flex: 0 0 170px;
  font-size: 16px;
}

.ListItemValue {
  font-size: 14px;
  font-weight: 300;
  flex: 1;
}

.Actions {
  display: flex;
  justify-content: space-between;
}

.ErrorMsg {
  color: var(--hc-orange);
}

.ControlContainer {
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
  justify-content: space-between;
}

.ControlCell {
  flex: 0 0 calc(50% - 10px);
}

.ControlLabel {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: var(--text-color);
}

.RadioControl {
  display: flex;
}

.RadioCell {
  flex: 0 0 25px;
  display: flex;
  align-items: center;
}

.Input {
  &.input {
    margin-left: 25px;
  }
}

.Control {
  position: relative;
  top: -5px;
}

.DefaultCell {
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  display: flex;
  align-items: center;
}

.disabled {
  opacity: 0.5;
}

.MaxReachedMessage {
  display: flex;
  padding: 0 0 15px 0;
  align-items: center;
  > *:first-child {
    margin-right: 10px;
  }
}
