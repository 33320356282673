@import '../styles/common/variables.css-module.css';
.Component {
  position: absolute;
  top: 10px;
  right: 16px;
  z-index: 600;
}

@media (max-width: 768px) {
  .Component {
    top: 20px;
  }
}

@media print {
  .Component {
    display: none;
  }
}
