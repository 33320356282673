@import '../styles/common/variables.css-module.css';

.PhotoPlaceholder {
  height: 100%;
  width: 100%;
  position: relative;
}

.Content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.Icon {
  height: auto;
  width: 50%;
  max-width: 150px;
  display: block;
  margin: auto;
}

.Text {
  color: #d7d7d7;
  font-size: 14px;
  font-weight: 900;
  line-height: 19px;
  max-width: 125px;
  display: block;
  margin: auto;
  text-align: center;
  padding-right: 13px;
  box-sizing: border-box;
  padding-top: 15px;
}
