.SubjectIcon {
  position: relative;
  top: -2px;
  left: 4px;
}

.SubjectIconWrapper {
  margin: -5px 0 0 -3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0.7) translate(-50%, -50%);
}
