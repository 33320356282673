@import '../../styles/common/variables.css-module.css';
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-15px);
    pointer-events: none;
  }
  99% {
    pointer-events: none;
  }
  to {
    opacity: 1;
    transform: translateY(0px);
    pointer-events: initial;
  }
}

@keyframes fadeInDownLeft {
  from {
    opacity: 0;
    transform: translate(-50%, -15px);
    pointer-events: none;
  }
  99% {
    pointer-events: none;
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0px);
    pointer-events: initial;
  }
}

.Popover {
  position: relative;
}

.Dropdown {
  position: absolute;
  top: calc(100% - 15px);
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  border-radius: 2px;
  padding: 15px;
  /* The only page element above `.dropdown-screen` */
  z-index: 2001;
  display: none;
}
.MenuPopover {
  padding: 0;
}
.open {
  display: block;
}
.openLeft {
  animation: fadeInDownLeft 0.3s;
}
.openTop {
  animation: fadeInDown 0.3s;
}

.Screen {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 2000;
}

.Caret {
  bottom: 100%;
  text-shadow: 0px 2px 0px #fff;
  position: absolute;
  /* Above .TooltipScreen */
  z-index: 2;
  display: flex;
  justify-content: center;
  margin-bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  &::after {
    color: #fff;
    content: '▲';
    transform: scaleX(1.5);
    text-shadow: 0px -1px 1px rgba(0, 0, 0, 0.125);
  }
}

/* React Toolbox Theme */
.menu {
  width: 100%;
}
