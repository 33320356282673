@import '../styles/common/variables.css-module.css';

.Price {
  display: inline-block;
  color: var(--text-color);
  text-align: right;
}

.Label {
  font-size: 16px;
  font-weight: 700;
}

.Date {
}

.Value {
  font-size: 30px;
  font-weight: 900;
}
