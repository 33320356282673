@import '../styles/common/variables.css-module.css';

.Property {
  flex: 1;
  color: var(--text-color);
}

.ReportDate {
  position: relative;
  top: -3px;
}

.AddressRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.PropertyDetails {
  font-size: 16px;
  font-weight: 300;
  line-height: 23px;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;

  > div {
    margin-right: 30px;
  }
}

.StreetAddress {
  font-weight: 900;
  font-size: 30px;
  margin: 0 15px 0 0;
  line-height: 100%;
}

@media (max-width: 1400px) {
  .PropertyDetails {
    > div {
      margin-right: 15px;
    }
  }
}
