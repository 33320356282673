.Container {
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;

  > div {
    display: inline-block;
    margin: 5px;
  }
}
