@import '../styles/common/variables.css-module.css';

.SimilarityLevel {
  line-height: 0;
  float: right;
  margin: 7px 0px 0px 4px;
  .SimilarityLevelDot {
    margin: 0 2px;
    circle {
      fill: #c8c8c8;
    }
  }

  &.high {
    .SimilarityLevelDot {
      circle {
        fill: var(--hc-blue);
      }
    }
  }

  &.moderate {
    .SimilarityLevelDot {
      circle {
        fill: var(--hc-blue);
      }
      &:last-child {
        circle {
          fill: #c8c8c8;
        }
      }
    }
  }

  &.low {
    .SimilarityLevelDot {
      &:first-child {
        circle {
          fill: var(--hc-blue);
        }
      }
    }
  }
}
