@import '../../styles/common/variables.css-module.css';

.MultiSelect {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.Option {
  display: flex;
  box-sizing: border-box;
  white-space: nowrap;
  margin-right: 15px;
  align-items: center;
  flex: 1 1 100%;
  margin-top: 10px;
  &:last-child {
    margin-right: 0px;
  }
}

.OptionContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.LabelCell {
  padding-left: 10px;
  flex: 1 1 0%;
  color: #3f3f3f;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 300;
  white-space: nowrap;
}

.CheckboxCell {
  width: 22px;
  flex: 0 0 22px;
  padding-right: 5px;
}

.Checkbox {
  position: relative;
  left: 10px;
  top: 3px;

  .check {
    border: 1px solid #e4e4e4;
    background-color: #fff;
    box-sizing: border-box;
    height: 16px;
    width: 16px;
    border-radius: 3px;
  }

  .checked {
    background-color: var(--hc-blue-dark);
    background-size: 45%;
    border: none;
  }

  .disabled {
    .check {
      background-color: #eaeaea;
      border: none;
    }
  }

  .input {
    display: none;
  }
}
