@import '../../styles/common/variables.css-module.css';

.Container {
  position: relative;
  padding: 30px 0 30px 0;
  display: flex;

  a {
    color: #0a62ff;
  }
}

.Checkbox {
  width: auto;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.Label {
  color: var(--text-color);
  display: inline-block;
  padding-left: 15px;
  vertical-align: top;
  position: relative;
  top: -5px;
}

.check.checked {
  background-color: var(--hc-blue);
  border-color: var(--hc-blue);
}

.ripple {
  display: none;
}
