@import '../styles/common/variables.css-module.css';

.CompsListHeader {
  background-color: white;
  display: flex;
  flex: 1 0 70px;
  align-items: center;
  padding: 10px 15px;
  box-sizing: border-box;
}

.LeftCol {
  flex: 1;
}

.RightCol {
  flex: 0 0 180px;
  align-self: flex-start;
}

.Value {
  color: #3f3f3f;
  font-family: Avenir;
  font-size: 24px;
  font-weight: 900;
  line-height: 33px;
  margin: 0;
}

.CountText {
  color: #939aa3;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}

.doubleHeight {
  display: block;

  .RightCol {
    padding-top: 15px;
  }

  .Check {
    text-align: left;
  }
}

.Info {
  width: 20px;
  height: 20px;
  margin-left: 7px;
}
