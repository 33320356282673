@import '../styles/common/variables.css-module.css';

.FilterControl {
  padding: 0 !important;
}

.MoreFiltersCard {
  border: 1.6px solid #e7e7e7;
  border-radius: 6px;
  display: inline-block;
  margin: 5px;
}

.ClearFilter {
  font-size: 12px;
  font-weight: 500;
  color: var(--hc-blue);
  cursor: pointer;
  margin-top: 10px;
  display: inline-block;
}

.hidden {
  visibility: hidden;
}
