@import '../../css-variables/variables.css';

.SubjectIcon {
  background: var(--subject-gradient);
  border-radius: 50%;
  position: relative;
}

.HomeIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% + 2px));
}

.Shadow {
  position: absolute;
  background: rgb(0 0 0 / 10%);
  height: 2px;
  border-radius: 50%;
  position: absolute;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 0px 2px rgb(0 0 0 / 10%);
}
