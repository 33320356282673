@import '../../styles/common/variables.css-module.css';

.hc-field-textarea-box {
  position: relative;
  margin-bottom: 10px;

  .hc-field-textarea-box--field {
    border: 1px solid rgba(0, 0, 0, 0.12);
    height: 120px;
    width: 100%;
    resize: none;
    box-sizing: border-box;
    font-family: 'Avenir', sans-serif;
    padding: 10px 15px 10px 15px;
  }

  .hc-field-textarea-box--maxlength {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 12px;
    color: var(--text-color);
    font-family: 'Avenir', sans-serif;
  }
}
