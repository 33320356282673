@import '../../../css-variables/variables.css';

.SubjectPublicRemarks {
  padding: 15px 0;
  display: block;
}

.Date {
  color: var(--text-light-color);
  font-size: 12px;
  font-weight: 300;
}
