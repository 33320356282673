@import '../styles/common/variables.css-module.css';

.AttributeGroupContainer {
  display: flex;
  justify-content: stretch;
  min-height: 200px;
}

.AttributeGroup {
  margin: 0 25px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}

.Attribute {
  display: flex;
  align-items: center;
  border-bottom: var(--hc-border);
  padding: 5px 10px;
  min-height: 38px;
  box-sizing: border-box;
}

.AttributeLabel {
  font-size: 12px;
  font-weight: 400;
  color: #707070;
  line-height: 19px;
  flex: 0 0 150px;
  align-self: flex-start;
  padding-top: 3px;
}

.AttributeValue {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.AttributeValueDisplay {
  flex: 1;
  position: relative;
  top: -2px;
}

.AdjustmentBadge {
  flex: 0 0 84px;
  display: flex;
  align-items: center;
}

.TextButton {
  padding: 15px 3px;
  margin-right: 15px;
}

.ActionBar {
  min-height: 46px;
  .TextButtonCancel {
    color: #9b9b9b;
  }
}

@media (max-width: 930px) {
  .AttributeGroup {
    margin: 0 10px;
  }
}

@media (max-width: 885px) {
  .AttributeGroupContainer {
    display: block;
  }
  .AttributeGroup {
    margin: 0;
  }
}

@media (max-width: 460px) {
  .Attribute {
    display: block;
  }
}
