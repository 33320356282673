@import '../styles/common/variables.css-module.css';

.SearchLandingSidebar {
  display: flex;
  flex-direction: column;
  padding: 15px 10px 15px 20px;
  max-height: 100%;
  height: 100%;
  overflow: auto;
}

.MapLayerControls {
  flex: 0 0 365px;
}

.MLSCards {
  flex: 1;
  padding: 10px;
}
.HeaderRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Header {
  font-size: 23px;
  font-weight: 900;
  line-height: 31px;
  margin: 0;
  color: var(--text-color);
}

.ToggleCell {
  padding-right: 15px;
  > a {
    color: var(--hc-blue);
    font-size: 12px;
    cursor: pointer;
  }
}
/* Overriding styles for heatmap layer controls */
.HeaderMapLayerControlsSection {
  text-align: center;
}

.HeaderMapLayerControls {
  > div {
    > div {
      /* .layer-controls-container */
      position: static;
      box-shadow: none;

      [data-hc-name='map-controls'] {
        flex-wrap: wrap;
        padding: 25px 0 0 0;

        > div {
          /* .control-item */
          max-width: initial;
          width: calc(33% - 10px);
          margin-bottom: 20px;
          flex-grow: 0;
          flex-basis: auto;

          > div {
            &:last-child {
              /* .control-item-description */
              display: block;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1250px) {
  .MapLayerControls {
    flex: 0 0 290px;
  }

  .HeaderMapLayerControls {
    > div {
      > div {
        /* .layer-controls-container */
        [data-hc-name='map-controls'] {
          > div {
            /* .control-item */
            > div {
              &:last-child {
                /* .control-item-description */
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
