@import '../../../css-variables/variables.css';

.KeywordSearchResults {
  height: 25px;
  width: 35px;
  position: relative;
}
.Count {
  height: 25px;
  padding: 0 7px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;

  &.matches {
    background: var(--keyword-color-dark);
  }

  &.noMatches {
    background: #ccc;
  }
}
