.HeaderButton {
  display: block;
  line-height: 65px;
  padding: 0 20px;
  cursor: pointer;
  height: 100%;
}

.TitleCell {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

.Header {
  box-shadow: 0px 7px 5px -5px #ddd;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  padding: 15px 0;
  box-sizing: border-box;
  position: relative;
  text-align: center;
}

.CompsFilterList {
  padding: 20px !important;
  overflow-y: auto !important;
  box-shadow: none !important;
}

.BackCell {
  flex: 0 0 75px;
}

.ClearCell {
  flex: 0 0 75px;
}

@media (max-width: 480px) {
  .Title {
    padding: 7px 50px;
  }
  .ApplyButtonContainer {
    height: 125px;
  }
}
