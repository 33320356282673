.StreetViewButton {
  position: relative;
  background: #fff;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 10%);
  height: 30px;
  width: 30px;
  cursor: pointer;
  border: none;
  box-sizing: border-box;
}

.StreetViewIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.disabled {
  opacity: 50%;
  cursor: not-allowed;
}
