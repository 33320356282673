.CompControls {
  display: flex;
  justify-content: stretch;
  align-items: center;
  height: 55px;
  flex: 0 0 55px;
  width: 100%;
  border-bottom: 1px solid #dedede;
}

.KeywordSearch {
  flex: 0 0 260px;
  display: flex;
  align-items: center;
}

.FilterSets {
  flex: 0 0 233px;
  height: 100%;
}

.Filters {
  height: 100%;
  flex: 1;
  overflow: hidden;
}

.RefreshComps {
  background-color: turquoise;
  flex: 0 0 235px;
  height: 100%;
}
