.RadioButton {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 300;
  cursor: pointer;
}

.RadioButton input[type='radio'] {
  position: absolute;
  visibility: hidden;
}

.Radio {
  display: inline-block;
  position: relative;
  border: 2px solid #cecece;
  border-radius: 100%;
  height: 16px;
  width: 16px;
  box-sizing: border-box;
  margin-right: 5px;
}

input[type='radio']:checked ~ .Radio {
  border: 2px solid #293b4e;
  > div {
    background: #293b4e;
    height: 8px;
    width: 8px;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 100%;
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
