@import '../styles/common/variables.css-module.css';

.ValueDetails {
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--text-light-color);
}

.ValueStatus {
  font-size: 12px;
  font-weight: 300;
}

.ValueHcLabel {
  font-weight: 600;
  color: var(--text-color);
}

.ValueActiveStatus {
  font-weight: 600;
  color: var(--hc-green);
}

.ValueUnknown {
  font-weight: 600;
}

.ValueDivider {
  font-weight: bold;
  margin: 0 5px;
}

.ValuePrice {
  font-size: 28px;
  font-weight: 900;
  line-height: 36px;
  height: 36px;
  color: var(--text-color);
}
