.ZoomControl {
  height: 80px;
  width: 40px;
  position: absolute;
  top: 15px;
  left: 15px;
  border-radius: 20px;
  box-shadow: 0px 2px 15px 0px rgb(0 0 0 / 40%);
  background: #ffffff;
  z-index: 5;
}

.Button {
  height: 40px;
  width: 40px;
  border: none;
  background: transparent;
  cursor: pointer;
}
