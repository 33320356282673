@import '../../css-variables/variables.css';

.IconButton {
  padding: 12px;
  cursor: pointer;
  display: inline-block;
  background: none;
  border: none;
  text-align: center;
}

.svgIcon {
  height: 20px;
  width: 20px;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.IconButtonText {
  color: var(--text-color);
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}
