.ClientId {
  display: flex;
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-content: flex-end;
}

.Label {
  padding-right: 7px;
}
