@import '../styles/common/variables.css-module.css';
.DrawControlButton {
  position: relative;
  flex-grow: 1;
  text-align: center;
  margin: 0;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: var(--hc-border-radius);
  box-shadow: 0 1px 7px 1px rgba(0, 0, 0, 0.13);
  cursor: pointer;

  .IconContainer {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 35px;
    margin: 0 auto;

    > span {
      width: 100%;
      display: block;
    }

    svg {
      width: 60%;
      height: 60%;
      position: absolute;
      top: 50%;
      left: 50%;
      bottom: 50%;
      right: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .Label {
    font-family: Avenir, sans-serif;
    font-stretch: 100%;
    font-style: normal;
    font-size: 10px;
    font-weight: 500;
    width: 100%;
    letter-spacing: -0.2px;
    line-height: 9px;
    outline-color: rgba(0, 0, 0, 0.89);
    outline-style: none;
    outline-width: 0px;
    pointer-events: auto;
    text-align: center;
    text-size-adjust: 100%;
    display: block;
    color: var(--text-color);
    cursor: pointer;
  }
}

.IconActive {
  display: none;
}
.isActive {
  .Icon {
    display: none;
  }
  .IconActive {
    display: initial;
  }
}
