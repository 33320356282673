@import 'src/legacy/styles/common/colors';
@import 'src/legacy/styles/common/variables';

.payment-error {
  padding: 10px;
  cursor: pointer;

  .payment-error--text {
    display: inline-block;
    padding-left: 10px;

    .payment-error--title {
      color: $hc-red;
      font-size: $font-size-14;
    }

    .payment-error--action {
      font-size: $font-size-12;
    }
  }
}
