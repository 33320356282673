@import '../styles/common/variables.css-module.css';

.Component {
  font-weight: 500;
}
.StreetAddress {
  font-size: 28px;
  font-weight: 900;
  line-height: 36px;
}
.CityStateZip {
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
  font-weight: 600;
}
.OtherDetails {
  padding-top: 10px;
  font-size: 14px;
  line-height: 25px;
  font-weight: 500;
  white-space: nowrap;
  & span {
    display: inline-block;
    vertical-align: middle;
  }
}
.OtherDetailsItems {
  margin-right: 25px;
}

.Dialog {
  max-width: 541px !important;
}

.ForSaleIcon {
  display: inline;
  margin: 0px 1px 0px 3px;
  vertical-align: middle;
}
.ForSaleTextContainer {
  width: fit-content;
  white-space: nowrap;
  background-color: #f9f9f9;
  border-radius: 3px;
  padding: 2px 5px 0px 1px;
  margin-bottom: 15px;
  margin-right: 35px;
}
.ForSaleText {
  display: inline;
  margin-right: 8px;
  color: #70d48a;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 900;
  line-height: 16px;
  vertical-align: 1px;
}
.ForSalePrice {
  display: inline;
  vertical-align: 1px;
  color: #4a4a4a;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 900;
  line-height: 22px;
}

.IconContainer {
  display: flex;
  float: right;
  position: relative;
  top: -10px;
}

.IconText {
  color: var(--text-color);
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.SingleIconContainer {
  margin-right: 25px;
}

.separator {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 28px;
  font-weight: 100;
  color: #969696;
  margin: 0 8px;
}

.ProductLogoRow {
  padding-bottom: 15px;
  display: flex;
  align-items: center;
}
.ProductLogo {
  font-size: 22.8px;
  line-height: 30px;
}

.LimitedReportWarning {
  margin-left: 15px;
}

.TopContainer {
  display: flex;
  justify-content: space-between;
  place-content: flex-end;
  margin-bottom: 5px;
}
.ClientId {
  flex: 0 1 100%;
}
@media (max-width: 1250px) {
  .other-details {
    white-space: normal;
  }
}

@media (max-width: 1000px) {
  .Component {
    display: flex;
    flex-direction: column;
  }

  .IconContainer,
  .StreetAddress,
  .CityStateZip {
    margin: 5px 0px;
  }
}
