.MultiSelect {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.Option {
  display: flex;
  box-sizing: border-box;
  white-space: nowrap;
  margin-right: 15px;
  align-items: center;
  flex: 1 1 100%;
  margin-top: 10px;
  &:last-child {
    margin-right: 0px;
  }
}

.OptionContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.LabelCell {
  padding-left: 10px;
  flex: 1 1 0%;
  color: #3f3f3f;
  font-size: 12px;
  font-weight: 300;
  white-space: nowrap;
}

.CheckboxCell {
  width: 22px;
  flex: 0 0 22px;
  padding-right: 5px;
}
