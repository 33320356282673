@import '../styles/common/variables.css-module.css';

.Component {
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: top;
  letter-spacing: 0.7px;
  z-index: 600;
  pointer-events: auto;
  padding: 0;
  box-sizing: border-box;

  transition: left 0.3s, transform 0.3s, bottom 0.3s;

  > div {
    flex-wrap: nowrap;
  }
}

.a--visible {
  margin-right: 7px;
  display: flex;

  &:last-child {
    margin-right: 0;
  }
}

.SaveButton {
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  box-shadow: var(--hc-box-shadow);
  background-color: var(--hc-blue);
  border-radius: 50%;
  color: #fff;
  position: absolute;
  bottom: 30px;
  right: 15px;
  z-index: 1000;
}

.LocationControl {
  margin-right: 25px;
  .LocationControlIcon {
    svg {
      width: 25px;
      > g {
        > g {
          fill: var(--text-light-color);
        }
      }
    }

    &.active {
      svg {
        width: 25px;
        > g {
          > g {
            fill: var(--hc-orange);
          }
        }
      }
    }
  }
}

.hidden {
  visibility: hidden;
}

.active-button--circle {
  .button--circle {
    border-color: var(--hc-orange);
  }
}

.active-button--polygon {
  .button--polygon {
    border-color: var(--hc-orange);
  }
}

.active-button--rectangle {
  .button--rectangle {
    border-color: var(--hc-orange);
  }
}

.active-button--edit {
  .button--edit {
    border-color: var(--hc-orange);
  }
}

.active-button--delete {
  .button--delete {
    border-color: var(--hc-orange);
  }
}

.disabled-button--circle {
  .a--circle {
    pointer-events: none;
    opacity: 0.3;
  }
}

.disabled-button--polygon {
  .a--polygon {
    pointer-events: none;
    opacity: 0.3;
  }
}

.disabled-button--edit {
  .a--edit {
    pointer-events: none;
    opacity: 0.3;
  }
}

.disabled-button--delete {
  .a--delete {
    pointer-events: none;
    opacity: 0.3;
  }
}

@media (max-width: 768px) {
  .Component {
    left: 15px;
    transform: initial;
  }
}

@media (max-width: 550px) {
  .Component {
    left: 50%;
    transform: translateX(-50%);
    bottom: 120px;
  }
}
