.PhotoDate {
  background-color: rgba(74, 74, 74, 0.5);
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  height: 16px;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  bottom: 25px;
  padding: 1px 7px 0 7px;
  transform: translateX(-50%);
  text-align: center;
  white-space: nowrap;
  max-width: 90%;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 2;
}
