@import 'src/legacy/styles/common/variables';
@import 'src/legacy/styles/common/colors';

.report-lifecycle-container {
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.5;

  .lifecycle-item {
    font-size: $font-size-16;
    padding: $padding-10;
  }
  .border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .border-none {
    border-bottom: none;
  }
}
