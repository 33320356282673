.SkeletonFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  padding: 0 15px;
  box-sizing: border-box;
}

.Cell {
  position: relative;
  overflow: none;
  &:not(last-child) {
    padding-right: 15px;
  }
}
