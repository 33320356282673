.ExpandableIcon {
}

.Icon {
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  display: none;
}

.active {
  .Icon {
    display: none;
  }
  .Content {
    display: initial;
  }
}

@media screen and (max-width: 1024px) {
  .Icon {
    display: initial;
  }
  .Content {
    display: none;
  }
}
