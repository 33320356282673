@import '../../css-variables/variables.css';

.SimilarityLevel {
  line-height: 0;
  .SimilarityLevelDot {
    margin: 0 2px;
    circle {
      fill: #c8c8c8;
    }
  }

  &.high {
    .SimilarityLevelDot {
      circle {
        fill: var(--hc-blue);
      }
    }
  }

  &.moderate {
    .SimilarityLevelDot {
      circle {
        fill: var(--hc-blue);
      }
      &:last-child {
        circle {
          fill: #c8c8c8;
        }
      }
    }
  }

  &.low {
    .SimilarityLevelDot {
      &:first-child {
        circle {
          fill: var(--hc-blue);
        }
      }
    }
  }
}
