.DataTable {
  height: 100%;

  .Table {
    background: repeating-linear-gradient(
      0deg,
      #ffffff,
      #ffffff 60px,
      #f6f7f8 60px,
      #f6f7f8 120px
    );
    background-color: #ffffff;
  }

  .TableRow {
    height: auto;

    &.Even {
      .TableCell,
      .DataTableSelectRowCell {
        background-color: #f6f7f8;
      }

      .DataTableSelectRowCell {
        border-top: none;
        border-bottom: none;
        background-color: #f6f7f8;
      }

      &.isKeywordMatch {
        .DataTableSelectRowCell {
          box-shadow: 0px 3px 0px #f3f3f3;
          background: linear-gradient(
            90deg,
            rgba(254, 185, 19, 1) 15px,
            rgba(246, 247, 248, 1) 15px
          );
          /* background: linear-gradient(90deg, rgba(0, 186, 217, 1) 15px, rgba(246, 247, 248, 1) 15px); */
        }
      }
    }

    &.Odd {
      .TableCell,
      .DataTableSelectRowCell {
        background-color: #ffffff;
      }

      .DataTableSelectRowCell {
        border-top: none;
        border-bottom: none;
        background-color: #ffffff;
      }
      &.isKeywordMatch {
        .DataTableSelectRowCell {
          box-shadow: 0px 3px 0px #f3f3f3;
          background: linear-gradient(
            90deg,
            rgba(254, 185, 19, 1) 15px,
            rgba(255, 255, 255, 1) 15px
          );
          /* background: linear-gradient(90deg, rgba(0, 186, 217, 1) 15px, rgba(255, 255, 255, 1) 15px); */
        }
      }
    }

    &.SubjectRow {
      .TableCell,
      .DataTableSelectRowCell {
        box-shadow: 0px 3px 0px #f3f3f3;
        background-color: #ffffff;
      }
    }
  }

  .TableHeaderCell,
  .TableCell {
    padding: 5px;
    padding-top: 5px;
    height: auto;
    border-top: none;
    border-bottom: none;
    color: #4a4a4a;
    vertical-align: middle;
  }

  .TableHeaderCell {
    background-color: #fafafa;
    vertical-align: bottom;

    .SortArrowAsc:after {
      border-bottom: 5px solid #0a62ff !important;
    }
    .SortArrowDesc:after {
      border-top: 5px solid #0a62ff !important;
    }
  }

  .DataTableSelectAllCell,
  .DataTableSelectRowCell {
    .check {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      /* Large Size */
      /* box-shadow: 0 2px 7px 0 rgba(0,0,0,.13); */
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.13);
      border: none;
      &:not(.checked) {
        &:after {
          content: '+';
          font-size: 18px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  &.compact {
    .Table {
      background: repeating-linear-gradient(
        0deg,
        #ffffff,
        #ffffff 37px,
        #f6f7f8 37px,
        #f6f7f8 74px
      );
      background-color: #ffffff;
    }
    .TableCell {
      font-size: 11px;
    }

    .TableHeaderCell .Content {
      font-size: 11px;
    }

    .Similarity {
      > div {
        transform: scale(0.7);
      }
    }
  }

  &.scrolledHorz {
    .TableHeaderCell,
    .TableCell {
      &.fixedCol {
        box-shadow: 2px 0px 3px -3px;
      }
    }

    .SubjectRow {
      .TableCell,
      .DataTableSelectRowCell {
        &.fixedCol {
          box-shadow: 2px 0px 3px -3px, 0px 3px 0px #f3f3f3;
          background-color: #ffffff;
        }
      }
    }
  }
}

.TableRow,
tr {
  .TableHeaderCell,
  .TableCell {
    &:nth-of-type(3) {
      padding-left: 10px;
    }
  }
}
tbody {
  .TableRow {
    &:nth-of-type(1) {
      .TableCell,
      .DataTableSelectRowCell {
        padding-top: 8px;
      }
    }
  }
}
