.CompFarmListCards {
  height: 100%;
  padding: 10px;
  /* required for react-lazyload */
  overflow: hidden;
  overflow-x: auto;
  overflow-y: auto;
  position: relative;
}

.PropertyCardWrapper {
  display: inline-block;
  margin: 10px 10px 0 10px;
  width: 240px;
}
