.QuickFilterContainer {
  margin: 0 auto;
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 10px 10px 16px 10px;
}

.QuickFilterText {
  color: #3f3f3f;
  text-align: left;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 10px;
}

.QuickFilterButtonContainer {
  margin: 0 auto;
  display: flex;
  align-items: center;
  > * {
    flex: 1;

    &.QuickFilterLabel {
      flex: 0 0 18px;
      font-size: 12px;
      color: #4a4a4a;
    }
  }
  :not(:last-child) {
    margin-right: 4px;
  }
}
