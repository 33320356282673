@import '../../css-variables/variables.css';

:root {
  --checkbox-focus-checked-color: color(var(--color-primary) a(26%));
  --checkbox-size: 14px;
  --checkbox-focus-color: color(var(--color-black) a(1%));
  --checkbox-focus-size: calc(var(--checkbox-size) * 2.3);
  --checkbox-border-color: var(--palette-grey-600);
  --checkbox-text-font-size: var(--font-size-small);
  --checkbox-total-height: 18px;
  --checkbox-transition-duration: 0.2s;
}

.Checkbox {
  display: inline-flex;
  align-items: center;
  height: var(--checkbox-size);
  position: relative;
  white-space: nowrap;
}

.Label {
  display: inline-block;
  font-size: 12px;
  line-height: var(--checkbox-size);
  padding-left: 10px;
  vertical-align: top;
  white-space: nowrap;
  cursor: pointer;
}

.Input {
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0;
}

.Check {
  border-color: var(--hc-gray);
  border-radius: 2px;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  display: inline-block;
  height: var(--checkbox-size);
  position: relative;
  transition-duration: var(--checkbox-transition-duration);
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: top;
  width: var(--checkbox-size);

  &.checked {
    background-color: var(--hc-blue-dark);
    border-color: var(--hc-blue-dark);

    &::after {
      animation: checkmark-expand 140ms ease-out forwards;
      border-bottom-width: 2px;
      border-color: #ffffff;
      border-left: 0;
      border-right-width: 2px;
      border-style: solid;
      border-top: 0;
      content: '';
      position: absolute;
      transform: rotate(45deg) scale(0.75);
    }
  }
}

.disabled {
  & > .Label {
    color: var(--hc-gray);
    cursor: not-allowed;
  }

  & > .Check {
    border-color: var(--hc-gray);
    cursor: not-allowed;

    &.checked {
      background-color: var(--hc-gray);
      border-color: transparent;
      cursor: not-allowed;
    }
  }
}

@keyframes checkmark-expand {
  0% {
    height: 0;
    left: 6px;
    top: 9px;
    width: 0;
  }

  100% {
    height: 10px;
    left: 3px;
    top: 0px;
    width: 5px;
  }
}
