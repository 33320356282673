.inputContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; 
}

.rangeDelimiter {
  margin: 30px 15px 0 15px;
}

.error {
  color: #fd8a5f;
  font-size: 10px;
}

.error > * {
  font-size: 10px;
}

.rangeInputError > div {
  margin-bottom: 10px;
}

.rangeInputError {
  padding-top: 10px;
}

.rangeInputError {
  font-size: 12px;
  padding-top: 4px;
  color: #fd8a5f !important;
}

.InputCell {
  flex: 1 1 120px;
}


.RelativeValue {
  color: #8F8F8F;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
}
