@import '../styles/common/variables.css-module.css';

.report-details-section {
  display: flex;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  padding: 10px 0;
}

.report-details {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.report-action-btn {
  text-align: center;
  align-self: center;
}

.NullStateCard {
  margin: 30px 50px;
}

.badge {
  border: 0;
  font-family: $secondary-font-stack;
  padding: rem(8) rem(20);
  font-size: rem(16);
  line-height: rem(22);
  color: $black;
  min-width: rem(165);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.09);
  text-align: center;
  display: inline-block;
  border-radius: rem(4);
  background: $white;
  box-sizing: border-box;
  outline: none;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.component {
  overflow: auto;
  padding: 15px 15px 15px 15px;
  position: relative;
  height: 100%;
  box-sizing: border-box;
}

.SubscriptionSection {
  position: relative;
}

@media (max-width: 768px) {
  .component {
    flex: 0 0 auto;
    font-size: 10px;
    z-index: 1;
    box-shadow: var(--hc-box-shadow);
    background-color: white;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    z-index: 2;

    &.retracted {
      flex-grow: 0;
      height: 75px;
      overflow: hidden;
    }
  }
}
