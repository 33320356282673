@import '../styles/common/variables.css-module.css';

.CompStats {
  width: 100%;
  background: #ffffff;
}

.Loading {
  display: block;
  margin: auto;
  width: 80px;
}

.StatTable {
  width: 100%;
}

.Row {
  display: flex;
  padding: 5px 0;
}

.Label {
  flex: 0 0 25px;
  font-weight: bold;
}

.Value {
  flex: 1;
  text-align: right;
}

.Active {
  color: var(--color-listed);
}

.Pending {
  color: var(--color-pending);
}

.Sold {
  color: var(--color-sold);
}
