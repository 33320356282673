.BadgePosition {
  vertical-align: middle;
  position: relative;
  top: -4px;
  z-index: 1;
}

.IconPosition {
  position: relative;
  z-index: 2;
  height: 20px;
  & svg {
    display: block;
  }
}

.TooltipLabel {
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
  margin-bottom: 5px;
}

.TooltipDescription {
  font-size: 11px;
  line-height: 15px;
  white-space: normal;
}
