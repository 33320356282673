/*
 * Move any variables needed from .scss here for use in CSS Modules
 */
:root {
  /* Colors */
  --text-color: #4a4a4a;
  --text-light-color: #939aa3;
  --hc-orange: #feb913;
  --hc-blue: #0a62ff;
  --hc-green: #87d191;
  --hc-yellow: #fbe6be;
  --positive-change: #70d48a;
  --negative-change: #ff8253;
  --light-loading-color: #ddd;
  --border-color: #eaeaea;
  --hc-blue-dark: #2d3b4c;
  --grey-border: rgba(155, 155, 155, 0.5);
  --grey-bg-light: #f7f7f7;
  --hc-tuxedo: #293b4e;

  /* --keyword-color-dark: #0a62ff;
  --keyword-color-dark-rgba: rgba(0, 186, 217, 1);
  --keyword-color-light: rgb(40 183 211 / .5); */

  --keyword-color-dark: #feb913;
  --keyword-color-dark-rgba: rgba(254, 185, 19, 1);
  --keyword-color-light: #ffdc98;

  --color-listed: #70d48a;
  --color-sold: #ff7944;
  --color-pending: #fdb813;

  --similarity-high: #42d882;
  --similarity-moderate: #ffb600;
  --similarity-low: #ff7944;

  /* Other */
  --hc-box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.4);
  --hc-box-shadow-soft: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
  --hc-border: 1px solid var(--border-color);
  --hc-font: 'Avenir', sans-serif;
  --hc-overlay: rgba(33, 33, 33, 0.48);
  --hc-overlay-light: rgba(255, 255, 255, 0.8);
  --hc-border-radius: 4px;

  /* Animations */
  --animation-fade-in: fadeIn 0.3s;
  --animation-fade-in-down: fadeInDown 0.3s;
  --animation-fade-in-up: fadeInUp 0.3s;

  /* Z-index */
  --zIndexHighest: 1001;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(0px);
  }
  to {
    opacity: 1;
    transform: translateY(15px);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(0px);
  }
  to {
    opacity: 1;
    transform: translateY(-15px);
  }
}

/* Export colors for use in JavaScript */
:export {
  colorText: var(--text-color);
  colorTextLight: var(--text-light-color);
  colorOrange: var(--hc-orange);
  colorBlue: var(--hc-blue);
  colorGreen: var(--hc-green);
  colorYellow: var(--hc-yellow);
  colorChangePos: var(--positive-change);
  colorChangeNeg: var(--negative-change);
  colorChartLoading: var(--light-loading-color);
  colorBorder: var(--border-color);
  colorBlueDark: var(--hc-blue-dark);
  colorTuxedo: var(--hc-tuxedo);
}
