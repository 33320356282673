@import '../../styles/common/variables.css-module.css';

.Component {
  z-index: var(--zIndexHighest);
}

.TriggerIcon {
  width: 20px;
  height: 25px;
  > svg {
    vertical-align: top;
    margin-top: 5px;
  }
}
