@import '../styles/common/variables.css-module.css';

.SubscriptionDetails {
  color: var(--text-color);
  margin-top: 1em;
  align-items: flex-start;
  display: flex;
  width: 100%;
}

.SubscriptionDetailsCol {
  margin-right: 20px;
  font-size: 0.875rem;
  &:last-child {
    margin-right: 0;
  }
}

.Label {
  font-weight: 600;
  display: block;
}
