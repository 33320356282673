@import '../styles/common/variables.css-module.css';

.ViewListingRemarks {
  font-weight: 700;
  color: var(--hc-blue);
}

.ListingRemarksContent {
  color: var(--text-color);
  font-family: Avenir;
  font-size: 11px;
  font-weight: 300;
  line-height: 15px;
}

.Title {
  margin: 5px 0 10px 0;
}
