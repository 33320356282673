@import '../styles/common/variables.css-module.css';

.GlossaryTerm {
  padding: 0 20px;
}
.PanelHeader {
  border-bottom: var(--hc-border);
}
.Title {
  font-size: 16px;
  font-weight: 900;
  padding: 25px 0 15px 0;
}

.Description {
  font-size: 14px;
  line-height: 19px;
}

.Source {
  font-size: 10px;
  color: var(--text-light-color);
  padding: 15px 0;
}
