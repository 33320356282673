@import 'src/legacy/styles/common/variables';
@import 'src/legacy/styles/common/colors';
@import 'src/legacy/styles/common/animations';

/**
 * TODO add all additional styles to `CompsSummarySection` CSS module and move all of these styles there
 */

.heading-sub-section-title {
  margin: 5px 0 0 0;
}

/* When report isn't editable, hide map controls button (which opens Comps Selection page) */
.comps-summary--map-container.report-not-editable .show-map-controls-button {
  display: none;
}

.comps-summary--button-row {
  text-align: center;
  width: 100%;
  margin: 10px 0 15px 0;
}

.comps-summary--property {
  width: 250px;
  margin: 5px 5px 20px 5px;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  text-align: left;
  height: 350px;
}

.comps-summary--property-list-container {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  margin-bottom: 10px;
  box-sizing: border-box;
  text-align: center;
}

.comps-summary--property-list-container--none-selected {
  text-align: center;

  .comps-summary--property {
    margin: auto;
    cursor: default;
    &:hover {
      .comps-summary--property-image {
        box-shadow: none;
      }
    }
  }

  .comps-summary--property-image {
    line-height: 150px;
    font-size: $font-size-13;
    font-weight: 600;
  }
}
@mixin comp-card-width($per-row) {
  width: calc(100% / #{$per-row} - calc(#{$per-row - 1} * 10px / #{$per-row}));
}

@media print {
  .comps-summary {
    page-break-after: always;
  }
  .comps-summary--button-row {
    display: none;
  }
  .comps-summary--property-list-container {
    overflow-x: visible;
    overflow-y: visible;
    white-space: initial;

    .comps-summary--property {
      margin-bottom: 20px;
    }
  }
  .comps-summary--property {
    @include comp-card-width(6);
  }
}
