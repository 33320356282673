@import '../styles/common/variables.css-module.css';

.CobrandingBadge {
  margin: 0 20px;

  .Cell {
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 15px;
    vertical-align: top;
  }

  .NoWrap {
    white-space: nowrap;
    display: inline-block;
  }

  img {
    height: 60px;
    max-height: 60px;
    vertical-align: top;
  }

  .ProfileLink {
    font-size: 12px;
    line-height: 16px;
    color: var(--hc-blue);
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    text-decoration: underline;
    cursor: pointer;
  }

  .UserInfo {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Wrap {
    white-space: normal;
  }
}
