.TableOptionsDialog.Dialog {
  width: 560px;
  max-width: 100%;
  position: relative;
  min-height: 90vh;
}

.TableOptionsButton {
  cursor: pointer;
  position: relative;
  top: 3px;
  height: 15px;
  width: 15px;

  > span {
    padding: 0;

    svg {
      height: 15px;
      width: 15px;
    }
  }
}

.enter,
.enterActive {
  animation-name: fadeIn;
  animation-duration: 750ms;
}

.leave,
.leaveActive {
  animation-name: fadeOut;
  animation-duration: 750ms;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
