@import '../../css-variables/variables.css';

.FilterButton {
  background-color: white;
  height: 40px;
  border-radius: 4px;
  font-family: Avenir;
  font-weight: 800;
  font-size: 14px;
  text-align: center;
  line-height: 38px;
  min-width: 100px;
  padding: 0 10px;
  box-sizing: border-box;
  cursor: pointer;
  white-space: nowrap;
  border: 1px solid;
  transition: color 0.15s;
  /* Inactive styles */
  border-color: #c9c9c9;
  color: rgba(74, 74, 74, 0.8);

  &.active {
    border-color: var(--text-color);
    color: var(--text-color);
  }
  &.error {
    border-color: #fd8a5f !important;
  }
}
