@import '../../css-variables/variables.css';

:root {
  /* 
    Variables changed/removed from RT:
      --input-text-bottom-border-color: --hc-gray-light
      --input-text-disabled-color: removed
  */
  --input-padding: calc(2 * var(--unit));
  --input-field-padding: calc(0.8 * var(--unit));
  --input-field-font-size: calc(1.6 * var(--unit));
  --input-field-height: calc(
    var(--input-field-padding) * 2 + var(--input-field-font-size) * 1.4
  );
  --input-label-font-size: calc(1.2 * var(--unit));
  --input-focus-label-top: calc(0.6 * var(--unit));
  --input-text-error-color: rgb(222, 50, 38);
  --input-text-required-color: rgb(222, 50, 38);
  --input-text-background-color: transparent;
  --input-text-label-color: var(--hc-gray);
  --input-underline-height: calc(2 * var(--unit));
  --input-field-secondary-color: #8f8f8f;

  --unit: 10px;

  --font-size: calc(1.6 * var(--unit));
  --font-size-tiny: calc(1.2 * var(--unit));
  --font-size-small: calc(1.4 * var(--unit));
  --font-size-normal: var(--font-size);
  --font-size-big: calc(1.8 * var(--unit));

  --animation-duration: 0.35s;
  --animation-curve-default: cubic-bezier(0.4, 0, 0.2, 1);
}

.Input {
  position: relative;
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-size-adjust: 100%;

  &:not(.noPadding) {
    padding: 14px 0;
  }
  & *,
  & *::after,
  & *::before {
    box-sizing: border-box;
    font-smoothing: antialiased;
    text-size-adjust: 100%;
    -webkit-touch-callout: none;
  }

  & input::-ms-clear,
  & textarea::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
}

.Error {
  position: absolute;
  bottom: -5px;
  left: 12px;
  color: var(--hc-orange);
  font-size: 10px;

  > * {
    font-size: 10px;
  }
}

.Counter {
  position: absolute;
  right: 0;
  bottom: -5px;
  font-size: 10px;
  color: var(--input-field-secondary-color);
}

.Hint {
  position: absolute;
  bottom: -5px;
  left: 12px;
  font-size: 10px;
  line-height: 16px;
  color: var(--input-field-secondary-color);
  opacity: 1;
  transition-duration: var(--animation-duration);
  transition-property: opacity;
  transition-timing-function: var(--animation-curve-default);
  pointer-events: none;
}

.Label {
  position: absolute;
  padding: 0 12px;
  top: 27px;
  left: 0;
  font-size: var(--input-field-font-size);
  line-height: var(--input-field-font-size);
  transition: 150ms;
  transition-duration: var(--animation-duration);
  transition-property: top, font-size, background-color;
  transition-timing-function: var(--animation-curve-default);
  pointer-events: none;
  color: var(--hc-gray);
  background: transparent;
  z-index: 2;
  max-width: calc(100% - 12px);
  overflow: hidden;

  &.fixed ~ .hint {
    display: none;
  }
}

.hidden {
  display: none;
}

.errored {
  position: relative;

  & > .InputBorder {
    border-color: var(--hc-orange) !important;
    .InputElement {
      margin-top: 1px;
    }
  }

  & > .Counter,
  & > .Label {
    color: var(--hc-orange) !important;
  }

  & > .Label > .required {
    color: var(--hc-orange);
  }
}

.InputBorder {
  box-sizing: border-box;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  &:not(.noBorder) {
    border: 1px solid var(--hc-gray);
    border-radius: 3px;
  }
}
.InputElement {
  display: block;
  outline: none;
  border: none;
  width: 100%;
  height: 40px;
  padding: 3px 12px 0 12px;
  box-sizing: border-box;
  font-size: 16px;
  justify-self: stretch;
  border-radius: 3px;

  &::placeholder {
    color: transparent;
    font-family: var(--hc-font);
    font-weight: 300;
  }

  &.visiblePlaceholder::placeholder {
    color: #d0d0d0;
  }

  &.mini {
    height: 30px;
    font-size: 14px;
    line-height: 30px;
  }

  &:required {
    box-shadow: none;
  }

  &:hover {
    border-color: var(--hc-blue);
  }

  &.filled ~ .Label.fixed {
    display: none;
  }

  &.filled,
  &:focus:not([disabled]):not([readonly]) {
    &:hover:not([disabled]) {
      border-color: var(--hc-blue);
    }

    &::placeholder {
      color: #d0d0d0;
    }

    & ~ .LabelMaskOuter:not(.fixed) {
      .LabelMask {
        width: 100%;
      }
    }

    & ~ .Label:not(.fixed) {
      top: 8px;
      left: 8px;
      padding: 0 4px;
      color: var(--hc-gray);
      font-weight: 600;
      font-size: 12px;
    }

    &:hover {
      & ~ .Label:not(.fixed) {
        color: var(--hc-blue);
      }
    }
  }

  &.filled:not(.initialValue) {
    border-color: var(--text-color);

    & ~ .Label:not(.fixed) {
      color: var(--text-color);
    }

    &:hover {
      & ~ .Label:not(.fixed) {
        color: var(--hc-blue);
      }
    }
  }

  &:focus:not([disabled]):not([readonly]) {
    border-color: var(--hc-blue);

    & ~ .bar::before,
    & ~ .bar::after {
      width: 50%;
    }

    & ~ .Label:not(.fixed) {
      color: var(--hc-blue);
    }

    & ~ .Label > .required {
    }

    & ~ .hint {
      display: block;
    }
  }
}

.AlertIcon {
  & svg {
    width: 18px !important;
    height: 18px !important;
    position: absolute;
    right: 12px;
    top: 25px;
  }

  & circle {
    fill: #ff8456;
  }
}

.labelMask {
  top: 8px;
  left: 8px;
  background: white;
  color: transparent !important;
  font-weight: 600;
  font-size: 0px;
  z-index: 1;
  padding: 0;
  overflow: hidden;
}

.LabelMaskOuter {
  position: absolute;
  padding: 0 12px;
  line-height: var(--input-field-font-size);
  pointer-events: none;
  background: transparent;
  color: transparent;
  z-index: 1;
  top: 8px;
  left: 8px;
  padding: 0 4px;
  font-weight: 600;
  font-size: 12px;
}

.LabelMask {
  height: 100%;
  width: 0%;
  position: absolute;
  top: 0;
  left: 50%;
  background: #ffffff;
  z-index: 1;
  transform: translateX(-50%);
  transition-duration: 0.15s;
  transition-property: width, background-color;
  transition-timing-function: ease-out;

  &.fixed {
    display: none;
  }
}

.disabled {
  .LabelMaskOuter {
    .LabelMask {
      background: transparent;
    }
  }
}

.ClearButton {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 12px;
  height: 24px;
  cursor: pointer;
}

.required {
  color: inherit;
}
