.hc-input-text--container {
  text-align: left;
  vertical-align: top;
  display: inline-block;
  margin-bottom: 5px;
  min-height: 105px;

  &.hc-input-text--container--full-width {
    width: 100%;
  }
}

.hc-input-text {
  background-color: #fff;
  border: 0;
  margin: 0;
  vertical-align: bottom;
  transition: color 0.55s, background-color 0.55s;
  width: 100%;
  border-radius: 3px;
  padding: 10px 15px 10px 15px;
  box-sizing: border-box;
  height: 40px;
  border: 1px solid #dedede;
}

.hc-input-text--label {
  font-size: 14px;
  margin-bottom: 5px;
}

.errorWrap--maxlength {
  position: absolute;
  bottom: -18px;
  left: 0px;
}

.errorWrap--maxlength {
  position: absolute;
  bottom: 2px;
  left: 45px;
}
