@import '../../../css-variables/variables.css';

.SubjectComment {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.Placeholder {
  color: var(--text-light-color);
}

.ReadOnly {
  color: var(--text-light-color);
  font-size: 14px;
  white-space: pre-line;
  min-height: 129px;
  border: 1px solid var(--text-color);
  border-radius: 3px;
  padding: 10px;
}
