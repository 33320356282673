@import '../styles/common/variables.css-module.css';

.Heading {
  composes: section-heading from './Common.css-module.css';
  margin-bottom: 25px;
}
.PropertyExplorerValuationSection {
  min-height: 151px;
  position: relative;
}

.NullStateCard {
  margin: 25px auto;
}

.PropertyExplorerValuation {
  display: flex;
  flex-direction: row;
  color: var(--text-color);

  & .PropertyExplorerValuationInfoPanel {
    position: relative;
    border-radius: 4px;
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
    margin-top: 5%;
    padding: 30px;
    min-height: 175px;

    &::after {
      left: 100%;
      top: 33%;
      border: solid transparent;
      content: ' ';
      background-color: white;
      position: absolute;

      width: 30px;
      height: 30px;
      margin-left: -19px;
      transform: rotate(45deg);
      box-shadow: 5px -5px 5px rgba(0, 0, 0, 0.1);
    }
    & hr {
      border-bottom: 1px solid #0a62ff;
      margin: 10px 0px 10px 0px;
    }
    & p {
      margin: 0px;
    }
  }

  .PropertyExplorerValuationInfoSection {
    display: flex;
    flex-direction: column;
    flex: 0 1 400px;
  }

  .PropertyExplorerValuationUnavailable {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .PropertyExplorerValuationUnavailableText {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 0.875rem;
  }

  .PropertyExplorerValuationUnavailable svg {
    width: 40px;
    height: 40px;
  }

  .PropertyExplorerValuationChart {
    width: 800px;
  }

  .Bar {
    fill: #fad96a;
    margin-left: -3px;
    outline: 2px solid #fdbc5f;

    &:hover {
      fill: #fdbc5f;
    }
  }

  .AverageSalePriceLabel {
    fill: #acacac;
    font-weight: bold;
  }
  #avg-adj-sale-price-text {
    font-weight: bold;
    color: #acacac;
  }
  svg text.legend {
    text-anchor: start;
  }
  text.title {
    font-size: 1.2em;
  }
  h1 {
    margin-bottom: 30px;
  }
}

.HcIcon {
  height: 70px;
  width: auto;
}

.Tooltip {
  opacity: 0.9;
  border: 3px solid #ffffff;
  background-color: rgba(255, 255, 255, 0.84);
  border-radius: 5px;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  z-index: 1;

  & .TooltipContent {
    padding: 15px 25px;
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  & .InfoRow {
    font-weight: bold;
  }

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 13px 0 13px;
    border-color: white transparent transparent transparent;
    position: absolute;
    bottom: -10px;
    right: calc(50% - 9px);
  }
}

/* Stack chart and info panel */
@media (max-width: 1149px) {
  .PropertyExplorerValuation {
    flex-direction: column-reverse;
    align-items: center;
    & .PropertyExplorerValuationChart {
      margin-top: -10px;
      flex: none;
    }
    & .PropertyExplorerValuationInfoPanel {
      max-width: 500px;
      margin-top: 10px;
      &::after {
        display: none;
      }
    }
  }
}
