@import '../styles/common/variables.css-module.css';

.DistanceControl {
  width: 100%;
}

.Button {
  width: 100%;
  text-transform: capitalize;
}

.value {
  input {
    margin: 0;
    text-transform: capitalize;
  }

  &::after {
    top: 38%;
  }
}

.Or {
  font-size: 12px;
  font-weight: 900;
  text-align: center;
  background-color: var(--hc-blue);
  color: #fff;
  height: 25px;
  width: 25px;
  margin: 10px auto;
  border-radius: 50%;
  line-height: 25px;
}

.active {
  input {
    border-color: var(--hc-orange);
  }
}
