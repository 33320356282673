.ReadOnlyReportMsg {
  font-size: 16px;
  font-weight: 900;
  line-height: 22px;
  margin-top: 22px;
  position: relative;
  left: -30px;
}

.ReadOnlyIcon {
  width: 28px;
  height: 19px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin-right: 5px;
}
