.SubjectDetailsTable {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  min-height: 200px;
}

.LeftColumn {
  flex: 1 1 50%;
  margin-right: 25px;
  flex: 0 0 calc(50% - 12.5px);
}

.RightColumn {
  flex: 0 0 calc(50% - 12.5px);
}

@media screen and (max-width: 768px) {
  .SubjectDetailsTable {
    flex-wrap: wrap;
  }

  .LeftColumn,
  .RightColumn {
    flex: 0 0 100%;
    margin: 0;
  }
}
