.CompsValueConfidenceBanner {
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  padding: 8px 15px 6px 15px;
  font-weight: 700;
  align-items: center;
  justify-content: space-around;
  background: #ccc;
}

.selectComps {
  color: #4a4a4a;
}

.ValuationSuitabilityScore {
  display: inline-block;
}
.Fsd {
  margin-left: 25px;
  display: inline-block;
}

.high {
  background: #70d48a;
}

.average {
  background: #feb910;
}

.low {
  background: #fd895f;
}
