@import '../../../css-variables/variables.css';

.Page {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
}

.Header {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  border-bottom: 1px solid #dedede;
}

.SubjectHeader {
  flex: 1;
}

.CompSearch {
  z-index: 4;
  position: relative;
}

/* Mapbox uses transform to position the marker */
@keyframes fadeInDownPosition {
  from {
    opacity: 0;
    top: -25px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
}

.HoveredComp {
  z-index: 10;
  position: relative;
  animation: fadeInDownPosition 0.3s;
}

.Main {
  display: flex;
  flex: 0 1 100%;
  overflow: hidden;
}

.Map {
  flex: 1 1 50%;
  position: relative;
  background: var(--hc-gray-light);
}

.List {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.ListOuter {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background: #ffffff;
  height: 100%;
  flex: 1 1 50%;
  position: relative;
  background: green;
}

.ListInner {
  max-height: 100%;
  height: 100%;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #ffffff;
}

.ListHeader {
  flex: 1 1 0;
}

.ListContent {
  flex: 1;
  overflow: auto;
}

.MoreFilters {
  flex: 0 0 345px;
  overflow: auto;
}
