.Label {
  height: 8px;
  width: 8px;
  border-radius: 4px;
  box-sizing: border-box;
  margin-left: 14px;
  border: 1px solid #ffffff;
  position: relative;
  bottom: 30px;
}

.sold {
  background-color: #fd8a5f;
}

.active {
  background-color: #70d48a;
}

.pending {
  background-color: #feb913;
}

.lightLabel {
  color:#ffffff !important;
}