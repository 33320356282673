@import '../styles/common/variables.css-module.css';

.PropertyHeader {
  height: 87px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  padding: 20px 0;
  align-items: flex-start;
  border-bottom: 1px solid #dedede;
  max-width: 100%;
}

.Back {
  flex: 0 0 44px;
  padding: 7px 12px 0 12px;
  box-sizing: border-box;
}

.BackIcon {
  svg {
    height: 16px;
    width: 16px;
    cursor: pointer;
  }
}

.Aside {
  flex: 0 1 400px;
  padding-right: 40px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  align-self: stretch;
}
