.page {
  width: 100%;
  height: 100%;
  background-color: white;
}
.page-header {
  height: 120px;
  font-size: 16px;
}
.page-header.slim {
  height: 80px;
}
.header-middle {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
}
.header-left,
.header-right {
  flex-basis: 20%;
  text-align: center;
}

.header-left {
  font-weight: 700;
  cursor: pointer;
}

.header-middle-message {
  color: #feb913;
  font-size: 13px;
  margin: 10px 0;
  padding: 0 20px;
}
