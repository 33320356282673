.ThumbnailImage {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
}
