.LoginPromptModal {
  max-width: 420px;
}

.BlueDialogHeaderTitle {
  font-size: 20px;
}

.BlueDialogHeaderSubTitle {
  font-size: 14px;
}
