@import '../../css-variables/variables.css';
.Button {
  text-align: center;
  text-transform: capitalize;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 900;
  font-family: var(--hc-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  padding: 1em 1.25em;
  min-width: 154px;
  box-shadow: 0 0.25rem 0.3125rem 0 rgb(0 0 0 / 20%);
  position: relative;
  transform: translateY(0);
  transition: transform 0.125s, box-shadow 0.125s, border 0.45s,
    background 0.45s, color 0.45s, -webkit-transform 0.125s,
    -webkit-box-shadow 0.125s;
  border-radius: 0.1875rem;
  line-height: 1.15;
  margin: 0;
  text-decoration: none;
  /* Default Primary Button Styles */
  background-color: var(--hc-blue);
  border: 0 solid transparent;
  color: #fff;

  &:hover:not(.disabled) {
    box-shadow: 0 0.3125rem 0.3125rem 0.0625rem rgb(0 0 0 / 18%);
    transform: translateY(-0.2rem);
    transition: transform 0.1s, box-shadow 0.1s, border 0.1s, background 0.1s,
      color 0.1s, -webkit-transform 0.1s, -webkit-box-shadow 0.1s;
    background-color: var(--hc-blue-highlight);
    color: #fff;
  }

  &:not(:first-of-type) {
    margin-left: 15px;
  }
}

.secondary {
  background-color: #fff;
  border: 0.0625rem solid #f0f0f0;
  color: var(--hc-blue);
  &:hover:not(.disabled) {
    color: var(--text-color);
    background-color: #fff;
  }
  &:not(:first-of-type) {
    margin-left: 10px;
  }
}

.disabled {
  color: var(--text-light-color);
  background-color: var(--hc-gray-light);
  box-shadow: none;
  transform: none;
  transition: none;
  border-width: 0;
  cursor: not-allowed;
}

.small {
  min-width: auto;
  text-align: right;
  font-weight: 900;
  height: 40px;
  padding: 12px 13px;

  box-sizing: border-box;
  font-size: 0.875rem;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
}

@media screen and (min-width: 650px) {
  .Button:not(.small) {
    font-size: 0.9375rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    padding: 1em 1.25em;
    min-width: 11.5rem;
  }
}
