@import '~hc-pattern-lib/src/hc-pattern-lib';
$max-width: rem(400);
$min-width: rem(300);

.HeaderContainer {
  text-align: center;
  max-width: $max-width;
  min-width: $min-width;

  * {
    color: #ffffff;
  }
}

.ForgotPasswordForm {
  min-width: $min-width;
  max-width: $max-width;
  margin-top: rem(20);
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
}

.button:hover {
  background-color: initial;
}

.SecondaryLink .button {
  color: $faded-grey;
}

.BlueGradHeader {
  background: #0a62ff !important;
}
