.MLSCopyrightText {
  text-align: left;
  font-size: 11px;
  font-weight: 100;
  padding: 0 20px;
  max-width: 1200px;
  margin: 40px auto 0 auto;

  & p {
    margin: 8px 0;
  }
}

.MLSLogo {
  margin-bottom: 5px;
  /* Per design to accommodate all different possible logos */
  max-width: 100px;
  max-height: 62px;
}
