@import '../styles/common/variables.css-module.css';

.CompsFiltersHeader {
  display: flex;
  align-items: center;
  height: 55px;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #dedede;
}

.MoreFiltersCell {
  display: flex;
  align-items: center;

  .filtersActive {
    flex: 0 0 156px;
  }
}

.MoreFiltersButton {
  padding: 0 10px;
  &.open {
    border-color: var(--hc-tuxedo);
    color: #ffffff !important;
    background-color: var(--hc-tuxedo);
  }
}

.FiltersCell {
  flex: 1;
  display: flex;
  overflow: hidden;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  position: relative;

  > * {
    margin-right: 5px;
  }
}

.ActionsCell {
  display: flex;
  padding-right: 15px;

  button {
    white-space: nowrap;
  }
}

.button {
  margin: 3px 0px 0px;
}

.IconWrapper {
  height: 20px;
  width: 20px;
  position: relative;
  display: inline-block;
  top: 6px;
  margin-right: 5px;
  vertical-align: top;

  > * {
    display: inline-block;
    height: 100%;
    width: 100%;
  }
}

.hidden {
  visibility: hidden;
}
