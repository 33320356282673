.CobrandingImgCompany {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 120px;
  background-color: rgb(186, 186, 186);
  color: white;
  font-weight: bold;
}

.CobrandingImgCompanyWithImg {
  background-color: initial;
}

.CobrandingImgCompanyImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
