@import '../../styles/common/variables.css-module.css';

.container {
  position: relative;
  /* margin-bottom: 7px; */
  margin-top: 20px;

  & * {
    font-smoothing: antialiased;
    font-family: var(--hc-font);
  }

  & label {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 19px;
    line-height: 1.1875rem;
    color: var(--text-color);
    position: absolute;
    left: 1px;
    left: 0.0625rem;
    top: -25px;
  }

  & textarea {
    width: 100%;
    border-radius: 0.1875rem;
    border: 1px solid #dedede;
    border: 0.0625rem solid #dedede;
    /* Room enough for the absolutely positioned character count display */
    padding: 10px 80px 10px 10px;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-color);
    box-sizing: border-box;
    font-family: var(--hc-font);
    resize: none;
    height: 125px;
  }
  & textarea::placeholder {
    color: var(--text-light-color);
  }
}

.input-meta {
  text-align: left;
}

.char-count {
  position: absolute;
  top: 10px;
  right: 20px;
  color: var(--text-light-color);
  font-family: var(--hc-font);
  font-size: 12px;
}
