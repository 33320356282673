.CompKeywordSearch {
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.InputCell {
  flex: 1;
}

.ResultsCell {
  flex: 0 0 35px;
  padding-left: 15px;
}

.Match {
  padding: 10px 15px 10px 10px;
  cursor: pointer;

  &:hover {
    background-color: #f6f7f8;
  }
}
.StreetAddress {
  font-weight: 600;
}

.CityStateZip {
  font-size: 0.8em;
  color: rgba(102, 102, 102, 0.89);
}

.clickable {
  cursor: pointer;
}
