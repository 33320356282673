@import '../../styles/common/variables.css-module.css';

.CompTagsTrigger {
  background: white;
  padding: 5px;
  height: 30px;
  width: 30px;
  border-radius: var(--hc-border-radius);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  box-sizing: border-box;

  svg {
    height: 18px;
    width: 18px;
  }
}

.TagControl {
  list-style: none;
  padding: 0;
  font-size: 11px;
}

.Count {
  background-color: #fd8a5f;
  color: white;
  height: 12px;
  width: 12px;
  font-weight: 500;
  line-height: 12px;
  border-radius: 100%;
  text-align: center;
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 7px;
}

.Checkbox {
  transform: scale(0.7);
  position: relative;
  top: 6px;
}

.Content {
  padding: 0 10px;
}
