@import '../../css-variables/variables.css';
.FilterCard {
  box-sizing: border-box;
  border-radius: 6px;
  width: 310px;
  padding: 10px 10px 14px 10px;
  &.bordered {
    padding: 20px 20px 24px 20px;
    border: var(--hc-border);
    border-radius: var(--hc-border-radius);
  }
}

.Title {
  margin: 0px;
  color: #4a4a4a;
  font-family: Avenir;
  font-size: 18px;
  font-weight: 800;
  line-height: 25px;
}

.Info {
  margin: 0px 0px 16px 0px;
  color: #939aa3;
  font-family: Avenir;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
}

.Info.error {
  color: #fd8a5f;
}
