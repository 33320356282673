$namespace: 'md-housecanary-theme';
$swatch-primary: #fdb813;
$swatch-secondary: #0a62ff;
$swatch-gray-dark: rgba(0, 0, 0, 0.54);
$swatch-gray-light: rgba(0, 0, 0, 0.26);

$swatch-high: #8db258;
$swatch-average: $swatch-primary;
$swatch-low: #bf4830;

$table-background: #fafafa;
$table-header-text: #7f7f7f;
$body-background: #f0f2f3;
$toast-background: #f7d894;
$progress-linear: rgba(22, 183, 213, 0.25);
$hc-border: #d8d8d8;
$icon-default: $swatch-gray-dark;
$table-border: rgba(0, 0, 0, 0.12);
$error: $swatch-low;
$warning: $swatch-average;
$update: $swatch-high;
$hc-support-icon: rgb(60, 85, 92);
$sidenav-fill: #737373;
$sidenav-background: #e1eaf0;
$billing-subtext: $swatch-gray-light;
$disabled: $swatch-gray-dark;
$hc-box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.4);
$hc-text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);

/*Caution when changing auth-body-background variable*/
$auth-body-background: #02242c;
$text-color: rgba(0, 0, 0, 0.89);
$aside-text-color: #9c9c9c;

/*Base Convention for conversion: 1rem = 16px = 62.5%;*/
$browser-context: 16px;
$base-rem: 1rem;

/* Top nav bar height */
$top-nav-height: 50px;

@function calc-rem($target-px) {
  @return ($target-px * $base-rem) / $browser-context;
}

$px-rem-2: calc-rem(2px);
$px-rem-3: calc-rem(3px);
$px-rem-5: calc-rem(5px);
$px-rem-6: calc-rem(6px);
$px-rem-7: calc-rem(7px);
$px-rem-8: calc-rem(8px);
$px-rem-9: calc-rem(9px);
$px-rem-10: calc-rem(10px);
$px-rem-11: calc-rem(11px);
$px-rem-12: calc-rem(12px);
$px-rem-13: calc-rem(13px);
$px-rem-14: calc-rem(14px);
$px-rem-15: calc-rem(15px);
$px-rem-16: calc-rem(16px);
$px-rem-17: calc-rem(17px);
$px-rem-18: calc-rem(18px);
$px-rem-20: calc-rem(20px);
$px-rem-21: calc-rem(21px);
$px-rem-22: calc-rem(22px);
$px-rem-24: calc-rem(24px);
$px-rem-25: calc-rem(25px);
$px-rem-26: calc-rem(26px);
$px-rem-28: calc-rem(28px);
$px-rem-30: calc-rem(30px);
$px-rem-34: calc-rem(34px);
$px-rem-35: calc-rem(35px);
$px-rem-40: calc-rem(40px);
$px-rem-45: calc-rem(45px);
$px-rem-46: calc-rem(46px);
$px-rem-50: calc-rem(50px);
$px-rem-55: calc-rem(55px);
$px-rem-64: calc-rem(64px);

$font-size-9: $px-rem-9;
$font-size-10: $px-rem-10;
$font-size-11: $px-rem-11;
$font-size-12: $px-rem-12;
$font-size-13: $px-rem-13;
$font-size-14: $px-rem-14;
$font-size-15: $px-rem-15;
$font-size-16: $px-rem-16;
$font-size-18: $px-rem-18;
$font-size-20: $px-rem-20;
$font-size-22: $px-rem-22;
$font-size-24: $px-rem-24;
$font-size-26: $px-rem-26;
$font-size-28: $px-rem-28;
$font-size-30: $px-rem-30;
$font-size-34: $px-rem-34;
$font-size-35: $px-rem-35;
$font-size-40: $px-rem-40;
$font-size-45: $px-rem-45;
$font-size-46: $px-rem-46;
$font-size-64: $px-rem-64;

$padding-2: $px-rem-2;
$padding-3: $px-rem-3;
$padding-5: $px-rem-5;
$padding-6: $px-rem-6;
$padding-8: $px-rem-8;
$padding-10: $px-rem-10;
$padding-15: $px-rem-15;
$padding-16: $px-rem-16;
$padding-20: $px-rem-20;
$padding-25: $px-rem-25;
$padding-26: $px-rem-26;
$padding-30: $px-rem-30;
$padding-40: $px-rem-40;

$margin-2: $px-rem-2;
$margin-3: $px-rem-3;
$margin-5: $px-rem-5;
$margin-8: $px-rem-8;
$margin-10: $px-rem-10;
$margin-15: $px-rem-15;
$margin-16: $px-rem-16;
$margin-18: $px-rem-18;
$margin-20: $px-rem-20;
$margin-25: $px-rem-25;
$margin-30: $px-rem-30;
$margin-35: $px-rem-35;
$margin-50: $px-rem-50;
$margin-55: $px-rem-55;

/* Breakpoints (to match Angular Material) */
$smallScreen: 600px;
$mediumScreen: 960px;
$largeScreen: 1280px;
$xlargeScreen: 1920px;
