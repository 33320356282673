.Chart {
  display: block;
  margin: 0 0 0 -40px;
  /* Hardcoded height needed for IE11 */
  height: 400px;
}

.LoadingSpinner {
  margin-top: 150px !important;
}
