@import '../../../css-variables/variables.css';

.Legal {
  height: 25px;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  white-space: normal;
}

.BrokerageCredit {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 7px);
  bottom: 15px;
  width: 100%;
}
