@import '../styles/common/variables.css-module.css';

.locationUnknown {
  display: none;
}

.userDefinedLocation {
  > div {
    &:first-child {
      > div {
        &:first-child {
          background: var(--text-color);
        }
      }
    }
  }
}

.userDefinedLocationActive {
  > div {
    &:first-child {
      > div {
        &:first-child {
          background: var(--hc-orange);
        }
      }
    }
  }
}
