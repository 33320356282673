/* Container */
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.inline-flex {
  display: inline-flex;
}
.flex-center {
  align-items: center;
  align-content: center;
}
.flex-align-start {
  align-items: flex-start;
}
.flex-align-end {
  align-items: flex-end;
}
.flex-justify-start {
  justify-content: flex-start;
}
.flex-justify-space-between {
  justify-content: space-between;
}
.flex-justify-end {
  justify-content: flex-end;
}
.flex-wrap {
  flex-wrap: wrap;
}
/* Item */
.flex-item,
.flex-item-1 {
  flex: 1 1 auto;
}
.flex-item-2 {
  flex: 2 2 auto;
}
.flex-item-3 {
  flex: 3 3 auto;
}
.flex-basis-0 {
  flex-basis: 0;
}
.flex-1-0 {
  flex: 1 0 auto;
}
.flex-0-0 {
  flex: 0 0 auto;
}
.flex-0-0-0 {
  flex: 0 0 0;
}
.flex-50 {
  flex: 1 50%;
}
.flex-100 {
  flex: 1 100%;
}
.with-full-height-child {
  position: relative;
  & > * {
    position: absolute;
    height: 100%;
  }
}
