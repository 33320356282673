@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(130%, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.leftToRight-enter.leftToRight-enter-active {
  animation-name: fadeInLeft;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.leftToRight-leave.leftToRight-leave-active {
  animation-name: fadeOutRight;
  animation-duration: 1s;
  animation-fill-mode: both;
  position: absolute;
  top: 0;
}

.rightToLeft-enter.rightToLeft-enter-active {
  animation-name: fadeInRight;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.rightToLeft-leave.rightToLeft-leave-active {
  animation-name: fadeOutLeft;
  animation-duration: 1s;
  animation-fill-mode: both;
  position: absolute;
  top: 0;
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(135deg);
  }
  60% {
    opacity: 1;
    transform: translateY(30px) rotate(135deg);
  }
  80% {
    transform: translateY(-10px) rotate(135deg);
  }
  100% {
    transform: translateY(0) rotate(135deg);
  }
}

@keyframes raise {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-5px);
  }
}
@keyframes return {
  from {
    transform: translateY(-5px);
  }
  to {
    transform: translateY(0px);
  }
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInDown-enter,
.zoomInDown-enter-active {
  animation-name: zoomInDown;
  animation-duration: 600ms;
  opacity: 1;
}

.zoomInDown-leave,
.zoomInDown-leave-active {
  animation-name: zoomInDown;
  animation-duration: 0ms;
  opacity: 0;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeInDownFadeOut-enter,
.fadeInDownFadeOut-enter-active {
  animation-name: fadeInDown;
  animation-duration: 300ms;
}

.fadeInDownFadeOut-leave,
.fadeInDownFadeOut-leave-active {
  animation-name: fadeOut;
  animation-duration: 300ms;
}

.fadeInFadeOut-enter,
.fadeInFadeOut-enter-active {
  animation-name: fadeIn;
  animation-duration: 300ms;
}

.fadeInFadeOut-leave,
.fadeInFadeOut-leave-active {
  animation-name: fadeOut;
  animation-duration: 300ms;
}
