@import '../../css-variables/variables.css';
.NullState {
  position: relative;
  width: 100%;
  max-width: 600px;
  font-family: var(--hc-font);
  border: var(--hc-border);
  border-radius: var(--hc-border-radius);
  box-sizing: border-box;
  background: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 80px;
}

.Icon {
  position: absolute;
  line-height: 1;
  height: 1px;
  top: -20px;
  max-width: 600px;
  margin-left: 10px;
}

.Title {
  margin: auto;
  font-weight: 100;
  font-size: 16px;
}
