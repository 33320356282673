.outer {
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 50px;
  z-index: 2;
  height: 0;
  overflow: hidden;
  transition: height 0.25s;

  &.visible {
    height: 70px;
    border-bottom: 3px solid #f3f3f3;
  }
}

.content {
  max-width: 1250px;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  & > div {
    margin-right: 25px;
  }
}

.user-photo {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin-left: 10px;
  margin-top: 5px;
  display: inline-block;
}

.user-name {
  font-size: 18px;
}

@media print {
  .outer {
    display: none;
  }
}
