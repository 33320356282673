@import '../styles/common/variables.css-module.css';

.Page {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-touch-callout: none;
  user-select: none;
}

.DateWarnings {
  position: absolute;
  bottom: -8px;
  > * {
    margin-bottom: 3px !important;
  }
}

.Header {
  background: #fff;
  margin: 0;
  position: relative;
  z-index: 5;
  height: 135px;
  flex: 0 0 135px;
  align-items: center;
  border-bottom: var(--hc-border);
}

.HeaderTop {
  display: flex;
  height: 65px;
}

.CompSearch {
  min-width: 400px;
  width: 100%;
  max-width: 800px;
  z-index: 10;
}

.Loading {
  height: 100%;
  width: 100%;
  z-index: 100;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;

  &.filtersOpen {
    > div {
      transform: translate(calc(-50% + 200px), -50%);
    }
  }
}

.Back {
  display: flex;
  align-items: center;
  flex: 0 0 200px;
  padding: 0 0 0 20px;
  a {
    color: var(--hc-blue);
    font-weight: bold;
  }
}

.LinkContentWrapper {
  display: flex;
  align-items: center;
}

.AvmCell {
  display: flex;
  align-items: center;
  flex: 1;
}

.Avm {
  color: var(--text-color);
  font-size: 24px;
  font-weight: 900;
  line-height: 33px;
  text-shadow: 0 5px 42px 0 rgba(0, 0, 0, 0.5);
  margin: 0 auto;
}

.MobileRental.Avm {
  font-size: 20px;
  line-height: 25px;
}

.AvmLabel {
  display: inline;
}

.Search {
  display: flex;
  align-items: center;
  flex: 0 0 330px;
  padding: 0 20px 0 0;

  > div {
    width: 100%;
  }
}

.HeaderBottom {
  min-height: 70px;
}

.Content {
  background: #fff;
  display: flex;
  flex: 1;
  align-items: stretch;
  height: calc(100% - 135px);
  overflow: hidden;
  position: relative;

  &.ie {
    height: calc(100% - 185px);
  }
}

.ContentColumn {
  margin: 0;
  padding: 0;
  flex: 1;
  position: relative;
  height: 100%;

  display: flex;
  flex-direction: column;

  &.hidden {
    overflow: hidden;
  }
  &.auto {
    overflow: auto;
  }
}

.ResizableColumn {
  .ContentColumn {
    width: 100%;
  }
}
.TopColumn {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.BottomColumn {
  box-shadow: none;
  z-index: 1;
}

.SmallColumn {
  > div {
    visibility: hidden;
    &:first-child {
      visibility: visible;
    }
  }
}

@media (max-width: 960px) {
  .Search {
    flex: 0 0 70px;
    > div {
      text-align: right;
      border: none;
      cursor: pointer;
    }
  }
}

@media (max-width: 768px) {
  .Back {
    flex: 0 0 70px;
  }
  .Avm {
    text-align: center;
    margin: auto;
    padding-top: 5px;
  }
  .AvmLabel {
    display: block;
  }
}
