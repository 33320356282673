.MLSCards {
  height: 100%;
  position: relative;
}

.MLSCard {
  overflow: visible;
  margin: 10px 10px 0 0;
  display: inline-block;
  width: calc(50% - 10px);
}

@media (max-width: 1250px) {
  .MLSCard {
    width: 100%;
  }
}
