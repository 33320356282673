.CompMarkerPopup {
  z-index: 4;
}

.CompMarkerPopup > div {
  padding: 0;
}

.CompMarkerPopupContent {
  display: flex;
  height: 165px;
  width: 400px;
}

.Photos {
  height: 100%;
  flex: 0 0 180px;
}

.Content {
  padding: 15px;
  flex: 1;
  position: relative;
}
