@import '../../css-variables/variables.css';

.Details {
  font-size: 12px;
  color: var(--text-color);
  font-weight: 600;
}

.Attributes {
  line-height: 30px;
}

.Attribute {
  display: inline-block;
  &:not(:last-child)::after {
    content: '|';
    font-weight: 100;
    font-size: 16px;
    color: var(--border-color);
    margin: 0 8px;
  }
}

.keywordMatch {
  background-color: var(--keyword-color-light);
  padding: 10px 10px 6px 10px;
  margin: -10px -10px -6px -10px;
}
