.TextInputPropertyDetails {
  &.input {
    top: 0px;
    width: 100%;
    max-width: 200px;
    padding: 0;
  }

  .counter {
    display: none;
  }

  input {
    margin: 0 !important;
    height: 27px !important;
  }
}
