.Thumbnail {
  padding: 0 5px;
  box-sizing: border-box;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;

  > .FullscreenButton {
    display: none;
    animation-name: fadeInDown;
    animation-duration: 0.3s;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
  }

  > img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }

  &:hover {
    > .FullscreenButton {
      display: block;
    }
  }
}

.LazyLoadImage {
  text-align: center;
  display: block !important; /* important is here to overwrite an inline style fromt he library */
  margin: auto;

  > img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
}

.clickable {
  > img {
    cursor: pointer;
  }
}

.ThumbnailOverlay {
  background-color: rgba(74, 74, 74, 0.5);
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  height: 16px;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  bottom: 15px;
  padding: 1px 7px 0 7px;
  transform: translateX(-50%);
  text-align: center;
  white-space: nowrap;
  max-width: 90%;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 2;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
