@import '../../css-variables/variables.css';

.Fullscreen {
  font-family: var(--hc-font);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999 !important;
}

.Carousel {
  display: flex;
  max-height: calc(100vh - 150px);
  margin: auto;
  width: 100%;
  margin: auto;
}

.CarouselButton {
  width: 40px;
  height: 40px;
  background-color: #f7f9fa;
  transition: opacity 0.5s, padding 0.3s, left 0.3s, right 0.3s;
  z-index: 10001;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  border-radius: 50%;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.btnLeft {
    left: 24px;
    animation-name: fadeInLeft;
    animation-duration: 1s;
    svg {
      transform: translate(-55%, -50%) rotate(0deg) scale(0.6);
    }
  }

  &.btnRight {
    right: 24px;
    animation-name: fadeInRight;
    animation-duration: 1s;
    svg {
      transform: translate(-50%, -50%) rotate(180deg) scale(0.6);
    }
  }

  svg {
    position: absolute;
    height: 52px;
    width: 37px;
    top: 50%;
    left: 50%;
  }
}

.CarouselButtonCell {
  flex: 0 0 120px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.CarouselImg {
  flex: auto;
  text-align: center;
  position: relative;
  -webkit-touch-callout: none;
  user-select: none;
  > img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    animation-name: zoomIn;
    animation-duration: 0.3s;
  }
}

.FullscreenCarousel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 1460px;
  z-index: 2;
}

.FullscreenContent {
  max-width: 1460px;
  width: 100%;
  margin: auto;
  border-radius: 4px;
  background: #ffffff;
}

.FullscreenOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

.FullscreenMetadata {
  font-family: var(--hc-font);
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  padding: 10px 24px;
  align-items: center;
  z-index: 1001;
  box-sizing: border-box;
}

.upper {
  background-color: #f7f9fa;
  border-radius: 4px 4px 0 0;
}

.FullscreenMetadataContentUpper {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 700;
}

.FullscreenMetadataContentLower {
  color: var(--text-light-color);
  font-size: 14px;
  font-weight: 300;
}

.Counter {
  font-weight: 700;
  font-size: 14px;
  text-align: right;
  padding-right: 10px;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, -50%, 0);
  }

  50% {
    opacity: 0;
    transform: translate3d(-100%, -50%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, -50%, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, -50%, 0);
  }

  50% {
    opacity: 0;
    transform: translate3d(100%, -50%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, -50%, 0);
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 0, -1000px);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
