@import '../../css-variables/variables.css';

.PhotoGrid {
  font-family: var(--hc-font);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: auto;
  box-sizing: border-box;
  overflow-y: auto;
}
