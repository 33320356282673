@import '../styles/common/variables.css-module.css';

.Date {
  font-size: 12px;
  white-space: nowrap;
}

.Index {
  display: inline-block;
  font-size: 36px;
  font-weight: 900;
  padding-top: 10px;
  white-space: nowrap;
  color: var(--text-color);
  padding-right: 7px;
}

.Market {
  display: inline-block;
  font-size: 10px;
  color: var(--text-color);
  line-height: 100%;
}

.MarketType {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}

.PointRow {
  white-space: nowrap;
  min-width: 153px;
}
