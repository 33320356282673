@import '../styles/common/variables.css-module.css';

.ConditionSelector {
  display: flex;
  align-items: flex-start;
  position: relative;

  .Tooltip {
    margin: 0;
  }
}

.SelectorButtonWrapper {
  margin-right: 4px;
  height: 72px;
  cursor: pointer;
  position: relative;
  flex: 0 1 92px;
  max-width: 92px;

  &:hover {
    .SelectorButton {
      height: 64px;
    }
  }

  &.active {
    .SelectorButton {
      background-color: var(--hc-blue);
      font-weight: 700;
      height: 64px;

      .Caret {
        border-color: var(--hc-blue) transparent transparent transparent;
      }
    }

    .SelectorButtonLabel {
      color: #ffffff;
      font-weight: 700;
    }
  }
}

.SelectorButton {
  height: 14px;
  width: 100%;
  border-radius: 3px;
  background-color: #e3e8ed;
  position: relative;
  z-index: 1;
  transition: height 0.3s, background-color 0.3s;

  .Caret {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-color: #e3e8ed transparent transparent transparent;
    position: absolute;
    top: 100%;
    left: 0;
    transition: border-width 0.3s, border-color 0.3s;
  }
}

.SelectorButtonLabel {
  font-size: 12px;
  line-height: 17px;
  font-weight: 500;
  text-align: center;
  position: absolute;
  z-index: 1;
  top: 18px;
  left: 50%;
  width: 100%;
  box-sizing: border-box;
  padding: 0 5px;
  transform: translateX(-50%);
}

.ConditionExplanation {
  background-color: #ffffff;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.06);
  position: absolute;
  padding: 15px;
  font-size: 12px;
  left: calc(100% + 10px);
  line-height: 16px;
  z-index: 10;
}

.Source {
  color: #939aa3;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  margin-top: 10px;
}

@media (max-width: 885px) {
  .ConditionExplanation {
    left: 0;
    width: 100% !important;
    top: calc(100% + 10px);
  }
}

@media (max-width: 590px) {
  .SelectorButtonLabel {
    font-size: 9px;
    line-height: 13px;
  }
}
