@import '../styles/common/variables.css-module.css';

.NearbyChartDialog {
  width: 80vw;
  max-width: 1350px;
}

.ChartsContainer {
  display: flex;
  flex-wrap: wrap;
}

.ChartButton {
  height: 100px;
  max-width: 500px;
  border-bottom: 1px solid rgb(222, 222, 222);
  box-sizing: border-box;
  cursor: pointer;
}

.LinkButton {
  flex: 0 1 calc(50% - 30px);
  &:nth-child(odd) {
    margin-right: 60px;
  }
}

.ChartButtonHeader {
  color: var(--text-color);
  margin: 20px 0px 10px 0px;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 900;
  line-height: 22px;
}

.ChartButtonText {
  color: var(--text-color);
  font-family: Avenir;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  b {
    font-weight: bold;
  }
}

.ChevronContainer {
  float: right;
  margin-top: -15px;
}

.Dialog {
  margin-top: 30px;
  max-height: calc(100vh - 90px);
}

.DialogHeader {
  display: flex;
  background-color: #f7f9fa;
  color: var(--text-color);
  padding: 24px 30px;
  font-size: 24px;
  font-weight: 700;
}

.Icon {
  padding: 0px;
  margin-left: 10px;
}

.SimilarHomesChart {
  min-width: 1150px;
}

@media (max-width: 1149px) {
  .SimilarHomesChart {
    min-width: 800px;
  }
}
