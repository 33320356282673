:root {
  /* Colors */
  --text-color: #4a4a4a;
  --text-light-color: #939aa3;
  --hc-orange: #feb913;
  --hc-blue: #0a62ff;
  --hc-blue-highlight: #0a62ff;
  --hc-blue-dark: #2d3b4c;
  --border-color: #eaeaea;
  --hc-gray: #c9c9c9;
  --hc-gray-light: #f9f9f9;
  /* RANGE */
  --swatch-high: #42d882;
  --swatch-medium: #ffb600;
  --swatch-low: #ff7944;
  /* STATUS */
  --swatch-success: #70d48a;
  --swatch-disabled: #eaeaea;
  --swatch-error: #fd8a5f;
  --swatch-warn: #feb913;
  /* OVERLAY */
  --overlay-light: rgba(255, 255, 255, 0.8);
  --overlay: rgba(33, 33, 33, 0.48);
  /* MLS STATE */
  --color-active: #70d48a;
  --color-sold: #ff7944;
  --color-pending: #fdb813;

  --subject-gradient: linear-gradient(160deg, #7cd4e6 10%, #006090 90%);
  /* KEYWORD SEARCH RESULTS */
  --keyword-color-dark: #feb913;
  --keyword-color-dark-rgba: rgba(254, 185, 19, 1);
  --keyword-color-light: #ffdc98;
}
