@import '../../styles/common/variables.css-module.css';

.simple-loader {
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;

  &.simple-loader-medium svg {
    width: 80px;
  }

  &.simple-loader-small svg {
    width: 30px;
  }
  circle {
    stroke: var(--hc-blue) !important;
  }
}
