.SearchPage {
  width: 100%;
  position: relative;
  height: calc(100% - 64px);
  display: flex;
  &.ie {
    height: calc(100% - 114px);
  }
}

.Map {
  flex: 1;
  position: relative;
}

.Sidebar {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 0px;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  transition: flex-basis 0.3s, opacity 0.3s;

  &.visible {
    flex-basis: 550px;
    opacity: 1;
  }
}

.isMobile {
  .LandingSidebar {
    &.visible {
      flex-basis: 0px;
      opacity: 0;
    }
  }

  &.isPreviewView {
    flex-direction: column-reverse;

    .PreviewSidebar {
      flex-shrink: 1;
      flex-grow: 1;
      flex-basis: 0;
    }

    &:not(.isMobileBarExpanded) {
      .Map {
        flex: 0 0 calc(100% - 75px);

        [data-hc-name='map-controls'] {
          position: absolute;
          bottom: 75px;
          background: #ffffff;
        }
      }
      .PreviewSidebar {
        flex: 0 0 75px;
      }
    }
  }
}

.ie.isNotificationsDrawerExpanded {
  .LandingSidebar {
    opacity: 0 !important;
  }
}

@media (max-width: 1250px) {
  .LandingSidebar {
    &.visible {
      flex-basis: 330px;
      opacity: 1;
    }
  }
}
