@import '../styles/common/variables.css-module.css';

.Toast {
  text-align: center;
  max-width: 500px;
  width: auto !important;
  margin: auto;
  transform: translate(-50%, 0) !important;
  left: 50% !important;
  top: 46px !important;
  bottom: auto !important;
  right: auto !important;
  border-radius: 4px !important;
  background: rgba(75, 75, 75, 0.85) !important;
  font-weight: 500 !important;
  box-shadow: var(--hc-box-shadow) !important;
  z-index: 9999 !important;

  * {
    color: #FFFFFF;
  }
}
