@import '../styles/common/variables.css-module.css';

.RiskOfDeclineChart {
  position: relative;
  padding-bottom: 20px;
}

.ChartContent {
  display: block;
  position: relative;
}

.BarLabel {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  white-space: nowrap;
}
.BarLabelExtreme {
  &:first-child {
    text-align: left;
    padding-left: 1px;
  }
  &:last-child {
    text-align: right;
    padding-right: 1px;
  }
}

.Bar {
  position: relative;
  width: 100%;
  height: 17px;
  border-radius: 17px;
  background: linear-gradient(
    -90deg,
    #fd8a5f 9%,
    #f8c446 52%,
    #deec31 72%,
    #2cdaa9 100%
  );
}

.BarLabelContainer {
  width: 100%;
  padding-top: 5px;
}

.Subject {
  position: relative;
  transition: left 0.5s;
  top: 0;
  height: 78px;
  width: 0;
  margin-bottom: 5px;
  overflow: visible;
}
.SubjectLogo {
  width: auto;
  height: 100%;
}

.SubjectLabel {
  font-size: 14px;
  font-weight: 900;
  line-height: 19px;
  text-align: center;
  white-space: nowrap;
  position: relative;
  left: -20px;
  top: -10px;
}

.ValueLabel {
  text-align: center;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.ValueLabelPercent {
  display: inline-block;
  font-size: 36px;
  font-weight: 900;
  padding-top: 10px;
  white-space: nowrap;
  color: var(--text-color);
  padding-right: 7px;
}

.RiskLevel {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}

.ValueLabelDescription {
  display: inline-block;
  font-size: 10px;
  color: var(--text-color);
  line-height: 100%;
  text-align: left;
}

.ValueTick {
  background-color: var(--text-color);
  height: 16px;
  width: 4px;
  border-radius: 4px;
  position: absolute;
  bottom: 6px;
  left: 0%;
}

.SubjectLeft {
  .ValueLabel {
    left: -10px;
  }
}

.SubjectCenter {
  .ValueLabel {
    transform: translateX(-50%);
  }
  .ValueTick {
    transform: translateX(-50%);
  }
}

.SubjectRight {
  .ValueLabel {
    left: 10px;
    transform: translateX(-100%);
  }
}

.Tick {
  position: absolute;
  bottom: 0;
  height: 75%;
  width: 1px;
  background-color: #fff;
}

.TickVeryLow {
  left: 10%;
}
.TickLow {
  left: 30%;
}
.TickNeutral {
  left: 50%;
}
.TickHigh {
  left: 70%;
}

.LabelVeryLow {
  width: 10%;
}
.LabelLow {
  width: 20%;
}
.LabelNeutral {
  width: 20%;
}
.LabelHigh {
  width: 20%;
}
.LabelVeryHigh {
  width: 30%;
}

@media (max-width: 700px) {
  .BarLabel {
    display: none;
  }

  .BarLabelExtreme {
    display: block;
    &:first-child {
      float: left;
    }
    &:last-child {
      float: right;
    }
  }
}
