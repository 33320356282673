/* Override fixed-data-table styling */
.fixedDataTableLayout_main {
  border: none !important;
  .fixedDataTableLayout_header,
  .fixedDataTableLayout_header .public_fixedDataTableCell_main {
    border: none;
    background-image: none;
    background-color: #fafafa;
  }
  .fixedDataTableLayout_header .public_fixedDataTableCell_cellContent {
    padding: 0 8px;
    font-weight: 100;
    cursor: pointer;
  }
  .no-click {
    * {
      cursor: default !important;
    }
  }
  .public_fixedDataTableCell_main {
    border: none;
  }
  .public_fixedDataTableCell_cellContent {
    padding: 12px 8px;
    font-size: 14px;
    text-align: left;
  }
  .public_fixedDataTable_hasBottomBorder {
    border: none;
  }
  .public_fixedDataTable_bottomShadow {
    display: none;
  }
  .fixedDataTableCellLayout_wrap1 {
    /* Cause cells to not be allowed to overflow their column's set width.
     * Used in implementing text-overflow ellipsis for cell content */
    table-layout: fixed;
    width: 100%;
  }
  .comps-table--last-cell
    .public_fixedDataTableCell_wrap1
    .public_fixedDataTableCell_wrap2
    .public_fixedDataTableCell_cellContent {
    padding-right: 20px;
  }
  /* Style the fixed first row (located in the fixed header) like other rows */
  .public_fixedDataTable_header .fixed-data-table--with-fixed-first-row--cell {
    background-color: white !important;
    border-bottom: 3px solid #f3f3f3;

    .public_fixedDataTableCell_cellContent {
      padding: 12px 8px;
    }
  }
}
.public_fixedDataTableRow_main:not(.public_fixedDataTable_header)
  .comps-table--address-cell
  .public_fixedDataTableCell_cellContent {
  font-weight: 600;
}

.public_fixedDataTableCell_cellContent .map-marker-label {
  width: 70px;
}

.fixedDataTableRowLayout_fixedColumnsDivider.public_fixedDataTableRow_fixedColumnsDivider {
  border-left-width: 0;
}
