@import '../styles/common/variables.css-module.css';

.QuickFilterButton {
  border: 1px solid #d8d8d8;
  background-color: transparent;
  border-radius: 4px;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  padding: 8px 10px;
  cursor: pointer;
}

.QuickFilterButton.active {
  border: 1px solid var(--hc-blue);
}

.disabled {
  cursor: not-allowed;
}
