@import '../styles/common/variables.css-module.css';

.Warning {
  background-color: #ffdc98;
  border-radius: 4px;
  padding: 0 15px;
  margin-bottom: 15px;
  color: var(--text-color);
  border: 1px solid var(--hc-orange);
}

.compact {
  h3,
  p {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
  }
}
