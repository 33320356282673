.select {
  background-color: transparent;
  border: none;
  appearance: none;
  background-repeat: no-repeat;
  background-size: 13px;
  background-position: 100%;
  display: inline-block;
  font: inherit;
  padding: 0 18px 0 0;
  white-space: normal;
  background-image: url('../../assets/svg/chevron_down.svg');

  &::-ms-expand {
    display: none;
  }
}
