.RangeAdjusterButton {
  font-family: 'Avenir', sans-serif;
  height: 36px;
  line-height: 36px;
  user-select: none;
  vertical-align: middle;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}
