.Dialog {
  min-width: 600px;
  max-width: 1400px;
  border-radius: 4px !important;
}

.DialogContent {
  padding: 0px;
}

.DialogHeaderConatiner {
  border-radius: 4px 4px 0px 0px;
}

.DialogHeader {
  background-color: #f7f9fa;
  padding: 24px 0px 24px 40px !important;
}

.DialogHeaderHeading {
  line-height: 33px;
  font-size: 24px;
  font-weight: 700;
}
