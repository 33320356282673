.TableHeaderInfo {
  display: flex;
  overflow: visible;
}

.Info {
  flex: 0 0 12px;
  width: 12px;
  height: 12px;
  margin-left: 5px;

  svg {
    margin-top: 0;
    height: 100%;
    width: 100%;
  }
}

.Label {
  flex: 1;
}
