@import '../styles/common/variables.css-module.css';

.PropertyCompare {
  box-sizing: border-box;
  padding: 0px 15px;
}
.AdjustRow {
  height: 32px;
}
.Subject {
  min-width: 330px;
  max-width: 330px;
  background-color: #f7f7f7;
  padding: 0px 15px 0px 20px;
}

.Comp {
  flex: 1 1;
  min-width: 300px;
  max-width: 300px;
}

.Heading {
  font-size: 14px;
  font-weight: 900;
  color: var(--text-color);
  margin: 0;
  padding: 10px 0px;
  height: 36px;
  box-sizing: border-box;
}

.PropertyCard {
  height: 305px;
}

.TextButton {
  padding: 15px 3px;
  margin: 0 10px 0 5px;

  &.TextButtonCancel {
    color: #9b9b9b;
  }
}

.PropertyLookup {
  margin: 15px auto 30px auto;
  text-align: center;
}
