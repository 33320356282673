@import './animations.css';
@import './colors.css';
@import './fonts.css';
@import './z-index.css';

:root {
  /* Other */
  --hc-box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.4);
  --hc-border: 1px solid var(--border-color);
  --hc-border-radius: 4px;
  --section-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.13);

  /* Z-index */
  --z-index-top: 1001;
  --z-index-dialog: 1000;
}
