@import '../../../css-variables/variables.css';

.SelectButton {
  position: absolute;
  right: 10px;
  transform: translateY(-100%);
}

.Legal {
  height: 25px;
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  white-space: normal;
}

.BrokerageCredit {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 7px);
  bottom: 15px;
  width: 100%;
}

.StreetViewLauncherButton {
  position: absolute;
  z-index: 2;
  bottom: 10px;
  right: 10px;
}
