@import '../styles/common/variables.css-module.css';

.SelfServiceCheckout {
  max-width: 500px;
  margin: 0 auto;
}

.Link {
  margin: auto;
  display: block;
  text-align: center;
  padding: 25px 0;
}

.LoadingMessage {
  color: var(--text-light-color);
  text-align: center;
}

.Label {
  padding-top: 15px;
}

.Success {
  text-align: center;
  padding: 30px 0;

  .SuccessIcon svg {
    height: 100px;
  }
  .Title {
    color: #1de179;
  }
}

.Button {
  margin-right: 15px;
}
