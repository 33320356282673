@import '../../css-variables/variables.css';

.PropertyCard {
  border-radius: var(--hc-border-radius);
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background: #ffffff;
  position: relative;
}

.Photos {
  width: 100%;
  height: 159px;
  position: relative;
  background-color: var(--hc-gray-light);
}

.Content {
  padding: 10px;
}
