.PopoverContent {
  animation: fadeInDown 0.3s;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  background-color: #ffffff;
  visibility: hidden;
  border: none;
  border-radius: 4px;
  padding: 20px;
  font-size: 14px;
  font-weight: 100;
  box-sizing: border-box;
  line-height: 1.2rem;
  text-align: left;
  box-shadow: -2px 2px 10px #cccccc;

  &.visible {
    visibility: visible;
  }

  &.top {
    animation: fadeInUp 0.3s;
  }

  &.bottom {
    animation: fadeInDown 0.3s;
  }
}

.PopoverCaret {
  font-size: 14px;
  color: #ffffff;
  position: absolute;
  left: 0;
  height: 14px;
}

.Overlay {
  position: fixed;
  z-index: 9998;
  height: 100%;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
  left: 0;
  top: 0;
}

.RefWrap {
  display: initial;
}

.top {
  .PopoverCaret {
    bottom: -10px;
    > div {
      text-shadow: 0 -2px 0 #ffffff, 0 2px 4px #cccccc;
      &:after {
        content: '▼';
        color: #FFFFFF;
      }
    }
  }
}

.bottom {
  .PopoverCaret {
    top: -13px;
    > div {
      text-shadow: 0 2px 0 #ffffff, 0 -2px 4px #cccccc;
      &:after {
        content: '▲';
        color: #FFFFFF;
      }
    }
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-15px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
