@import '../../../css-variables/variables.css';

.ListingStatusBrief {
    display: inline;
    color: var(--text-color);
    font-size: 16px;
    font-weight: 700;
}

.Bullet {
    display: inline-block;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    margin: 0 6px 1px 0;
}

.leased,
.sold {
    background-color: var(--color-sold);
}

.listed {
    background-color: var(--color-active);
}

.pending {
    background-color: var(--color-pending);
}
