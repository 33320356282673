@import '../styles/common/variables.css-module.css';

.PropertyTransactionHistory {
  width: 100%;
  overflow-x: auto;
  padding-bottom: 12px;
  flex: 1;
  align-items: stretch;
  display: flex;
  flex-direction: column;
}

.RowWrapper {
  display: flex;
}

.Row {
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  box-sizing: border-box;
  border-bottom: var(--hc-border);
  background-color: #fff;
  &:hover {
    background-color: #f7f7f7;
  }
}

.Content {
  min-height: 150px;
}

.HeaderRow {
  min-width: 100%;
  height: 30px;
  border-bottom: 2px solid #000;
  &:hover {
    background-color: #fff;
  }
}

.Cell {
  font-size: 14px;
  font-weight: 700;
  padding: 0px 5px;
  color: var(--text-color);
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  > div {
    display: inline-block;
  }
}

.HeaderCell {
  font-size: 14px;
  font-weight: 900;
}

.HistoryTable {
  min-height: 100px;
}

.NullStateCard {
  margin: 40px auto auto;
}

.HelpText {
  color: var(--text-color);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.13px;
  line-height: 16px;
}

.HelpTooltipAnchor {
  cursor: help;
}

.hidden {
  visibility: hidden;
}
