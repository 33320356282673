.section:not(:last-child) {
  margin-right: 15px;
  margin-bottom: 15px;
  font-weight: 500;
}

.cobranding-content {
  display: flex;
  flex-direction: column;
}

.cobranding-info {
  display: flex;
  flex-direction: row;
}

.cobranding-company-address,
.cobranding-company-contact {
  flex: 1;
  line-height: 1rem;
}

.cobranding-company-contact {
  text-align: right;
}

.user-photo {
  width: 90px;
  height: 90px;
  /* Use img with clip-path instead of background image to make print friendly */
  clip-path: circle(45px at 50% 50%);
  display: flex;
  justify-content: center;
  overflow: hidden;

  & .user-photo--image {
    height: 90px;
  }
}
.info-section {
  padding-top: 10px;
}
.user-name {
  word-break: break-word;
  font-size: 30px;
  font-weight: 900;
  line-height: 32px;
  margin-right: 25px;
  display: inline-block;
}
.company-logo {
  max-height: 85px;
  background-size: cover;
  background-position: center;
  display: inline-block;
}
.company-name {
  word-break: break-word;
  font-size: 18px;
  line-height: 25px;
}
.contact-info {
  font-size: 14px;
  word-break: break-word;
}
.separator {
  display: inline-block;
  vertical-align: middle;
  font-size: 22px;
  font-weight: 100;
  color: #ccc;
  margin: 0 8px;
}
.phone,
.email {
  vertical-align: middle;
}

.extended-info {
  font-size: 14px;
  word-break: break-word;
}

.expand-button {
  cursor: pointer;
}

.expand-dot {
  height: 4px;
  width: 4px;
  background-color: #0a62ff;
  border-radius: 4px;
  display: inline-block;
  margin-right: 4px;
}

@media (max-width: 768px) {
  .user-name {
    font-size: 20px;
    line-height: 25px;
  }
  .company-name {
    font-size: 16px;
  }
  .company-logo {
    max-height: 50px;
  }
  .contact-info {
    font-size: 12px;
  }
  .phone,
  .email {
    display: block;
  }
  .email,
  .user-name,
  .company-name,
  .contact-info {
    white-space: normal;
  }
  .separator {
    display: none;
  }
}

@media print {
  .extended-info {
    display: block !important;
  }
  .expand-button {
    display: none;
  }
}
