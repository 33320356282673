@import '../../../css-variables/variables.css';

.CommentIcon {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.CommentIconIndicator {
  background-color: var(--swatch-error);
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  position: absolute;
  left: 50%;
  transform: traslateX(150%);
}
