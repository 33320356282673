@import '../styles/common/variables.css-module.css';

.component {
  width: 50%;
  box-sizing: border-box;
  &:only-child {
    width: 100%;
  }
}

.message-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--hc-orange);
  font-size: 13px;
  height: 30px;
  font-weight: 600;
}

.map-container {
  composes: map-container from './PropertyExplorerPage.css-module.css';
}

.NullStateCard {
  margin: auto;
}

.Loading {
  height: 100%;
  width: 100%;
}

/* Stack title and message-bar at narrow screen widths */
@media (max-width: 768px) {
  .title {
    margin: 0 !important;
  }
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
  .message-bar {
    margin-bottom: 10px;
  }
  .map-container {
    composes: map-container from './PropertyExplorerPage.css-module.css';
  }
}

@media print {
  .component {
    page-break-after: always;
    width: 100%;
  }
}
