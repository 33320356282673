.Dialog.dialog {
  max-width: 1200px;
}

.Title {
  color: #4a4a4a;
}

.Button.TextButton {
  display: block;
  margin: auto;
}
