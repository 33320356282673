@import 'src/legacy/styles/common/colors';
@import 'src/legacy/styles/common/variables';

.share-dropdown {
  left: 0;
  position: fixed;
  top: 56px;
  z-index: 2;
  margin: 0;
  width: 100%;
  padding: 0 8px 8px 8px;
  justify-content: space-between;

  div {
    width: 100%;
  }
}

.share-report-buttons--header {
  min-width: 180px;

  .share-report-buttons--header-button {
    height: auto;
    line-height: initial;
    min-width: 60px;
    padding: 5px 0px;
    text-transform: uppercase;
    width: 33%;
  }

  .share-report-buttons--header-icon svg {
    color: $hc-blue;
    &.disabled {
      color: $off-white;
    }
  }

  .share-report-buttons--header-icon.disabled svg {
    color: $off-white !important;
  }

  .share-report-buttons--download-button {
    display: inline-block;
    margin-left: 15px;
    vertical-align: middle;
    position: relative;

    button {
      border: none !important;
    }

    svg {
      /* IE11 needs a defined height */
      height: 24px;
    }

    #download-report-button[disabled] {
      svg {
        g {
          fill: #d8d8d8 !important;
        }
      }
    }
  }
}
