@import '../../css-variables/variables.css';

.PropertyDetailsHeader {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.LineOne {
  display: flex;
}

.StreetAddress {
  font-weight: 900;
  font-size: 30px;
  margin: 0 15px 0 0;
  line-height: 100%;
}

.CityStateZip {
  white-space: nowrap;
}

.Children {
  flex: 1;
}

.LineTwo {
  display: flex;
  line-height: 20px;
  margin-top: 5px;
}

.Details {
  > div {
    display: inline-block;
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}

.Divider {
  padding: 0 15px;
}

.PublicRemarks {
  white-space: nowrap;
}

.PublicRemarksButton {
  color: var(--hc-blue);
  font-weight: bold;
}

.ContentRight {
  margin-left: 25px;
}
