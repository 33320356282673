@import '../styles/common/variables.css-module.css';

.Heading {
  font-size: 16px;
  font-weight: 900;
}

.HeadingText {
  display: inline-block;
  padding: 0px 1px 4px;
}

.Paragraph {
  padding: 8px 0px 8px 0px;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
}

.NullStateHeading {
  color: var(--text-color);
  font-size: 16px;
  padding-right: 10px;
}

.NullStateContainer {
  margin-top: 40px;
}

.Date {
  color: var(--text-light-color);
  font-size: 12px;
  font-weight: 300;
}

.InnerWrapper {
  text-align: left;
  max-width: initial;
  padding-top: 10px;
}

.ListingRemarksNullState {
  margin: auto;
}

.ListingRemarksDate {
  color: var(--text-light-color);
  font-size: 12px;
  font-weight: 300;
}
