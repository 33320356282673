.FilterRange {
  display: flex;
  align-items: center;
}

.InputCell {
  flex: 1 1 120px;
}

.Hyphen {
  flex: 0 0 30px;
  text-align: center;
  font-size: 8px;
  font-weight: 500;
  color: #4a4a4a;
}

.FilterInput {
  &.input {
    padding: 14px 0 5px 0;
  }
  .inputElement {
    height: 32px;
  }
}

.RelativeValue {
  color: #8f8f8f;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
}

.Error {
  padding: 10px 0 0;
}
