.generate-link {
  text-align: center;

  .generate-link--lock-icon {
    color: $grey-dark !important;
    width: 60px !important;
    height: 60px !important;
  }

  .generate-link--button {
    margin-top: 10px;
  }
}
