@import '../styles/common/variables.css-module.css';

.DataSelector {
  opacity: 1;
  transition: opacity 0.5s;
  display: block;
}

.ControlRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chartLegend {
  position: absolute;
  width: 270px;
  right: 30px;
  bottom: 25px;
  color: var(--text-color);
}

.HiddenDataSelector {
  opacity: 0;
}

.ChartSize {
  height: 420px;
  width: 100%;
}

.NullStateCard {
  margin: 25px auto;
}

@media (max-width: 1742px) {
  .DataSelector {
    width: 100%;
  }
}

/* Specific width that causes the mobile state for HorizontalSelector */
@media (max-width: 619px) {
  .chartLegend {
    padding: 20px 0 0 0;
    width: 280px;
    box-sizing: border-box;
    display: inline-block;
    color: var(--text-color);
  }
  .DataSelector {
    width: calc(100% - 280px);
    display: inline-block;
  }
}

@media (max-width: 450px) {
  .chartLegend {
    display: block;
    width: 100%;
    color: var(--text-color);
  }
  .DataSelector {
    display: block;
    width: 100%;
  }
}
