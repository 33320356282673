@import '../../styles/common/variables.css-module.css';

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  background-color: transparent;
  transition: background-color 0.5s;
  z-index: 3;
  background-color: var(--hc-overlay-light);
}
