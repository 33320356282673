@import 'src/legacy/styles/common/colors';
@import 'src/legacy/styles/common/variables';

@media (min-width: 768px) {
  .chart-flex-wrapper {
    &:nth-child(odd) {
      padding-right: 10px;
    }

    &:nth-child(even) {
      padding-left: 10px;
    }
  }
}

.property-explorer-page {
  .map-controls-sidepanel {
    width: 420px;
    padding: 26px 10px 0 20px;
    .map-controls-sidepanel--header {
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    .heading-section .heading-sub-section-title {
      font-size: 18px;
      font-weight: 700;
    }
    .chart-flex-wrapper {
      flex: none !important;
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
    .dropdown--content {
      left: 0;
      right: 0;
    }
  }
}

/* Legacy styles */
/* ****************************************************  */
/* TODO remove or move to `property-explorer.css-modules.css` */

.heading-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .heading-section-title {
    font-weight: normal;
    font-size: $font-size-16;
    line-height: 18px;
    color: #000000;
  }
  .heading-sub-section-title {
    display: flex;
    align-items: center;
    font-size: $font-size-24;
    line-height: 28px;
    color: #4a4a4a;
    margin: 10px 0;
  }
  .heading-section-icon {
    width: $px-rem-24;
    height: $px-rem-24;
  }
}

.property-explorer-section {
  padding: {
    left: 12px;
    right: 12px;
  }
}

.chart-heading {
  font-size: $font-size-16;
  color: rgba(0, 0, 0, 0.87);
}
.chart-data-unavailable {
  width: 400px;
  height: 150px;
  .text {
    margin: 0 auto;
    line-height: 150px;
    font-size: $font-size-13;
    color: rgba(0, 0, 0, 0.26);
  }
}

.nearby-properties-section {
  .heading-section {
    .nearby-properties-card-header {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .nearby_property_rectangle {
    width: 20px;
    height: 8px;
    background-color: rgba(253, 183, 18, 0.2);
    border: solid 1px #fdb813;
  }
  .subject-rectangle {
    width: 20px;
    height: 8px;
    background-color: rgb(255, 180, 47);
    border: solid 1px rgb(255, 180, 47);
  }
}

@media print {
  .hc-action {
    display: none;
  }
  .property-explorer-print-pagebreak {
    padding-top: 80px;
    page-break-before: always;
  }
  .property-explorer-section {
    .flex-50 {
      max-width: 50% !important;
    }
    .chart-parent-position .SVGInline,
    .chart-parent-position svg {
      display: block !important;
      page-break-inside: avoid;
    }
  }
  .listings-wrapper {
    /* Reduced cell padding */
    .fixedDataTableLayout_main .public_fixedDataTableCell_cellContent,
    .public_fixedDataTable_header
      .fixed-data-table--with-fixed-first-row--cell
      .public_fixedDataTableCell_cellContent {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    /* Ensure no scrollbars */
    .public_Scrollbar_main,
    .public_fixedDataTable_horizontalScrollbar {
      display: none;
    }
  }
}
