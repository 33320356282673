@import '../styles/common/variables.css-module.css';

.Path {
  fill: var(--text-color);
}

.DeleteIcon {
  &:hover {
    .Path {
      fill: '#FD8A5F';
    }
  }
}
