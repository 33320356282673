/* react-toolbox input underline */
.bar {
  display: none;
}
/* react-toolbox input character counter */
.counter {
  display: none;
}
/* react-toolbox input wrapper element */
.input {
  padding: 0;
  > input {
    font-family: 'Avenir', sans-serif;
    border-bottom: none;

    &:focus {
      border-bottom: none;
    }
  }
}
