@import '../styles/common/variables.css-module.css';

.SignUpPreview {
  max-width: 550px;
  margin: 40px auto 20px auto;
  color: var(--text-color);
}

.Title {
  font-size: 1.25rem;
}

.Content {
  display: flex;
}

.ContentCell {
  flex: 0 0 50%;

  &:not(:last-child) {
    padding-right: 15px;
  }

  &:last-child {
    padding-left: 15px;
  }
}

.ContentTitle {
  font-size: 1rem;
}

.List {
  height: 80px;
  padding-inline-start: 20px;

  li {
    padding-bottom: 8px;
  }
}

.Link {
  font-weight: 300;
  font-size: 12px;
  color: var(--hc-blue);
  text-decoration: none;
  font-family: Avenir W01, Avenir, Helvetica, sans-serif;
}

.Disclaimer {
  font-style: italic;
}

.LoadingPlaceholder {
  margin: 40px auto 20px auto;
  max-width: 550px;
  text-align: center;
}

.LoadingSpinner {
  svg {
    width: 50px;
  }
}
