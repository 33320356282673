@import 'src/legacy/styles/common/colors';
@import 'src/legacy/styles/common/variables';

.property-search-container {
  .left-panel {
    .separator {
      padding-top: $padding-10;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
  .property-details {
    margin-top: 10px;
  }
}

/******* Search page ********/

.property-search-container {
  .map-container .map-controls-sidepanel {
    position: fixed;
    top: $top-nav-height;
    right: 0;
    .map-controls-sidepanel--header {
      flex: 1;
    }
    .map-controls-sidepanel--mls-listings {
      flex: 1 0 0;
      overflow: visible;
      padding-top: 10px;
    }
    .map-controls-sidepanel--section:not(:last-child) {
      border-bottom: 1px solid #e8e8e8;
    }
    .map-controls-sidepanel--show-listings-on-map-toggle {
      flex: 1;
      cursor: pointer;
      color: $hc-light-blue;
      text-align: right;
      margin-right: 10px;
    }
    .mls-listing--card-container-outer {
      height: 100%;
      position: relative;
    }
    .mls-listing--card-container {
      overflow: visible;
      width: 100%;
      margin-top: 20px;
      .lazily-rendered-component {
        display: inline-block;
        margin: 0 10px 10px 0;
      }
      .comps-property-preview-card--vertical {
        width: 100%;
      }
    }
    .mls-listing--no-results {
      font-size: 16px;
      color: #4a4a4a;
      margin-top: 20px;
    }
  }
  .map-container-wrapper {
    width: calc(100% - 500px /*width*/ - 50px /*padding*/);
    height: 100%;
    position: relative;

    .map-container {
      width: 100%;
      height: 100%;
      /* Important: since we're placing the fixed map controls outside of the
       * .map-container ("overflow: hidden") on this page, Safari requires this
       * container to have no z-index for the controls to appear */
      z-index: auto;
    }
    .autocomplete {
      border: 1px solid #999;
      width: 100%;
      z-index: 1;

      input {
        width: calc(100% - 70px);
      }
    }
    .property-search-full-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      /* Above map tiles */
      z-index: 600;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .property-search-prompt {
        z-index: 1;
        max-width: 80%;
        width: 600px;
        margin-top: 200px;
        /* Disallow long list overunning viewport boundary */
        .autocomplete--suggestions {
          max-height: calc(100vh - 450px);
          overflow-y: auto;
        }
      }
      .property-search-back-screen {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        opacity: 0.7;
      }
      .property-search--overlay-header {
        font-weight: 900;
        width: 104%;
        color: #4a4a4a;
        font-size: 35px;
        margin-bottom: 15px;
      }
    }
    .property-search-top-overlay {
      .only-on-full-overlay {
        display: none;
      }
      .autocomplete {
        position: absolute;
        top: 20px;
        width: 600px;
        left: calc(50% - 300px);
        box-shadow: $hc-box-shadow;
        /* Above map tiles */
        z-index: 600;
      }
    }
    .leaflet-control-zoom,
    .map-home-button {
      right: 20px;
    }
  }
}

/******* Search result page ********/

.property-search-container.result-mode {
  .map-container-wrapper {
    width: 100%;
    position: absolute;
    .map-container {
      z-index: 1;
    }
    .layer-controls-container {
      position: absolute;
    }
  }
}

/******* Responsive ********/

/* Small desktop: one column of MLS listings */
@media (max-width: 1250px) {
  .property-search-container {
    .mls-listing--card-container .lazily-rendered-component {
      width: calc(100% - 10px) !important;
    }
    .map-controls-sidepanel {
      width: 280px;

      .map-controls-sidepanel--show-listings-on-map-toggle {
        position: static;
      }
    }
    .map-container-wrapper {
      width: calc(100% - 280px /*width*/ - 50px /*padding*/);
      .control-item-description {
        display: none !important;
      }
      .property-search-top-overlay .autocomplete {
        width: 300px;
        left: calc(50% - 150px);
      }
    }
  }
}

/* Large desktop: three columns of MLS listings*/
@media (min-width: 1920px) {
  .property-search-container {
    .mls-listing--card-container .lazily-rendered-component {
      width: calc(33.3% - 10px) !important;
    }
    .map-controls-sidepanel {
      width: 740px;
    }
    .map-container-wrapper {
      width: calc(100% - 740px /*width*/ - 50px /*padding*/);
    }
  }
}
/* Mobile */
@media (max-width: 768px) {
  .property-search-container {
    &.result-mode .layer-controls-container {
      position: absolute;
    }
    .map-container-wrapper {
      width: 100%;
      .property-search-top-overlay .autocomplete {
        position: absolute;
        top: 0;
        left: 0;
        height: 50px;
        border-radius: 0;
        border: none;
        width: 100%;
        box-shadow: none;
        border-bottom: 1px solid #ddd;
        .ol-zoom {
          top: 70px;
          z-index: 1;
        }
        .map-reset {
          top: 160px;
        }
      }
      .property-search-full-overlay .property-search--overlay-header {
        font-size: 4.8vw;
      }
      .property-search-full-overlay .property-search-prompt {
        margin-top: 100px;
      }
      .property-search-back-screen {
        height: calc(100% - 100px);
      }
    }
  }
}

// Hide the address search bar when on a mobile device with a very small height
// (i.e. in landscape mode) to allow more room for viewing the map
//
// Note: we're excluding Android devices, as opening the keyboard on these devices
// causes the window height to change, causing the just-focused search bar to
// become hidden
@media (max-height: 480px) and (max-width: 768px) {
  .property-search-container:not(.result-mode.is-android-device) {
    maps-search,
    maps-search-result {
      top: 0px;
    }
    .autocomplete {
      display: none;
    }
    .map-container {
      .map-controls-legend-wrapper {
        top: 48px;
      }
      .refresh-indicator--mobile {
        top: 86px;
      }
    }
  }
}
