@import '../../css-variables/variables.css';

.FullscreenButton {
  height: 35px;
  width: 35px;
  background-color: var(--text-color);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  padding: 8px;
}
