@import '../../css-variables/variables.css';

.SelectButton {
  height: 40px;
  width: 40px;
  border: 0.75px solid #ffffff;
  border-radius: 100%;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.13);
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  background-color: #fff;
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none;
}

.Icon {
  svg {
    position: absolute;
    height: auto;
    width: 10px;
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    > g {
      > g {
        fill: #0a62ff;
      }
    }
  }
}

.SelectButton {
  &.selected {
    background-color: #000000;
    border-color: #000000;
    .Icon {
      svg {
        width: 14px;
        > g {
          > g {
            stroke: #fff;
            fill: transparent;
          }
        }
      }
    }
  }

  &.disabled {
    background-color: #eaeaea;
    border-color: #eaeaea;
    box-shadow: none;
    cursor: not-allowed;
    .Icon {
      svg {
        > g {
          > g {
            fill: #b3b3b3;
          }
        }
      }
    }
  }
}
