@import 'src/legacy/styles/common/colors';
@import 'src/legacy/styles/common/variables';

.result-view {
  .property-details {
    display: block;
    &.full-layout .property-detail--middle {
      margin: 15px 0;
    }
    .pipe-separator {
      font-size: 20px;
    }
    .flex-50 .address-line-1 {
      padding-right: 20px;
    }
    .address-line-1 {
      margin: 0;
      font-size: 16px;
      line-height: 25px;
      font-weight: 300;
    }
    .address-line-2 {
      margin: 0;
      font-size: 12px;
      font-weight: 100;
      color: $aside-text-color;
    }
    .property-detail--emphasis {
      font-size: 15px;
    }
    .property-detail--emphasis-2 {
      display: inline-block;
      font-size: 16px;
      color: $swatch-secondary;
    }
    .property-detail-hc-value {
      margin-bottom: 10px;
    }
  }
  .view-report-button {
    margin: 15px 0;
  }
  .pipe-separator:after {
    content: '|';
  }
  .property-photo {
    flex: 1 1 100%;
    height: 300px;
  }
  .result-view__recent-reports {
    margin-top: 30px;

    h3 {
      font-size: 15px;
      font-weight: 300;
      margin: 0 0 10px 0;
      cursor: pointer;
    }
    .report-row {
      margin: 0;
    }
    .material-icons,
    svg {
      vertical-align: middle;
    }
  }
  .error-info {
    padding: 10px 0;
  }
}

/************* Mobile *************/

@media (max-width: 768px) {
  .mobile-bottom-panel {
    .result-view__mobile-photo,
    .photo__frame--placeholder {
      min-height: initial;
      height: 30vw;
      width: 30vw;
      background-size: cover;
      margin-right: 15px;
    }
    .result-view__recent-reports {
      h3 {
        font-size: 15px;
        font-weight: 300;
        margin: 0 0 10px 0;
      }
      .report-row {
        margin: 0;
      }
    }
    .mobile-view-reports-link {
      float: right;
      margin-top: 4px;
    }
    .address-line-1 {
      padding-right: 60px;
    }
    .bottom-bar-toggle {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 3;
      width: 100%;
      padding: 20px;
      text-align: right;
      cursor: pointer;
      box-sizing: border-box;
    }
    .lifecycle-item {
      font-size: 10px;
    }
    .property-photo {
      width: 50%;
      height: 130px;
      margin-right: 15px;
      flex: 1 1 auto;
    }
  }
}

/************* Mobile, retracted *************/

@media (max-width: 768px) {
  .mobile-bottom-panel.mobile-bottom-panel--retracted {
    .result-view__mobile-photo,
    .photo__frame--placeholder {
      height: 55px;
      width: 55px;
      font-size: 2vw;
      transform: translate(0, -5px);
    }
    .property-detail--top-line {
      margin-bottom: 100px;
    }
    .address-line-1 {
      white-space: nowrap;
      width: calc(100vw - 80px);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
