@import '../../styles/common/variables.css-module.css';

.HcsDropdown {
  .ValueWrapper:hover:after,
  .inputElement.filled__1T3UM:hover:not([disabled]),
  .inputElement:focus:not([disabled]):not([readonly]):hover:not([disabled]) {
    border-color: var(--hc-blue);
  }

  .values > .selected {
    color: var(--hc-blue);
  }
}
