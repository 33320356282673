@import '../styles/common/variables.css-module.css';

.NoCompsTableContainer {
  width: 100%;
  height: 100%;
  position: relative;

  .NullStateCard {
    border: none;
    margin: 85px auto;
    max-width: 90%;
    width: 90%;
    border: none;
    margin: auto;
    max-width: 90%;
    position: absolute;
    z-index: 2;
    background: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .InnerWrapper,
    .IconWrapper {
      max-width: 100%;
      width: 100%;
    }

    .Title {
      font-size: 20px;
      font-weight: 900;
    }

    .Description {
      font-size: 14px;
      font-weight: 300px;
      color: var(--text-color);
    }
  }
}

.Overlay {
  background-color: var(--hc-overlay-light);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: none;

  &.loading {
    display: block;
  }
}

.LoadingIconSimple {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .loading-circle {
    height: 60px;
    width: 60px;
  }
}

/* Theme */
