.NullStateCard {
  margin-top: 15px;
}

.InnerWrapper {
  padding: 15px 10px;
}

.Title {
  margin: 0;
  font-size: 0.875rem;
}
