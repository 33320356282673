@import '../styles/common/variables.css-module.css';

.ZipSelector {
  width: 100%;
  height: auto;
  background: #f4f4f4;
  padding: 16px;
  border-radius: 6px;
}

.ZipTitle {
  margin: 10px 0 0;
  color: var(--text-color);
  font-size: 16px;
}

.dropdown {
  margin-bottom: 14px;
}
