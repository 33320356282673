@import '../../styles/common/variables.css-module.css';

.check {
  border: 1px solid #e4e4e4;
  background-color: #fff;
  box-sizing: border-box;
  height: 22px;
  width: 22px;
  border-radius: 3px;
}

.checked {
  background-color: var(--hc-blue-dark);
  background-size: 45%;
  border: none;
}

.disabled {
  .check {
    background-color: #eaeaea;
    border: none;
  }
}

.input {
  display: none;
}
