.SubjectPhotoCard {
  display: flex;
  width: 210px;
  height: 200px;
  border-radius: 10px;
  box-shadow: 0 2px 15px 0 rgb(0 0 0 / 40%);
  overflow: hidden;
  border: 4px solid #ffffff;
  background: #ffffff;
  position: relative;
}

.Photos {
  flex: 1;
}

.StreetViewLauncherButton {
  z-index: 2;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
