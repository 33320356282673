@import '../styles/common/variables.css-module.css';

.Keyword {
  background: var(--keyword-color-light);
}

.Date {
  color: var(--text-light-color);
  font-weight: 300;
  margin-top: 7px;
}
