@import '../styles/common/variables.css-module.css';
.EffectiveDateWarning {
  position: fixed;
  top: 4px;
  right: 40px;
}

.PriceEffectiveDate {
  margin-top: 6px;
}

.PhotosPage {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.Controls {
  padding: 15px 5px;
  max-width: 1460px;
  box-sizing: border-box;
  margin: auto;
  width: 100%;
  justify-content: flex-end;
  display: flex;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}

.Photos {
  flex: 1;
  overflow-y: auto;
  position: relative;
}

.TransactionSeparator {
  max-width: 1460px;
  padding: 15px 10px;
  margin: auto;
  box-sizing: border-box;
  position: sticky;
  z-index: 3;
  background-color: #ffffff;
  top: 0;
}

.enter.PhotosPage,
.enterActive.PhotosPage {
  animation-name: fadeIn;
  animation-duration: 500ms;
}

.leave.PhotosPage,
.leaveActive.PhotosPage {
  animation-name: fadeOut;
  animation-duration: 500ms;
}

.PhotoGrid {
  max-width: 1460px;
}

.Aside {
  align-self: flex-end;

  > * {
    position: relative;
    top: 5px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
