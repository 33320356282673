.LocationUnknownWarning.NullStateCard {
  position: absolute;
  z-index: 10;
  width: 370px;
  max-width: calc(100% - 30px);

  .InnerWrapper {
    max-width: 100%;
  }

  .Title {
    margin: 0;
  }

  .Description {
    margin: 15px 0 0 0;
  }

  &.noMessage {
    width: 280px;
    .InnerWrapper {
      padding: 20px 10px 18px 10px;
    }
    .Description {
      display: none;
    }
  }

  &.center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.topCenter {
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.bottomLeft {
    bottom: 15px;
    left: 15px;
  }

  &.bottomRight {
    bottom: 15px;
    right: 15px;
  }
}
