@import '../styles/common/variables.css-module.css';

.StreetViewIcon {
  position: absolute;
  top: 124px;
  right: 10px;
  z-index: 2;
}

.CompTags {
  position: absolute;
  top: 124px;
  left: 10px;
  z-index: 2;
}

.FullscreenThumbnail {
  cursor: zoom-in;
}

.clickable {
  cursor: pointer;
}

.NoPhotos {
  cursor: not-allowed;
}

.largeValue {
  .Price {
    font-size: 24px;
  }
}
.ThumbnailWrapper {
  border-radius: 4px 4px 0 0;
  overflow: hidden;
}
.PropertyCard {
  /** allow for tooltip to overflow */
  position: initial;
  overflow: visible;
  height: 100%;

  .PriceRow {
    width: 100%;
  }
}

.smallThumbnail {
  .ThumbnailWrapper {
    flex: 0 0 130px !important;
  }
}

.PriceRow > div {
  width: 100%;
}

.active {
  .PriceLabel {
    color: var(--color-listed);
  }
}
.sold {
  .PriceLabel {
    color: var(--color-sold);
  }
}
.pending {
  .PriceLabel {
    color: var(--color-pending);
  }
}

.Sup {
  font-size: 9px;
}

.PropertyCardVr {
  background-color: #ffffff;
  .Icon {
    position: absolute !important;
    top: 50% !important;
    bottom: 50% !important;
    left: 50% !important;
    right: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 11px !important;
    width: 11px !important;
    svg {
      width: 11px !important;
      position: static !important;
      transform: none !important;
    }
  }
  .Similarity {
    line-height: auto;
  }
  .SimilarityScoreTableCell {
    display: inline-block;
    position: relative;
    vertical-align: text-bottom;
    top: 2px;
    > div {
      font-size: 12px !important;
    }
  }

  .OtherDetails {
    width: 100%;
  }
}

.PropertyCardVr {
  .selected {
    .Icon {
      svg {
        path {
          stroke: #fff !important;
        }
      }
    }
  }
}

.PropertyDetailsWrapper {
  box-sizing: border-box;
}

.selectCellNoPrice {
  position: relative;

  .SelectCell {
    position: absolute;
    right: 15px;
    bottom: 35px;
    width: 40px;
    height: 40px;
  }
}

.PinWrapper {
  bottom: auto;
  top: 10px;
}

.hasKeywordMatch {
  .Address,
  .CityStateZip {
    background-color: var(--keyword-color-light);
    /* border: solid var(--keyword-color-light); */
    color: var(--text-color);
    width: 100%;
  }

  .Address {
    /* border-width: 1px 0 0 0; */
    padding: 10px 12px 0 12px;
    margin: -12px 0 0 -12px;
  }

  .CityStateZip {
    /* border-width: 0 0 1px 0; */
    padding: 0 12px 10px 12px;
    margin: 0 0 0 -12px;
  }
}

.BrokerageCredit {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 7px);
  bottom: 15px;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}

.PropertyCard--layoutHorizontal {
  height: 200px !important;

  .PropertyDetailsWrapper {
    position: relative;
    overflow-x: visible;
    overflow-y: visible;
    overflow: visible !important;
  }

  .StreetViewIcon {
    left: -40px;
    right: auto;
    top: auto;
    bottom: 10px;
  }

  .CompTags {
    left: -150px;
    right: auto;
    top: auto;
    bottom: 10px;
  }
}

.Address {
  font-size: 14px;
  color: var(--text-color);
  font-weight: 800;
}

.CityStateZip {
  font-size: 10px;
  color: var(--text-color);
  font-weight: 400;
}

.SingleAttribute {
  font-size: 12px;
  color: var(--text-color);
  font-weight: 800;

  &:not(:last-child):after {
    color: var(--text-color) !important;
  }
}
