@import '../../../css-variables/variables.css';

.Header {
  background-color: #ffffff;
  display: flex;
  flex: 1 1 0%;
  align-items: center;
  padding: 0 7.5px;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.LeftColumn {
  padding: 10px 7.5px;
  flex: 1 1;
}

.RightColumn {
  flex: 0 0 180px;
  align-self: flex-start;
  padding: 10px 7.5px;
}

.Value {
  font-size: 24px;
  font-weight: 900;
  line-height: 33px;
  margin: 0;
}

.Counts {
  color: var(--text-light-color);
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}

.Checkbox {
  margin-top: 5px;
}

/* When less than 465px */
.vertical {
  flex-direction: column;
  .LeftColumn {
    padding-right: 0;
    padding-bottom: 15px;
    align-self: flex-start;
  }
  .Toggle {
    margin-right: 15px;
  }
}
