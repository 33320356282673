.PersonalizationPromptModal.Dialog {
  max-width: 560px;

  .DialogHeader {
    max-width: 100%;

    .DialogHeaderHeading {
      font-size: 18px;
      line-height: 32px;
      font-weight: 400;
    }
  }
}

.CustomActionWrapper {
  text-align: right;
  padding-top: 25px;
}
