* {
  font-family: 'Avenir', sans-serif;
}

.warning {
  color: $warning;
}
.disabled {
  color: $disabled;
}

@font-face {
  font-display: swap;
  font-family: 'Avenir';
  src: url('~legacy/assets/fonts/Avenir/710789a0-1557-48a1-8cec-03d52d663d74.eot?#iefix')
    format('eot');
}
@font-face {
  font-display: swap;
  font-family: 'Avenir';
  src: url('~legacy/assets/fonts/Avenir/710789a0-1557-48a1-8cec-03d52d663d74.eot?#iefix');
  src: url('~legacy/assets/fonts/Avenir/710789a0-1557-48a1-8cec-03d52d663d74.eot?#iefix')
      format('eot'),
    url('~legacy/assets/fonts/Avenir/065a6b14-b2cc-446e-9428-271c570df0d9.woff2')
      format('woff2'),
    url('~legacy/assets/fonts/Avenir/65d75eb0-2601-4da5-a9a4-9ee67a470a59.woff')
      format('woff'),
    url('~legacy/assets/fonts/Avenir/c70e90bc-3c94-41dc-bf14-caa727c76301.ttf')
      format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: 'Avenir';
  src: url('~legacy/assets/fonts/Avenir/e0542193-b2e6-4273-bc45-39d4dfd3c15b.eot?#iefix')
    format('eot');
}
@font-face {
  font-display: swap;
  font-family: 'Avenir';
  src: url('~legacy/assets/fonts/Avenir/e0542193-b2e6-4273-bc45-39d4dfd3c15b.eot?#iefix');
  src: url('~legacy/assets/fonts/Avenir/e0542193-b2e6-4273-bc45-39d4dfd3c15b.eot?#iefix')
      format('eot'),
    url('~legacy/assets/fonts/Avenir/17b90ef5-b63f-457b-a981-503bb7afe3c0.woff2')
      format('woff2'),
    url('~legacy/assets/fonts/Avenir/c9aeeabd-dd65-491d-b4be-3e0db9ae47a0.woff')
      format('woff'),
    url('~legacy/assets/fonts/Avenir/25f994de-d13f-4a5d-a82b-bf925a1e054d.ttf')
      format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: 'Avenir';
  src: url('~legacy/assets/fonts/Avenir/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix')
    format('eot');
}
@font-face {
  font-display: swap;
  font-family: 'Avenir';
  src: url('~legacy/assets/fonts/Avenir/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix');
  src: url('~legacy/assets/fonts/Avenir/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix')
      format('eot'),
    url('~legacy/assets/fonts/Avenir/d513e15e-8f35-4129-ad05-481815e52625.woff2')
      format('woff2'),
    url('~legacy/assets/fonts/Avenir/61bd362e-7162-46bd-b67e-28f366c4afbe.woff')
      format('woff'),
    url('~legacy/assets/fonts/Avenir/ccd17c6b-e7ed-4b73-b0d2-76712a4ef46b.ttf')
      format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: 'Avenir';
  src: url('~legacy/assets/fonts/Avenir/f55e4498-ad48-4577-93a0-c614de5cbbb8.eot?#iefix')
    format('eot');
}
@font-face {
  font-display: swap;
  font-family: 'Avenir';
  src: url('~legacy/assets/fonts/Avenir/f55e4498-ad48-4577-93a0-c614de5cbbb8.eot?#iefix');
  src: url('~legacy/assets/fonts/Avenir/f55e4498-ad48-4577-93a0-c614de5cbbb8.eot?#iefix')
      format('eot'),
    url('~legacy/assets/fonts/Avenir/c78eb7af-a1c8-4892-974b-52379646fef4.woff2')
      format('woff2'),
    url('~legacy/assets/fonts/Avenir/75b36c58-2a02-4057-a537-09af0832ae46.woff')
      format('woff'),
    url('~legacy/assets/fonts/Avenir/b5c44a82-eeb8-41de-9c3f-a8db50b24b8a.ttf')
      format('truetype');
  font-weight: 900;
  font-style: normal;
}
