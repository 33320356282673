@import '../styles/common/variables.css-module.css';

.UserDefinedLocationMarkerPopup {
  background-color: #fff;
  width: 150px;
  box-shadow: var(--hc-box-shadow);
  border-radius: 3px;
  text-align: center;
  position: relative;
  top: -5px;
  left: 2px;

  .Button {
    padding: 5px;
    margin-bottom: 10px;
  }
}

.Title {
  font-weight: 900;
  font-size: 16px;
  color: var(--text-color);
  margin: 0;
  padding-top: 15px;
}

.Spacer {
  height: 15px;
}

.Caret {
  position: absolute;
  left: calc(50% - 10px);
  font-size: 16px;
  width: 10px;
  transform: scaleX(1.5);
  border: none;
  line-height: 8px;
  color: white;
  top: 100%;
  text-shadow: 0px 2px 0px #ffffff;
  &::after {
    content: '▼';
  }
}
