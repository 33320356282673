.OrgReportsForAddress {
  padding: 25px 0;
}

.TitleRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.NoReportsMessage {
  text-align: center;
  padding: 5px 15px 30px 15px;
}

.clickable {
  cursor: pointer;
}

.Loading.simple-loader {
  margin: auto;
  display: block;
  width: 150px;
}
