.Heading {
  justify-content: space-between;
}

.BelowDetails {
  display: flex;
  padding: 20px 0;
  justify-content: space-between;
}

.ConditionSection {
  flex: 0 1 576px;
  padding-right: 30px;
}

.ConditionHeader {
  font-size: 16px;
  font-weight: 900;
}

.AdjustmentSection {
}

.EffectiveDateWarning {
  line-height: 13px;
  position: relative;
  top: -3px;
}

@media (max-width: 835px) {
  .BelowDetails {
    display: block;
  }

  .ConditionSection {
    display: block;
    margin-bottom: 35px;
    padding-right: 0;
  }
}

@media (max-width: 640px) {
  .Heading {
    display: block !important;
  }
  .Toggle {
    margin-top: 15px !important;
    margin-left: 0 !important;
  }
}

@media (max-width: 425px) {
  .ConditionSection {
    display: none;
  }
}
