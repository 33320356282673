@import '../styles/common/variables.css-module.css';

.InternetExplorerWarning {
  position: fixed;
  top: 64px;
  z-index: 200;
  left: 0;
  right: 0;
}
.Spacer {
  width: 100%;
  height: 64px;
  margin: 0;
  padding: 0;

  &.ie {
    height: 114px;
  }
}

.ArchivedReportsIcon {
  > svg {
    width: 16px !important;
  }
}

.MenuItem {
  cursor: pointer;
}

.LoginButton {
  float: right;
}

.Search {
  float: right;
  margin-right: 25px;

  > div {
    > div {
      /* AddressAutoComplete .component */
      width: 315px;
    }
  }
}

.EffectiveDate {
  margin-top: 8px;
}

.Search.searchFocused {
  > div {
    > div {
      /* AddressAutoComplete .component */
      width: 480px;
    }
  }
}

@media (max-width: 1040px) {
  .searchFocused {
    > div {
      > div {
        /* AddressAutoComplete .component */
        width: 315px;
      }
    }
  }
}

@media (max-width: 895px) {
  .Search {
    > div {
      > div {
        /* AddressAutoComplete .component */
        width: 100%;
        left: 0;
      }
    }
  }
}

.WarningsContainer {
  margin-bottom: -30px;
}
