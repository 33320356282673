.login-container {
  margin-top: 50px;

  &.login-container--inside-modal {
    margin-top: 0;

    & h2 {
      display: none;
    }
  }
}

.LoginForm {
  max-width: 550px !important;
  & .Link {
    font-weight: 300;
    font-size: 12px;
  }

  & .Row {
    margin-top: 0;
  }
}

@media (max-width: 450px) {
  .LoginForm {
    max-width: 350px;
    padding: 0 25px;
  }
}
