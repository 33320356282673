.PopoverContentPosition {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  &.top {
    animation: fadeInUp 0.3s;
  }

  &.bottom {
    animation: fadeInDown 0.3s;
  }
}
.PopoverContent {
  background-color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 100;
  box-sizing: border-box;
  line-height: 1.2rem;
  text-align: left;
  box-shadow: -2px 2px 10px #cccccc;
  position: relative;
  z-index: 2;
}

.PopoverCaret {
  position: absolute;
  right: 0;
  left: 0;
  height: 14px;
  z-index: 1;
}

.PopoverCaret > div {
  position: absolute;
  font-size: 14px;
  color: #ffffff;
  height: 14px;
}

.top {
  .PopoverCaret {
    bottom: -10px;
    > div {
      text-shadow: 0 -2px 0 #ffffff, 0 2px 4px #cccccc;
      &:after {
        content: '▼';
      }
    }
  }
}

.bottom {
  .PopoverCaret {
    top: -13px;
    > div {
      text-shadow: 0 2px 0 #ffffff, 0 -2px 4px #cccccc;
      &:after {
        content: '▲';
      }
    }
  }
}

.Overlay {
  position: fixed;
  z-index: 9998;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  left: 0;
  top: 0;
}

.RefWrap {
  display: inline-block;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-15px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
