@import '../styles/common/variables.css-module.css';

.RefreshCompsHeaderSection {
  padding: 0px 15px;
  border-left: var(--hc-border);
  display: flex;
  align-items: center;
}

.CompsAsOfSection {
  color: var(--text-light-color);
  font-size: 12px;
  padding-left: 10px;
}

.CheckboxRow {
  padding: 15px 0;
  border-bottom: var(--hc-border);

  &:last-child {
    border-bottom: none;
  }
}

.Button {
  margin-right: 15px;
}
