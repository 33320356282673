@import '../../styles/common/variables.css-module.css';

.FileFieldContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;

  & > div {
    margin-right: 40px;
  }
}

.FileFieldAvatarImg {
  // match company image width for alignment
  width: 120px;
  min-width: 120px;
  text-align: center;
}

.FileFieldCompanyImg {
  text-align: center;
}

.Label {
  font-family: Avenir, Helvetica, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.1875rem;
  color: #4a4a4a;
  margin-bottom: 10px;
}

.FileFieldDeleteButton {
  position: relative;
  top: 20px;
  font-size: 14px;
  color: #0a62ff;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  display: block;
  text-align: center;
}

.FileName {
  font-size: 12px;
  margin-top: 10px;
}

.hc-field-file {
  position: relative;
  margin-top: 20px;

  .small-label {
    top: -30px;
  }

  .hc-file-field--button-wrapper {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  .hc-field-file--input {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
  }

  .hc-field-file--button {
    display: inline-block;
    cursor: pointer;
  }

  .hc-field-file--status {
    display: inline-block;
  }

  .hc-field-file--thumbnail-container {
    display: inline-block;
    position: relative;
    margin-right: 15px;
    min-height: 25px;
    min-width: 36px;
    vertical-align: top;
  }

  .hc-field-file--thumbnail-img {
    height: 60px;
  }
}

.upload-error {
  color: var(--negative-change);
  font-size: 12px;
  margin-top: 10px;
}
