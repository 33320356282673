@import '../../../lib/css-variables/variables.css';

.SectionHeader {
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 28px;
  color: var(--text-color);
  margin: 10px 0;
  font-weight: 900;
}
