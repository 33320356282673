@import '../../../../css-variables/variables.css';

.CompFilterButton {
  padding: 0 5px;
}

.OverflowFilter {
  margin: 10px;
  padding: 8px;
  border: var(--hc-border);
  border-radius: 6px;
}

.hidden {
  visibility: hidden;
}
