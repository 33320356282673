@import '../../css-variables/variables.css';

.TextButton {
  color: var(--hc-blue);
  font-weight: bold;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  padding: 15px 16px;
  font-size: 14px;
  display: inline-block;
  border: 0;
  font-family: 'Avenir', sans-serif;
  background: transparent;
  &:hover {
    color: #02ccee;
  }
  &:focus {
    outline: 0;
  }
}

.TextButton.secondary {
  color: var(--hc-blue-dark);
  &:hover {
    color: #4b607b;
  }
}

.TextButton.disabled {
  cursor: not-allowed;
  color: var(--swatch-disabled);
}
