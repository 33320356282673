.PropertyCompareComps {
  display: flex;
}

.SubjectCell {
  min-width: 330px;
  background-color: #f7f7f7;
}

.CompsCell {
  overflow: auto;

  > div {
    flex: 1;
    display: flex;
  }
}

@media (max-width: 640px) {
  .CompsCell {
    overflow: visible;
  }
}
