@import '../styles/common/variables.css-module.css';

:root {
  --row-height: 45px;
  --row-padding: 4px 0 3px 0;
}
.IconButton {
  > span {
    padding: 5px 20px;
  }

  * {
    cursor: pointer !important;
  }
}

.Header {
  flex: 0 0 40px;
  font-size: 24px;
  font-weight: 900;
  margin: 10px 0 0;
}

.Subheader {
  font-size: 12px;
  font-weight: 300;
  margin: 0 0 15px 0;
}

.DraggableContainer {
  flex: 1 0;
  overflow-y: scroll;
  overflow-x: hidden;
  width: calc(100% + 60px);
  margin-left: -30px;
  user-select: none;
  position: relative;
}

.Buttons {
  padding-top: 10px;
  text-align: right;
}

.OrderRow {
  display: flex;
  padding: var(--row-padding);
  align-items: center;
  height: var(--row-height);
  box-sizing: border-box;
}

.OrderRowActive {
  z-index: 2;
}

.OrderRowInactive {
  z-index: 1;
}

.OrderRowNumbers {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: var(--row-height);
  align-items: flex-start;

  .Column {
    visibility: hidden;
  }
}

.OrderNumber {
  flex: 0 0 75px;
  padding-right: 40px;
}

.OrderNumberLabels {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.OrderNumberLabel {
  font-size: 14px;
  font-weight: 900;
  text-align: right;
  height: var(--row-height);
  line-height: var(--row-height);
  transition: color 0.5s;

  &.inactive {
    color: #c1c1c1;
  }
}

.Spacer {
  flex: 0 1 70px;
}

.Column {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  height: 38px;
  padding-bottom: 2px;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

.CheckboxSmall {
  position: relative;
  left: 10px;
  top: 4px;
}

.ColumnToggle {
  flex: 0 0 35px;
}

.ColumnLabel {
  padding-left: 5px;
  flex: 1;
  > span {
    position: relative;
    top: 2px;
    font-size: 14px;
    font-weight: 500;
  }
}

.CancelButton {
  margin-right: 30px;
}

.columnDragging {
  box-shadow: var(--hc-box-shadow-soft);
}

.dragging {
  .OrderRow {
    &:hover {
      background-color: rgba(216, 216, 216, 0.4);

      .OrderNumber {
        color: #c1c1c1;
      }
    }
  }
}

.disabled {
  .Column {
    box-shadow: none;
  }
}

@keyframes fadeInDown {
  from {
    transform: translate3d(0, -100%, 0);
    opacity: 0;
    height: 0px;
    padding: 0px;
  }
  75% {
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    height: var(--row-height);
    padding: var(--row-padding);
  }
}

@keyframes fadeOutUp {
  from {
    transform: translate3d(0, 0, 0);
    height: var(--row-height);
    padding: var(--row-padding);
  }
  to {
    transform: translate3d(0, -400%, 0);
    height: 0px;
    padding: 0px;
  }
}

@keyframes fadeInUp {
  from {
    height: 0px;
    opacity: 0;
    padding: 0px;
    transform: translate3d(0, 400%, 0);
  }
  25% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    height: var(--row-height);
    padding: var(--row-padding);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutDown {
  from {
    height: var(--row-height);
    padding: var(--row-padding);
    transform: translate3d(0, 0, 0);
  }
  to {
    height: 0px;
    padding: 0px;
    transform: translate3d(0, 400%, 0);
  }
}

.enter.OrderRowInactive,
.enterActive.OrderRowInactive {
  animation-name: fadeInDown;
  animation-duration: 300ms;
  animation-timing-function: ease-in;
}

.leave.OrderRowInactive,
.leaveActive.OrderRowInactive {
  opacity: 0;
  transition: opacity 300ms;
  animation-name: fadeOutUp;
  animation-duration: 300ms;
  animation-timing-function: ease-in;
}

.enter.OrderRowActive,
.enterActive.OrderRowActive {
  z-index: 3;
  position: relative;
  animation-name: fadeInUp;
  animation-duration: 300ms;
  animation-timing-function: ease-in;
}

.leave.OrderRowActive,
.leaveActive.OrderRowActive {
  z-index: 3;
  position: relative;
  opacity: 0;
  transition: opacity 300ms;
  animation-name: fadeOutDown;
  animation-timing-function: ease-in;
  animation-duration: 300ms;
}

@media (max-width: 480px) {
  .Spacer {
    display: none;
  }
  .OrderNumber {
    flex: 0 0 50px;
    padding-right: 15px;
  }
  .Column {
    margin-right: 25px;
  }
}
