@import '../styles/common/variables.css-module.css';

.Row {
  display: flex;
  align-items: center;
  border-bottom: var(--hc-border);
  box-sizing: border-box;
  min-height: 38px;
  padding: 5px 0;

  &:last-child {
    border-bottom: none;
  }

  > div {
    &:only-child {
      width: 100%;
    }
  }
}

.Label {
  flex: 0 0 150px;
  font-size: 12px;
  font-weight: 400;
  color: #707070;
}

.Value {
  color: var(--text-color);
  font-size: 12px;
  font-weight: 800;
  &:first-child {
    padding: 0 10px;
  }
}

.expandable {
  height: auto;
  align-items: flex-start;
  padding: 5px 0;
}

.TempBorder {
  border-bottom-width: 3px;
}
