.SelfServicePackageSelector {
  max-width: 440px;
}

.Tile {
  width: 100% !important;
  max-width: 440px;
}

.MultiIcon {
  position: absolute;
  &.one {
    margin-left: 10px;
    z-index: 1;
  }
  &.two {
    margin-left: 20px;
    z-index: 2;
  }
  &.three {
    margin-left: 30px;
    z-index: 3;
  }
  &.four {
    margin-left: 40px;
    z-index: 4;
  }
  &.five {
    margin-left: 50px;
    z-index: 5;
  }
  &.six {
    margin-left: 60px;
    z-index: 6;
  }
  &.seven {
    margin-left: 70px;
    z-index: 7;
  }
  &.eight {
    margin-left: 80px;
    z-index: 8;
  }
  &.nine {
    margin-left: 90px;
    z-index: 9;
  }
}
