@import '../styles/common/variables.css-module.css';

.CreditCard {
  border: var(--hc-border);
  border-radius: var(--hc-border-radius);
  padding: 10px 15px;
  margin: 25px 0;
}

.ErrorMessage {
  color: var(--negative-change);
  margin: auto;
  text-align: center;
  padding: 20px 0;
}

.Submit {
  margin: auto;
  text-align: center;
  display: block !important;
}

.Button {
  margin-right: 15px;
}
