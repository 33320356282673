@import '../styles/common/variables.css-module.css';

.component {
  white-space: nowrap;
  text-align: center;

  & hr {
    margin: 5px 0 20px;
  }
}
.avm-label {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 6px 0;
}
.avm-not-available {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 6px;
  text-align: left;
  color: #999;
}
.avm-value {
  font-size: 48px;
  font-weight: 900;
  line-height: 66px;
  height: 66px;
  text-align: center;
}

.avm-value--selector {
  text-align: center;
  font-size: 30px;
  font-weight: 900;
  line-height: 41px;
  padding-bottom: 5px;
}

.avm-subtext {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  &:not(.nowrap) {
    white-space: normal;
  }
}
.avm-subtext-heading {
  font-weight: 900;
  margin: 20px 0 15px 0;
}
.see-more-link {
  font-size: 12px;
}
.see-more-link {
  white-space: nowrap;
}
.see-less-link {
  display: block;
  text-align: right;
}
.choose-avm-link {
  line-height: 16px;
  font-size: 12px;
  font-weight: 900;
}
.choose-avm-popover-accent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 15px;
  background-color: #555;
}

.chooseAvmItems {
  padding: 30px 10px 0px 10px;
  width: 605px;
  vertical-align: top;
  white-space: normal;
}

.avm-selection-item {
  box-shadow: var(--hc-box-shadow-soft);
  width: calc(50% - 10px);
  padding: 15px 20px 35px 20px;
  white-space: normal;
  border-radius: 4px;
  position: relative;
  height: 155px;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 55px;
}

.avm-selection-item:nth-child(odd) {
  margin-right: 20px;
}

.avm-selection-item:only-child {
  text-align: center;
  margin-right: auto;
  & .avm-value {
    justify-content: center;
  }
}

.avmSelectorSingle {
  width: 335px;

  .avm-selection-item {
    width: calc(100% - 60px);
    margin-left: auto;
    margin-right: auto;
  }
}

.avmSelectorInValueDisplay {
  .avm-selection-item {
    width: 345px;
    margin-left: auto;
    margin-right: auto;
    .avm-label {
      margin: 15px auto;
      display: block;
    }
  }
}

.avmSelectorItemNull {
  padding: 0;
  box-shadow: none;
}

.avmChartContainer {
  max-width: 380px;
  margin: auto;
}

.avmHcNullState {
  margin-top: 30px;
  white-space: initial;
}

.userEnteredLabel {
  font-size: 16px;
  font-weight: 900;
}

.Disclaimer {
  font-size: 12px;
  color: var(--text-light-color);
  max-width: 380px;
  display: block;
  white-space: normal;
  margin: 8px auto;
  line-height: 16px;
}

.avmWarning {
  position: absolute;
  width: 100%;
  left: 0;
  top: -13px;
}

.input {
  margin: 0;
  padding: 10px 0 0 !important;
}

.inputElement {
  font-size: 18px !important;
  font-weight: 900 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  height: auto !important;
  margin: 0 0 7px 0 !important;
}

.avm-button-wrapper {
  position: absolute;
  top: 90%;
  text-align: center;
  left: 0;
  width: 100%;
}
.avm-alttext {
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
}
.avm-button {
  display: inline-block;
}
.expanded-avm-section {
  margin-bottom: 10px;
}
.to-expand {
  max-height: 0;
  overflow: hidden;
  transition: 0.3s all ease-in;
  &.shown {
    max-height: 80px;
  }
  &.full-height.shown {
    max-height: 240px;
  }
}
.avm-chart {
  width: 100%;
}
.avm-chart-label-avm-container {
  justify-content: center;
  margin-bottom: 5px;
  text-align: center;
}
.avm-chart-label-text-strong {
  /* For the tooltip icon */
  display: flex;
  align-items: center;
  /* ** */
  font-size: 36px;
  line-height: 49px;
  font-weight: 900;
}
.avm-chart-label-text-medium {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}
.avm-chart-label-text {
  font-size: 12px;
  font-weight: 500;
  color: var(--text-light-color);
}
.avm-chart-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 12px;
  border-radius: 6px;
  background-image: linear-gradient(to right, #c0ee83, #11ddb3);
  margin-bottom: 8px;
}
.avm-chart-bar-labels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.avm-chart-bar-label {
  font-weight: 700;
  font-size: 12px;

  &:nth-child(2)::after {
    content: '';
    display: block;
    border-right: 3px solid #7de69b;
    height: 14px;
  }
}
.break {
  flex: 1;
  height: 100%;
  border-right: var(--hc-border);
  transform: translate(-50%);
}
.avm-chart-label-avm-container-min-max {
  display: flex;
  text-align: left;
  justify-content: space-between;
  & > div:last-child {
    text-align: right;
  }
}
.avmSelectorContainer {
  display: inline-block;
  margin-left: 8px;
}

/* Passed into Tooltip component to override styling */
.Tooltip {
  padding-top: 25px;
  width: 360px;
}

@media (max-width: 768px) {
  .component {
    width: 100%;
  }
  .avm-chart {
    width: 100%;
  }

  .avmSelector {
    width: 320px;
  }
  .chooseAvmItems {
    width: 300px;
  }

  .avm-selection-item {
    width: 100%;
  }

  .avm-subtext {
    display: flex;
    flex-direction: column;

    & .see-more-link {
      margin-left: initial;
    }
  }
  .see-less-link {
    text-align: left;
  }
}

.ReadOnlyReportMsg {
  font-size: 16px;
  font-weight: 900;
  line-height: 22px;
  margin-top: 22px;
  position: relative;
  left: -30px;
}

.ReadOnlyIcon {
  width: 28px;
  height: 19px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin-right: 5px;
}
