$hc-black: rgba(0, 0, 0, 0.89);
$white: #ffffff;
$off-white: #abacac;
$black: #000000;
$hc-dark-blue: #00242b;
$default-color: #3c555c;
$hc-blue: #0a62ff; //#21B5D0
$hc-blue-highlight: #0a62ff;
$hc-light-blue: #0a62ff;
$hc-blue-shade: #0a62ff;
$hc-selection-blue: #0a62ff; // rgba(22,183,213,0.3);
$grey-light: #dedede;
$grey-dark: rgba(0, 0, 0, 0.54);
$hc-green: #87d191;
$hc-orange: #feb913;
$hc-red: #bf4830;
$placeholder-color: #d0d0d0;
$hc-overlay: rgba(33, 33, 33, 0.48);
$grey-bg: rgba(240, 242, 243, 1);
$grey-bg-darker: rgba(221, 221, 221, 1);
$grey-bg-darkest: rgba(137, 137, 137, 1);
$grey-bg-light: #f7f7f7;
$hc-overlay-light: rgba(255, 255, 255, 0.75);
$grey-border: rgba(155, 155, 155, 0.5);
