@import 'src/legacy/styles/common/colors';

%center-align {
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo {
  width: 100%;

  &__frame:before,
  & &__item {
    display: inline-block;
    vertical-align: middle;
  }

  &__frame {
    width: 100%;
    overflow: hidden;

    &:before {
      content: '';

      /* Magic Number */
      /* Set this to your desired height(to width) ratio, 4:3 */
      padding-top: 75%;
    }

    &--placeholder {
      &:before {
        padding-top: initial;
      }
      @extend %center-align;
      width: 100%;
      min-height: 200px;
      text-align: center;
      text-transform: uppercase;
      flex-direction: column;
      color: $grey-bg-darkest;
      background-color: $grey-bg-darker;
    }
  }

  &__item {
    width: 100%;
    padding-left: 100%;
    margin: -999em -100%;
  }
}
