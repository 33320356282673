@import '../../css-variables/variables.css';

.Section:not(:last-child) {
  margin-right: 15px;
  margin-bottom: 15px;
}

.ExpandDot {
  height: 4px;
  width: 4px;
  background-color: var(--hc-blue);
  border-radius: 4px;
  display: inline-block;
  margin-right: 4px;
}

.ExpandButton {
  cursor: pointer;
}

.ExtendedInfo {
  font-size: 14px;
  word-break: break-word;
}

.InfoSection {
  padding-top: 10px;
  flex: 1 1 auto;
  font-weight: 500;
}

.ContactInfo {
  font-size: 14px;
  word-break: break-word;
}

.Phone,
.Email {
  vertical-align: middle;
}

.Separator {
  display: inline-block;
  vertical-align: middle;
  font-size: 22px;
  font-weight: 100;
  color: #ccc;
  margin: 0 8px;
}

.CompanyLogo {
  max-height: 85px;
  background-size: cover;
  background-position: center;
  display: inline-block;
}

.UserName {
  word-break: break-word;
  font-size: 30px;
  font-weight: 900;
  line-height: 32px;
  margin-right: 25px;
  display: inline-block;
}

.UserPhoto {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  background-size: cover;
  display: inline-block;
  background-position: center;
}

.UserSection {
  margin-right: 15px;
}

.CompanyName {
  word-break: break-word;
  font-size: 18px;
  line-height: 25px;
}

.PersonalizationHeader {
  display: flex;
  flex-direction: row;
  max-width: 1250px;
  padding: 0 33px;
}
