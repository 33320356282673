@import '../styles/common/variables.css-module.css';

.AddressSearchMap {
  position: absolute;
  z-index: 199;
  margin-top: 200px;
  transition: margin-top 0.3s;
  width: calc(100% - 160px);
  max-width: 620px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;

  &.isMobile {
    margin-top: 120px;

    .Title {
      font-size: 18px;
    }
  }

  &.overlayDismissed {
    margin-top: 25px;
  }
}

.Title {
  font-size: 35px;
  color: var(--text-color);
  font-weight: 900;
  margin-bottom: 15px;
}

.Overlay {
  position: absolute;
  z-index: 198;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 500px) {
  .AddressSearchMap {
    width: 90%;
  }
}
