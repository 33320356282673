@import '../../../css-variables/variables.css';

.Label {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 900;
  line-height: 22px;
  margin: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MainValue {
  text-align: center;
  margin-bottom: 25px;
}

.Value {
  /* For the tooltip icon */
  display: flex;
  align-items: center;
  /* ** */
  color: var(--text-color);
  font-size: 36px;
  line-height: 49px;
  font-weight: 900;
  text-align: center;
  justify-content: space-around;
}

.ValueSecondary {
  font-size: 12px;
  font-weight: 500;
  color: var(--text-light-color);
  text-align: center;
}

.LoadingContainer {
  height: 380px;
  width: 100%;
  position: relative;
}

.AvmSelectorInValue {
  width: 345px;
  margin-left: auto;
  margin-right: auto;
}

.Disclaimer {
  font-size: 12px;
  color: #939aa3;
  max-width: 380px;
  display: block;
  white-space: normal;
  margin: 8px auto;
  line-height: 16px;
}
