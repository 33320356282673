@import '~hc-pattern-lib/src/hc-pattern-lib';
$max-width: rem(400);
$min-width: rem(300);
$row-margin: rem(26);

.HeaderContainer {
  text-align: center;
  max-width: $max-width;
  min-width: $min-width;

  * {
    color: #ffffff;
  }
}

.SelectOrganization {
  min-width: $min-width;
  max-width: $max-width;
  margin-top: rem(20);
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px;
}

.SelectOrganization .CreateOrgRow {
  margin-top: 0;
  display: flex;
  margin-bottom: rem(20);
  align-items: center;
  justify-content: center;
}

.button:hover {
  background-color: initial;
}

.SecondaryLink .button {
  color: $faded-grey;
}
