.MapContainer {
  height: 350px;
  background: #ececec;
  margin: 10px 0;
}

.CompCardsHorizontal {
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 10px;
}

.PropertyCard {
  flex: 0 0 240px;

  &:not(:first-of-type) {
    margin-left: 15px;
  }
}

.ButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 15px 0 25px 0;
}
