.CompFarmListTable {
  tr {
    td:first-child,
    th:first-child {
      padding: 0 5px 0 20px;
    }
  }
}

.compact {
  table {
    th,
    td {
      font-size: 11px;
    }
  }
}
