@import '../styles/common/variables.css-module.css';

.chartRow {
  width: 100%;
  margin-top: 15px;
  position: relative;
  color: var(--text-color);
}

.chartCell {
  width: 50%;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin: 15px auto auto auto;
  vertical-align: top;

  &:nth-child(odd) {
    padding-right: 10px;
  }
  &:nth-child(even) {
    padding-left: 10px;
  }
}

.ChartTitle {
  font-family: Avenir;
  font-size: 15px;
  font-weight: 900;
  line-height: 22px;
  color: var(--text-color);
}

.ChartTitleText {
  display: inline;
  vertical-align: text-bottom;
}

.headerSection {
  display: inline-block;
}

.infoButton {
  position: relative;
}

.statSection {
  padding: 15px 10% 15px 10%;
}

.statCell {
  width: 33.33%;
  box-sizing: border-box;
  display: inline-block;
  padding: 0 10px 0 10px;
  text-align: center;
}

.statLabel {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}

.statValue {
  font-size: 14px;
  font-weight: 900;
  line-height: 19px;
  text-align: center;
  text-transform: capitalize;
}

.NullStateCard {
  margin: 30px auto;
  width: 80%;
  min-width: 320px;
}

@media (max-width: 1000px) {
  .chartCell {
    width: 100%;
    display: block;
  }
}

@media print {
  .chartCell {
    width: 50% !important;
    display: inline-block !important;
  }
}
