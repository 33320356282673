@import 'src/legacy/styles/common/colors';
@import 'src/legacy/styles/common/variables';

.sign-up-page {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;

  .sign-up--container {
    padding: 15px;
    box-sizing: border-box;
  }
}

.sign-up-page--header {
  background: linear-gradient(
    134.85deg,
    #40b0c9 0%,
    #0a62ff 25.58%,
    #006090 100%
  );
  width: 100%;
  padding: 24px 0 24px 0;
  box-sizing: border-box;
  text-align: left;
  color: $white;
}

.sign-up-page--header-content {
  max-width: 400px;
  width: 100%;
  padding: 0 15px 0 15px;
  box-sizing: border-box;
  margin: auto;
}

.sign-up-page--header-title {
  font-size: 28px;
  font-weight: bold;
  line-height: 52px;
}

.sign-up-page--header-subtitle {
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
}

.sign-up--container {
  margin: auto;
  width: 400px !important;
  max-width: 100% !important;

  h1 {
    margin: 15px 0 25px 0 !important;
  }
}

.sign-up--inputs {
  text-align: left;
}

.sign-up--row {
  position: relative;
  vertical-align: top;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.sign-up--message {
  font-size: $font-size-12;
  position: absolute;
  top: 37px;
  right: 8px;
  background: $white;
}

.sign-up--tos-row,
.sign-up--login-row {
  text-align: center;
  color: #a2a2a2;
  a {
    color: $hc-blue;
    font-weight: bold;
    cursor: pointer;
  }
}

.sign-up--login-row {
  font-size: $font-size-14;
}

.sign-up--tos-row {
  margin-top: 55px;
  font-size: $font-size-13;
}

.sign-up--multi-input-row {
  width: 50%;
  box-sizing: border-box;
  &:first-child {
    padding-right: 5px;
  }
  &:last-child {
    padding-left: 5px;
  }
}

.sign-up--button-row {
  text-align: center;
  margin: 0 0 25px 0;
}

.sign-up--hc {
  h3 {
    color: $white;
  }

  a {
    color: $hc-blue;
  }
}

.sign-up--login-icon {
  height: 14px !important;
  width: 14px !important;
  vertical-align: middle;
}
