@import '../styles/common/variables.css-module.css';

.Title {
  font-size: 14px;
}

.Copy {
  font-size: 11px;
  line-height: 15px;
}

.Icon {
  border-radius: 50%;
  background-color: var(--hc-blue);
  height: 19px;
  width: 19px;

  &.inContent {
    position: absolute;
    top: 13px;
    right: 15px;
  }
}
.IconText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 900;
  font-size: 12px;
  color: #ffffff;
}

.Info {
  .Trigger {
    transform: scale(0.65);
    position: relative;
    top: 7px;
    height: 0px;
  }
}

.TooltipContent {
  padding: 0 15px;
  width: 220px;
}
