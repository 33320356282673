.cell {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  width: 50%;

  &:first-child {
    padding-right: 30px;
  }
  &:last-child {
    padding-left: 30px;
  }

  .RentalAvmChart {
    width: 380px;
    margin: auto;
  }
}
.ValueDisplay {
  text-align: center;
}
.avmSelectorInValueDisplay {
  margin-top: 20px;
}
.NoRentalReport {
  margin-top: 30px;
  > div {
    margin: auto;
  }
}

.NoRentalCompsMsg {
  text-align: center !important;
  margin-top: 40px;
}

.MapContainer {
  composes: map-container from './PropertyExplorerPage.css-module.css';
  height: 560px;
}

.CompsMessage {
  margin-top: 15px;
  margin-bottom: 10px;
}
.section {
  min-height: 400px;
  position: relative;
}

.Loading {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width: 850px) {
  .cell {
    display: block;
    width: 100%;

    &:first-child {
      padding-right: 0px;
    }
    &:last-child {
      padding-left: 0px;
    }

    .RentalAvmChart {
      width: 100%;
    }
  }

  .MapContainer {
    composes: map-container from './PropertyExplorerPage.css-module.css';
    height: 350px;
  }

  .RentalCompsSection {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
}
