.SatelliteToggle {
  position: absolute;
  z-index: 5;

  &.top-left {
    top: 15px;
    left: 15px;
  }
  &.top-right {
    top: 15px;
    right: 15px;
  }
  &.bottom-right {
    bottom: 15px;
    right: 15px;
  }
  &.bottom-left {
    bottom: 15px;
    left: 15px;
  }
}
