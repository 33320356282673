.CircleToggle {
  border-radius: 50%;
  background-color: #c1c1c1;
  height: 34px;
  width: 34px;
  cursor: pointer;
  color: white;
  font-size: 0.9rem;
  position: relative;
  display: inline-block;

  & svg {
    height: 24px;
    width: 24px;
  }
}

.Icon {
  height: 24px;
  width: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.active {
  background-color: #f5bb45;
}
