@import '../styles/common/variables.css-module.css';

.DrawControlTooltip {
  background-color: #fff;
  width: 300px;
  box-shadow: var(--hc-box-shadow);
  border-radius: 3px;
  text-align: left;
  padding: 20px 15px;
  box-sizing: border-box;
  display: block;
  font-size: 12px;
  letter-spacing: -0.001em;

  .Button {
    padding: 5px;
    margin-bottom: 10px;
  }
}

.Title {
  font-weight: 900;
  font-size: 16px;
  color: var(--text-color);
  margin: 0;
}

.Content {
  padding: 10px 0;
  line-height: 14px;
}

.CloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
}

.Address {
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  padding: 5px 0;
}

.Link {
  color: var(--hc-blue);
  font-size: 12px;
  font-weight: 700;
}
.Caret {
  position: absolute;
  display: block;
  left: calc(50% - 10px);
  font-size: 16px;
  width: 10px;
  transform: scaleX(1.5);
  border: none;
  line-height: 8px;
  color: white;
  top: 100%;
  text-shadow: 0px 2px 0px #ffffff;
  &::after {
    content: '▼';
  }
}
