@import 'src/legacy/styles/common/variables';
@import 'src/legacy/styles/common/colors';

.GenerateButtonRow {
  text-align: right;
  height: 0;
}

.share-report--row {
  margin: 0px 0px 10px 0px;
}
.cancelButton {
  margin-right: 5px;
}
.copyLinkIcon {
  color: #0a62ff !important;
  vertical-align: top;
  position: relative;
  top: -4px;
  margin-right: 8px;
}
.copyLinkButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;

  div {
    height: 15px;
  }
}
.copyLinkText {
  vertical-align: top;
  color: #0a62ff;
  position: relative;
  top: 0px;
}

.share-report--section-title {
  font-size: 16px;
  font-weight: 900;
  line-height: 22px;
  display: block;
  margin-bottom: 5px;
}
.PermissionRow {
  margin: auto;
  text-align: center;
  padding-bottom: 30px;
}
.PermissionToggleLabel {
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  margin-right: 15px;
  display: inline-block;
  padding: 0 0 15px 0;
}

.share-report--link-config {
  border: 1px solid rgba(0, 0, 0, 0.12);

  .share-report--link-config-row {
    height: 40px;
    display: flex;

    .share-report--permission-select {
      font-size: $font-size-16 !important;
      height: 40px !important;
      padding: 0 15px 0 15px !important;

      div:nth-child(2) {
        height: 40px !important;
      }

      button {
        height: 40px !important;
        fill: $hc-black !important;
      }

      hr {
        border-bottom: none !important;
      }
    }

    .share-report--link {
      line-height: 38px;
      height: 38px;
      border: none;
      font-weight: 300;
      font-family: 'Avenir', sans-serif;
      width: 100%;
    }

    .share-report--url-cell {
      align-self: stretch;
      flex: 1;
      border-right: 1px solid rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      padding: 0 15px 0 15px;
      overflow-y: hidden;
    }

    .share-report--copy-cell {
      position: relative;
      flex: 0 1 130px;
      width: 130px;
      button {
        width: 130px !important;
        min-width: 130px !important;
        height: 100% !important;
        div {
          position: relative;
          top: -2px;
          left: -5px;
        }
      }
    }
  }
}

.share-report--buttons {
  text-align: right;
}

.share-report--section {
  margin-bottom: 25px;
}

.share-report--sub-text {
  color: $hc-black;
  font-size: $font-size-14;
}
