@import '../styles/common/variables.css-module.css';

.EffectiveDateWarning {
  background-color: #ffdc98;
  border-radius: 4px;
  padding: 5px 10px;
  display: inline-block;
  color: var(--text-color);
  border: 1px solid var(--hc-orange);
  font-size: 12px;
  white-space: nowrap;
  height: 24px;
  box-sizing: border-box;
  margin: auto 5px;
  line-height: 12px;
}

.Title {
  padding-right: 10px;
  font-weight: bold;
}

.Value {
  font-weight: 300;
}
