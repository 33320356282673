@import '../../css-variables/variables.css';

.MapMarker {
  position: relative;
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  font-family: 'Avenir', sans-serif;
  color: black;
  background: #c1c1c1;
  text-align: center;
  border-radius: 50% 50% 50% 0;
  border: 2px solid #ffffff;
  transform: rotate(-45deg) translate(45%, -110%);
  margin-bottom: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s;

  &.hoverable:hover {
    transform: rotate(-45deg) translate(calc(45% + 5px), calc(-110% - 5px));
  }

  &.clickable {
    cursor: pointer;
  }
}

.HomeIcon {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(2px, 3px);
}

.Content {
  transform: rotate(45deg);
}

.Label {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 11px;
  transform: translate(-3px, 8px);
  font-weight: 700;
  text-align: center;
  width: 100%;
}

.ListingStatusIndicator {
  height: 9px;
  width: 9px;
  border-radius: 9px;
  box-sizing: border-box;
  border: 1px solid #ffffff;
  position: absolute;
  left: 50%;
  transform: translate(7px, -3px);

  &.sold {
    background-color: var(--color-sold);
  }
  &.pending {
    background-color: var(--color-pending);
  }
  &.active {
    background-color: var(--color-active);
  }
}

.subject {
  background: var(--subject-gradient);
}

.selected {
  background-color: var(--hc-blue-dark);

  .Label {
    color: #ffffff;
  }
}

@keyframes pulse {
  0% {
    transform: translate(-38%, -37%) scale(0.1);
    opacity: 0.8;
  }

  100% {
    transform: translate(-38%, -37%) scale(0.6);
    opacity: 0;
  }
}
.Pulse {
  z-index: 1;
  animation: pulse 1.5s infinite;
  position: absolute;
  top: 100%;
  left: -15px;
}
