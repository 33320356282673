.Controls {
    padding: 15px 5px;
    max-width: 1460px;
    box-sizing: border-box;
    margin: auto;
    width: 100%;
    justify-content: flex-end;
    display: flex;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
}

.Scroll {
    overflow-y: auto;
}

.ListingStatusBriefSeparator {
    max-width: 1460px;
    padding: 15px 10px;
    margin: auto;
    box-sizing: border-box;
    position: sticky;
    z-index: 3;
    background-color: #ffffff;
    top: 0;
}