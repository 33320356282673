.ArchivedReportsModal.Dialog {
  max-width: 900px;
}

.DialogContent {
  min-height: 500px;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0;

  & .SearchInput {
    position: absolute;
    right: 75px;
    top: 15px;
    min-height: initial;

    & input {
      margin-top: 0;
    }
  }
}
.Title {
  margin: 0px;
  padding: 24px 24px 20px;
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
}
.ReportsDropdown {
  display: inline-block;
}
.ReportsDropdown > button {
  border: none !important;
}

.NoReportsMessage {
  position: absolute;
  top: 50%;
  text-align: center;
  width: 100%;
}

.FlatButton {
  margin-right: 15px;
  position: relative;
  top: -5px;
}

/* Mobile */
@media (max-width: 768px) {
  .DialogContent {
    & > div {
      display: flex;
      flex-direction: column;
    }
    & .SearchInput {
      position: static;
      margin-bottom: 5px;

      & input {
        margin-top: 0;
      }
    }
  }
}
