html,
body {
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
}

#react-root,
#app-container {
  width: 100%;
  min-height: 100%;
  height: 100%;
  overflow: auto;
}

* {
  outline: none; //this removes the browsers default focus highlighting
}

.display-block {
  display: block;
}

.text-align-right {
  text-align: right;
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}

.hc-pointer {
  cursor: pointer;
}

.hc-block {
  display: block;
}

.hc-uppercase {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

.avoid-print-page-break {
  page-break-inside: avoid;
}

.lesser-text {
  font-size: 0.8em;
  color: lighten($text-color, 40%);
}

.text-ellipsis {
  /* element must have a defined width for this to work */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cursor-pointer {
  cursor: pointer;
}

.no-pointer-events-within * {
  pointer-events: none;
}

.hc-action {
  @extend .hc-pointer;
  color: $hc-blue;
}

.hc-action-bold {
  @extend .hc-action;
  font-weight: 600;
  font-size: 16px;
  user-select: none;
}

a {
  text-decoration: none;
  color: $hc-support-icon;
}

sup {
  font-size: $font-size-13;
}

.text-align-center {
  text-align: center;
}

.error-modal-timestamp {
  position: absolute;
  font-size: 10px;
  bottom: 10px;
  color: #ccc;
}

/* Can be uncommented to check for classNames of "undefined" */
/* .undefined {
  position: relative;
  border: 5px solid red !important;
  &::after {
    content: 'className="undefined"';
    color: red;
    position: absolute;
    top: -20px;
    left: -5px;
    font-size: 12px;
  };
} */
