@import '../../styles/common/variables.css-module.css';

.loading-spinner--overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  transition: background-color 0.5s;
  z-index: var(--zIndexHighest);
  &.light {
    background-color: var(--hc-overlay-light);
  }
  &.dark {
    background-color: var(--hc-overlay);
  }
}
.loading-circle-inner {
  stroke: var(--hc-blue) !important;
}

.loading-spinner--full-page {
  position: fixed;
}

.SpinnerPosition {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > div {
    height: 100%;
    width: 100%;
    svg {
      height: 100%;
      width: 100%;
    }
  }
}

.xsmall {
  height: 20px;
  width: 20px;
}

.small {
  height: 40px;
  width: 40px;
}

.medium {
  height: 60px;
  width: 60px;
}

.large {
  height: 80px;
  width: 80px;
}

.xlarge {
  height: 100px;
  width: 100px;
}
