@import '../../css-variables/variables.css';

.DialogWrapper {
  font-family: var(--hc-font);
  z-index: 199;
  bottom: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
}

.Dialog {
  max-height: calc(100vh - 40px);
  height: auto;
  width: 50vw;
  max-width: calc(100vw - 40px);
  overflow: hidden;
  border-radius: var(--hc-border-radius);
  background-color: #fff;
  box-shadow: 0 17px 50px 0 rgba(0, 0, 0, 0.19),
    0 12px 15px 0 rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: translate(-50%, -75%);
  transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1),
    transform 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transition-delay: 0.2s;

  &.small {
    width: 100%;
    max-width: 525px;
  }
  &.normal {
    width: 50vw;
  }

  &.auto {
    width: auto;
  }

  &.large {
    width: 96vw;
  }

  &.fullscreen {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
  }
}

.Header {
  border-radius: var(--hc-border-radius) var(--hc-border-radius) 0 0;
  background-color: #f7f9fa;
  flex: 0 1 80px;
  align-items: center;
  padding: 20px 50px 20px 1.875rem;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  min-width: 100%;
  max-width: 100%;
  width: 100%;
  color: var(--text-color);
  box-sizing: border-box;

  h1 {
    font-size: 24px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
  }

  h2 {
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 0;
  }
}

.CloseButton {
  position: absolute;
  border: none;
  background: transparent;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.DialogContent {
  flex: 1 1 auto;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1.25rem 1.875rem 1.875rem;
  overflow: auto;
}

.Actions {
  padding: 1.25rem 1.875rem 1.875rem;
  text-align: right;
}

.active {
  .Dialog {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

.visibleOverflow {
  .Dialog,
  .DialogContent {
    overflow: visible;
  }
}

.DialogOverlay {
  opacity: 0.6;
  pointer-events: all;
  background-color: rgb(0, 0, 0);
  transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.noPadding {
  padding: 0;
}
