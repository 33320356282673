@import 'src/legacy/styles/common/variables';
@import 'src/legacy/styles/common/colors';
@import 'src/legacy/styles/common/animations';

/* Z-indexes set by leaflet for each DOM element:
 * *************************************************
 * mapPane     'auto'  Pane that contains all other map panes
 * tilePane     200    Pane for GridLayers and TileLayers
 * overlayPane  400    Pane for vector overlays (Paths), like Polylines and Polygons
 * shadowPane   500    Pane for overlay shadows (e.g. Marker shadows)
 * markerPane   600    Pane for Icons of Markers
 * tooltipPane  650    Pane for tooltip.
 * popupPane    700    Pane for Popups.
 */

/************* Leaflet Control Overrides *************/

.leaflet-control-container {
  font-family: 'Avenir', sans-serif;
  font-size: 14px;
  // Minimap baselayer toggle control container
  .leaflet-bottom.leaflet-left {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 90px;
    height: 90px;
    /* Above map tiles */
    z-index: 600;
    .leaflet-control-minimap {
      box-shadow: none;
      border: 1px solid $hc-border;
      width: 100% !important;
      height: 100% !important;
    }
    .map-baselayers-control-label {
      left: 4px;
      bottom: 15px;
    }
  }
  .leaflet-top {
    position: static;
  }
  .leaflet-top.leaflet-left,
  .leaflet-top.leaflet-right,
  .leaflet-bottom.leaflet-right,
  .leaflet-control {
    /* Above map tiles and layers */
    z-index: 2000;
  }
  /* Override Leaflet zoom control css */
  .leaflet-control-zoom {
    position: absolute;
    margin: 0;
    top: 10px;
    left: 20px;
    overflow: hidden;
    width: 40px;
    border: none !important;
    border-radius: 20px;
    box-shadow: $hc-box-shadow !important;
  }
  .leaflet-control-zoom .leaflet-control-zoom-in {
    border-bottom: none;
  }
  .leaflet-control-zoom .leaflet-control-zoom-in {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }
  .leaflet-control-zoom .leaflet-control-zoom-out {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .leaflet-control-zoom .leaflet-control-zoom-in,
  .leaflet-control-zoom .leaflet-control-zoom-out {
    font-size: 24px;
    font-weight: 100;
    line-height: 40px;
    width: 40px;
    height: 40px;
    color: #4a4a4a;
  }
  .leaflet-control-scale .leaflet-control-scale-line {
    line-height: 0.9em;
    font-size: 9px;
  }
}

.leaflet-popup {
  margin-bottom: 5px !important;
  .leaflet-popup-content-wrapper,
  .leaflet-popup-content {
    margin: 0;
    box-shadow: none;
    background: transparent;
  }
  .leaflet-popup-tip {
    display: none;
  }
  .leaflet-popup-tip-container {
    height: 0;
  }
  .map-marker-popup--property-search {
    background-color: $hc-blue;
    padding: 10px;
    border-radius: 4px;
    letter-spacing: 1px;
    text-transform: uppercase;
    min-width: 175px;
    cursor: pointer;
    .marker-popup--label {
      color: white;
    }
  }
  .map-marker-popup--comps {
    width: 420px;
    box-shadow: $hc-box-shadow;
    background: white;
  }
  .comps-property-preview-card--details {
    margin: 15px 10px 5px 15px;
  }
}
.leaflet-popup-pane .leaflet-popup .leaflet-popup-close-button {
  top: 0;
  right: 2px;
  font-size: 25px;
  line-height: 25px;
  font-weight: 400;
  z-index: 1;
  cursor: pointer;
  position: absolute;
  color: #c3c3c3;
}

/************* Custom layer controls  *************/

.layer-controls-container {
  font-family: 'Avenir', sans-serif;
  box-shadow: $hc-box-shadow;
  .control-item-label {
    color: $text-color;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: -0.01px;
  }
  .control-item-description {
    display: none;
    font-size: 11px;
    font-weight: 300;
    line-height: 15px;
    color: #4a4a4a;
    text-align: left;
    letter-spacing: -0.01px;
  }
  .control-item-button {
    color: #4a4a4a;
    height: 55px;
    width: 55px;
    box-sizing: border-box;
    > span > svg {
      height: 22px;
    }
  }
  .control-item-button .element-to-stroke {
    stroke: #4a4a4a;
  }
  .control-item-button .element-to-fill {
    fill: #4a4a4a;
  }
  .control-item-button--active {
    border-color: $hc-blue;
  }
  .layer-option-label {
    font-size: 10px;
    font-weight: 500;
  }
  .layer-option--active .layer-option-label {
    font-size: 12px;
    font-weight: 900;
  }
  .layer-control-active-group-options,
  .layer-controls-legend-wrapper {
    height: 0;
    opacity: 0;
    transition: all 0.5s;
  }
  .layer-control-active-group-options {
    overflow: hidden;
  }
  .layer-control-active-group-options.shown {
    height: 40px;
    opacity: 1;
  }
  .layer-controls-legend-wrapper.shown {
    height: 24px;
    opacity: 1;
  }
  .label-emphasis {
    font-size: 16px;
  }
  .legend-item-color {
    /* The text color within legend color blocks */
    color: #00242b;
  }
  .legend-item-label {
    color: #00242b;
    font-size: 12px;
    font-weight: 500;
  }
}

// Minimap baselayer toggle control container
.map-container .leaflet-bottom.leaflet-left {
  bottom: 20px;
  left: 20px;
  width: 100px;
  height: 100px;
  .leaflet-control-minimap {
    border: solid 2px white;
    box-shadow: $hc-box-shadow;
  }
  .map-baselayers-control-label {
    bottom: 5px;
  }
}
.map-container.with-large-baselayer-control .leaflet-bottom.leaflet-left {
  bottom: 40px;
  top: initial;
  left: 40px;
  width: 150px;
  height: 150px;
}
.map-container.with-full-width-legend {
  .layer-control-active-group-options.shown {
    height: 32px;
  }
  .layer-controls-legend-wrapper.shown {
    height: 50px;
  }
}

/****** Custom layer controls sidepanel *******/

.map-controls-sidepanel {
  font-family: 'Avenir', sans-serif;
  position: absolute;
  overflow-y: auto;
  right: 0;
  top: 0;
  bottom: 0;
  width: 500px;
  display: flex;
  flex-direction: column;
  /* Above map tiles/layers */
  z-index: 600;
  padding: 26px 20px 0 30px;
  background-color: white;
  cursor: auto;

  .layer-controls-container {
    position: static;
    margin-top: 0px;
    box-shadow: none;
    .control-item-description {
      display: block;
    }
  }
  .layer-controls {
    flex-wrap: wrap;
    padding: 25px 0 0 0;
  }
  .control-item {
    max-width: initial;
    width: calc(33% - 10px);
    margin-bottom: 20px;
    flex-grow: 0;
    flex-basis: auto;
  }
  .group-options {
    margin-top: 10px;
  }
  .layer-control-active-group-options.shown .group-options {
    height: 35px;
  }
  .map-controls-legend {
    margin-bottom: 15px;
  }
  .map-controls-sidepanel--header {
    color: #3f3f3f;
    font-size: 23px;
    font-weight: 900;
    line-height: 31px;
    text-align: center;
    margin: 0;
  }
}

/************* Draw Tooltip *************/

.leaflet-draw-tooltip {
  font-family: 'Avenir', sans-serif;
  min-width: 100px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.84);
  border: 1px solid #eaeaea;
  min-width: 130px;
  text-align: left;
  border-radius: 4px;
  padding: 20px;
  font-size: 14px;
  font-weight: 300;
  line-height: 19.2px;
  line-height: 1.2rem;
  text-align: left;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  margin-left: 15px;
}

.leaflet-draw-guide-dash {
  font-size: 1%;
  opacity: 0.6;
  position: absolute;
  width: 5px;
  height: 5px;
}

.map-draw {
  .leaflet-div-icon {
    background-color: #fff;
    border-color: #3388ff;
    border-width: 3px;
    border-radius: 50%;
    box-shadow: none;
    z-index: 3999 !important;
    height: 16px !important;
    width: 16px !important;
    margin-left: -8px !important;
    margin-top: -8px !important;
  }
}

.map-draw--polygon {
  .leaflet-div-icon {
    background: #3388ff;
    border-color: #fff;
    border-width: 3px;
    box-shadow: 0px 3px 7px 3px rgba(0, 0, 0, 0.11);
    height: 16px !important;
    width: 16px !important;
    margin-left: -8px !important;
    margin-top: -8px !important;
  }

  .leaflet-div-icon ~ .leaflet-div-icon {
    background-color: #fff;
    border-color: #3388ff;
    box-shadow: none;
    height: 16px !important;
    width: 16px !important;
    margin-left: -8px !important;
    margin-top: -8px !important;
  }
}

/************* Markers *************/

.map-marker-wrapper {
  z-index: 2;
  /* Override Leaflet inline styles */
  width: 28px !important;
  height: 38px !important;
  &.map-marker-wrapper--large {
    width: 50px !important;
    height: 60px !important;
  }
}
.map-marker {
  display: inline-block;
}
.map-marker-wrapper:not(.map-marker-wrapper--no-hover) .map-marker {
  animation: return 0.3s forwards;
  &:hover {
    animation: raise 0.3s forwards;
    z-index: 1000 !important;
  }
}
.map-marker-label {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 11px;
  z-index: 2;
  span {
    color: #4a4a4a;
    flex: 1;
  }
}

.property-status-indicator {
  height: 8px;
  width: 8px;
  border-radius: 4px;
  box-sizing: border-box;
  margin-left: 14px;
  border: 1px solid #ffffff;
}

.property-status--sold {
  background-color: #fd8a5f;
}

.property-status--leased {
  background-color: #fd8a5f;
}

.property-status--active {
  background-color: #70d48a;
}

.property-status--pending {
  background-color: #feb913;
}

@keyframes pulse {
  0% {
    transform: translate(-38%, -37%) scale(0.1);
    opacity: 0.8;
  }

  100% {
    transform: translate(-38%, -37%) scale(0.6);
    opacity: 0;
  }
}
.map-marker-pulse {
  z-index: 1;
  animation: pulse 1.5s infinite;
  position: absolute;
}

/* Small pin */
.map-marker-icon {
  position: relative;
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  font-family: 'Avenir', sans-serif;
  color: rgba(0, 0, 0, 1);
  background: #c1c1c1;
  text-align: center;
  border-radius: 50% 50% 50% 0;
  border: 2px solid $white;
  transform: rotate(-45deg);
  margin-bottom: 0;
  z-index: 2;
}
/* Large pin */
.map-marker-wrapper--large {
  .map-marker-icon {
    width: 52px;
    height: 52px;
  }
  .map-marker-label {
    transform: translate(1px, 0);
  }
  .map-marker-svg--home-icon {
    width: 26px;
    transform: translate(-1px, 2px);
  }
}
/* Marker shadow */
.map-marker-wrapper::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 20px;
  background: radial-gradient(
    ellipse 15px 8px,
    rgba(0, 0, 0, 0.1),
    transparent
  );
  z-index: -1;
}
.map-marker-wrapper.map-marker-wrapper--large::after {
  bottom: -13px;
}

.map-marker-svg--home-icon {
  width: 19px;
}
/* Marker home icon - override inline style to ensure it's above all comp icons */
.map-marker-wrapper--home .map-marker-icon {
  background: $hc-blue;
}
.map-marker-wrapper--home .map-marker-label svg {
  width: 16px;
  margin-top: 5px;
}
.map-marker-wrapper--home.map-marker-wrapper--large .map-marker-label svg {
  width: 28px;
  margin-top: 5px;
  margin-left: -1px;
}
.map-marker-wrapper--circle .map-marker-icon {
  border-radius: 50% 50% 50% 50%;
}
.map-marker-svg--home-icon .element-to-fill {
  fill: white;
}
.map-marker-wrapper--selected .map-marker-icon {
  background: #2d3b4c;
}
.map-active-group-description-wrapper {
  position: relative;
}
.marker-popup--address {
  letter-spacing: initial;
  text-transform: initial;
  text-align: center;
  max-width: 300px;
  color: #ffffff;
}
.marker-popup--label {
  text-align: center;
  margin-top: 5px;
}
.marker-cluster {
  width: 40px !important;
  height: 40px !important;
  background-color: rgba(74, 74, 74, 0.5) !important;
  > div {
    background-color: #4a4a4a !important;
    transition: transform 0.3s;
    &:hover {
      transform: scale(1.2);
    }
  }
}
.marker-cluster-multi-unit-icon {
  .marker-cluster-multi-unit-label {
    color: #4a4a4a;
    /* Since the label has to be inside the icon, rotate it back to level */
    transform: rotate(45deg) translate(5px, 4px);
    font-size: 15px;
    line-height: 17px;

    * {
      color: inherit;
    }
  }
  .marker-cluster-multi-unit-count {
    font-weight: 600;
  }
}

/************* Misc *************/

.map-active-group-description-wrapper .map-active-group-description-toggle {
  display: none;
  position: absolute;
  top: 0px;
  height: 20px;
  width: 60px;
  left: calc(50% - 30px);
  background-color: white;
  border: 1px solid #ccc;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  z-index: 1000;
  text-align: center;
  transition: top 0.5s;

  &.shown {
    top: -21px;
  }
}
.map-active-group-description {
  display: none;
  height: 0;
  overflow: hidden;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0;
  transition: height 0.3s;

  > div {
    margin: 10px 60px 15px 60px;
  }
}
.map-active-group-description.shown {
  height: 70px;
}

.map-container {
  /* Fix Leaflet bug that disallows scrolling over map to scroll the page on mobile
     Map panning via 1 finger touch is enabled/disabled separately, using Javascript */
  touch-action: initial;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: 0.3s width;
  z-index: 1;

  &.with-controls-sidepanel {
    width: calc(100% - 450px /*sidepanel width*/);
    overflow: visible;
  }
  .map-controls-sidepanel {
    right: -450px;
    transition: 0.3s right;

    .layer-control-active-group-options.shown .group-options {
      height: auto;
      margin-top: 0;
    }
  }
  .layer-controls-legend-wrapper.shown {
    margin-bottom: 20px;
  }
  .layer-controls-container .layer-control-active-group-options.shown {
    height: auto;
  }

  .map-home-button {
    position: absolute;
    top: 120px;
    margin-right: 0;
    margin-top: 0;
    right: 20px;
    box-shadow: $hc-box-shadow;
    border-radius: 50%;
    font-size: 24px;
    line-height: 40px;
  }
  .leaflet-control-zoom .leaflet-control-zoom-in,
  .leaflet-control-zoom .leaflet-control-zoom-out,
  .map-home-button {
    width: 40px;
    height: 40px;
    background-color: white;
    color: #4a4a4a;
  }

  .show-map-controls-button {
    width: 50px;
    height: 50px;
    /* Above map tiles */
    z-index: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50%;
    box-shadow: $hc-box-shadow;
    cursor: pointer;
    margin-bottom: 12px;

    .SVGInline {
      flex: 0 0 30px;
      width: 30px;
      height: 30px;
      margin-top: 2px;
    }
    .element-to-fill {
      fill: #4a4a4a;
    }
  }

  .layer-controls-disabled {
    .control-item {
      pointer-events: none;
      .control-item-button {
        opacity: 0.25;
      }
    }
  }

  .light-label {
    color: #ffffff;
  }

  @media (max-width: 768px) {
    .map-home-button,
    .leaflet-control-zoom,
    .leaflet-control-minimap,
    .leaflet-control-minimap-mask {
      display: none;
    }
    .layer-controls-container {
      /* Fix map layers sporadically overlapping controls on fast mobile map panning */
      position: fixed;
    }
    .map-active-group-description-toggle,
    .map-active-group-description {
      display: block;
    }
    .leaflet-popup .map-marker-popup--comps {
      /* Full-width marker popup on mobile */
      width: calc(100vw - 40px);
    }
  }
}

@media print {
  .map-container {
    page-break-inside: avoid;
  }
}
