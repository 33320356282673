.img-placeholder {
  height: 100%;
  width: 100%;
  position: relative;
}

.img-placeholder--icon {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 24px;
  width: 24px;
}
