@import '../styles/common/variables.css-module.css';

.Percent {
  padding-right: 5px;
}

.positive {
  color: var(--positive-change);
}

.negative {
  color: var(--negative-change);
}
