@import '../styles/common/variables.css-module.css';

.input {
  padding: 0 !important;
}

.inputElement {
  display: block;
  outline: none;
  border: 1px solid #c9c9c9;
  border-radius: 3px;
  width: 100%;
  height: 40px;
  padding: 3px 12px 0 !important;
  box-sizing: border-box;
  font-family: Avenir W01, Avenir, sans-serif;
  font-size: 16px;
  border-left: 1px solid #c9c9c9 !important;
  border-right: 1px solid #c9c9c9 !important;
  border-top: 1px solid #c9c9c9 !important;
  border-bottom: 1px solid #c9c9c9 !important;
  border-radius: 3px;

  &.filled {
    border-color: #494949 !important;
  }
}

.label {
  top: 13px !important;
  left: 12px !important;
}

.inputElement.filled ~ .label {
  font-size: 12px !important;
  top: -8px !important;
  background: white !important;
  left: 10px !important;
  padding: 0 3px !important;
  color: #494949 !important;
  font-weight: 600 !important;
}

.header {
  background-color: var(--hc-blue) !important;
}

.day.active > span {
  background: var(--hc-blue) !important;
}

.day:hover:not(.active):not(.disabled) > span {
  background: rgb(40 183 211 / 0.4) !important;
}

.year {
  color: #ffffff;
}

.date {
  color: #ffffff;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
