.SubjectHeaderSearch {
  margin-right: 25px;
}
.SearchInputWrapper {
  min-width: 360px;
  transition: width 0.3s;
  float: right;
  position: relative;
  z-index: 101;
}

.MobileInput {
  position: fixed;
  left: 30px;
  top: 70px;
  width: calc(100vw - 60px);
}

.MobileIcon {
  float: right;
  position: relative;

  > span {
    position: absolute;
    top: 0;
    left: 0;

    > svg {
      fill: #4a4a4a;
      width: 25px;
    }
  }
}

.Overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 100;
  left: 0;
  margin: 0;
  padding: 0;
  background-color: rgba(33, 33, 33, 0.48);
}
