.MultiInput {
  display: flex;
  border: 1px solid #eaeaea;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
}

.DropdownCell {
  flex: 0 0 auto;
}

.InputCell {
  flex: 1;

  > div {
    height: 100%;
    width: 100%;
  }

  input {
    border: none;
    background: transparent;
    border-radius: 0;
    width: 100%;
    box-sizing: border-box;
  }
}
