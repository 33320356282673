@import '../styles/common/variables.css-module.css';

.AttributeGroupContainer {
  display: flex;
  justify-content: stretch;
  min-height: 200px;
}

.AttributeGroup {
  margin: 0 25px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}

.AttributeGroupHeader {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  min-height: 46px;
  box-sizing: border-box;
}
.AttributeGroupHeaderLabel {
  font-weight: 900;
  font-size: 14px;
  flex: 0 0 25%;
  box-sizing: border-box;
  padding-left: 13px;
}

.Attribute {
  display: flex;
  align-items: center;
  border-bottom: var(--hc-border);
  padding: 5px 10px;
  min-height: 38px;
  box-sizing: border-box;
}

.AttributeLabel {
  font-size: 12px;
  font-weight: 400;
  color: #707070;
  line-height: 19px;
  align-self: flex-start;
  padding-top: 3px;
  flex: 0 0 25%;
}

.AttributeValues {
  flex: 0 0 75%;
  display: flex;
}

.AttributeHC,
.AttributeMLS,
.AttributePR {
  flex: 0 0 33.33%;
  width: 33.33%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 13px;
}

.AttributeValueDisplay {
  font-size: 14px;
  font-weight: 500;
  flex: 1;
  position: relative;
  top: -2px;
}

.AdjustmentBadge {
  flex: 0 0 84px;
  display: flex;
  align-items: center;
}

.TextButton {
  padding: 15px 3px;
  margin-right: 15px;
}

.ActionBar {
  .TextButtonCancel {
    color: #9b9b9b;
  }
}

@media (max-width: 1200px) {
  .AttributeGroup {
    margin: 0 10px;
  }
}

@media (max-width: 1100px) {
  .AttributeGroupContainer {
    display: block;
  }
  .AttributeGroup {
    margin: 0;
  }
}

@media (max-width: 720px) {
  .Attribute {
    display: block;
    padding: 0px 10px;
  }
  .AttributeGroupHeader {
    padding: 0 10px;
    border-bottom: 1px solid #4a4a4a;
  }
  .AttributeGroupHeaderLabel {
    flex: 0 0 33.33%;
    width: 33.33%;
    height: 46px;
    line-height: 46px;
    padding: 0 5px;

    &:first-child {
      display: none;
    }

    &:nth-child(odd) {
      background-color: var(--grey-bg-light);
    }
  }

  .AttributeLabel {
    position: absolute;
    margin-top: 5px;
  }

  .AttributeHC,
  .AttributeMLS,
  .AttributePR {
    padding: 30px 5px 0;

    &:nth-child(even) {
      background-color: var(--grey-bg-light);
    }
  }
}
