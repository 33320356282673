.CommentIcon {
  display: inline-block;
  position: relative;
}

.CommentIconIndicator {
  background-color: #fd8a5f;
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  position: absolute;
  left: 50%;
  transform: traslateX(150%);
}
