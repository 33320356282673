@import '../styles/common/variables.css-module.css';

.KeywordSearch {
  padding: 0 15px;
  border-right: var(--hc-border);
  flex: 0 0 230px;
  display: flex;
}

.InputCell {
  flex: 0 1 100%;
}
.MatchesCell {
  padding: 0 0 0 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.KeywordMatches {
  height: 25px;
  padding: 0 7px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;

  &.matches {
    background: var(--keyword-color-dark);
    cursor: pointer;
  }

  &.noMatches {
    background: #ccc;
  }
}

.MatchesList {
  padding: 0px !important;
  max-height: calc(100vh - 250px);
  overflow: auto;
}

.Match {
  padding: 10px 15px 10px 10px;
  cursor: pointer;

  &:hover {
    background-color: #f6f7f8;
  }
}

.LoadingSpinner {
  margin-left: 0px !important;
}
