.chevron-down,
.chevron-up {
  display: inline-block;
  width: 13px;
  margin-left: 10px;
  svg {
    /* IE11 needs a defined height */
    height: 8px;
  }
}
