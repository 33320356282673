@import '../styles/common/variables.css-module.css';

.CompsSelectionMapPage {
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 100%;
}

.HeatmapControlPanel {
  .ControlsSidePanel {
    position: static;
    width: 380px;
    box-sizing: border-box;
    padding: 20px 10px;
  }
}

.Header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 64px;
  background-color: #70d48a;
  text-align: center;
}

.HeaderLabel {
  font-size: 16px;
  font-weight: 900;
  text-align: center;
  color: #fff;
}

.MapWrapper {
  height: calc(100% - 64px);
  width: 100%;
  padding-top: 64px;
  display: flex;
  flex: 1;

  .LocationUnknownWarningContainer {
    top: 85px !important;
    position: absolute;
  }
}

.Map {
  position: relative !important;
  flex: 1;
}

.LayerControls {
  flex: 0 0 0px;
  width: 0px;
  transition: flex 0.3s;
  overflow: hidden;
  display: none;

  &.controlsVisible {
    flex: 0 0 380px;
    width: 380px;
    display: block;
  }
}

.CloseButton {
  float: right;
  background: transparent;
  border: none;
  margin: 18px 20px 0 0;
  cursor: pointer;
}

.HeaderLabel {
  margin: auto;
  text-align: center;
  line-height: 64px;
}

.CompStats {
  position: absolute !important;
  box-sizing: border-box;
  right: 25px;
  box-shadow: var(--hc-box-shadow-soft);
  z-index: 1;
  bottom: 80px;
  width: 185px;
  background-color: #ffffff;
  padding: 0 15px 10px 15px;
}

.SubmitButton {
  position: absolute !important;
  right: 25px;
  z-index: 1;
  bottom: 20px;
}

.Tooltip {
  position: absolute;
  bottom: 100%;
  margin-left: -147px;
  margin-top: -9px;
}

.hidden {
  display: none;
}

@media (max-width: 550px) {
  .SubmitButton {
    width: calc(100% - 50px);
  }
}
