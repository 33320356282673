@import '../styles/common/variables.css-module.css';

.Container {
  border: 1.25px solid #4a4a4a;
  padding: 0 15px 10px;
}

.Heading {
  font-size: 16px;
  font-weight: 900;
}

.Adjustment {
  font-size: 16px;
  font-weight: 900;
  text-align: right;
  position: relative;
  height: 18px;
}

.Loading {
  > div {
    right: 0;
    left: auto;
  }
}

.positive {
  color: var(--positive-change);
}
.negative {
  color: var(--negative-change);
}
