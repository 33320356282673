@import '../../css-variables/variables.css';

:root {
  --toggleOptionPadding: 16px;
}

.Toggle {
  display: inline-block;
  border: 2px solid var(--border-color);
  margin: 2px 0;
  border-radius: 6px;
  background-color: var(--border-color);
  position: relative;
  user-select: none;
  white-space: nowrap;
}

.Option {
  display: inline-block;
  font-size: 12px;
  text-align: center;
  cursor: default;
  position: relative;
  z-index: 2;
  padding: 7px var(--toggleOptionPadding) 5px var(--toggleOptionPadding);
  font-weight: 500;
  box-sizing: border-box;

  &:not(.selected) {
    cursor: pointer;
  }

  &.disabledOption {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.SelectedMask {
  position: absolute;
  z-index: 1;
  top: 0;
  transition: left 0.3s;
  box-sizing: border-box;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  border-radius: 6px;
  height: 100%;
  padding: 0 var(--toggleOptionPadding) 0 var(--toggleOptionPadding);
}

.disabled {
  & .SelectedMask {
    background-color: transparent;
  }

  & .Option {
    cursor: default;
    color: var(--text-light-color);
  }
}
