.CompsButtons {
  z-index: 10;
  position: absolute;
  bottom: 15px;
  right: 50%;
  transform: translateX(calc(50% - 100px));
  .SelectCompsButton {
    margin-right: 10px;
  }
}

@media (max-width: 1250px) {
  .CompsButtons {
    display: none;
  }
}
