.SearchResultCta {
  display: flex;
  align-items: center;
  padding: 10px 15px 10px 0;
  overflow: hidden;
}

.EffectiveDateCell {
  flex: 0 0 120px;
  margin-right: 15px;
}

.DialogTitle {
  white-space: nowrap;
}

.ClientIdInput {
  flex: 1 0 120px;
  margin-right: 15px;
}

.MetadataCell {
  flex: 0 0 calc(33.333% - 15px);
  box-sizing: border-box;
  padding-right: 15px;
  overflow: hidden;
  position: relative;
  top: -7px;

  &:last-child {
    flex: 0 0 33.333%;
  }

  label {
    padding: 5px 0;
    font-size: 12px;
  }

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 5px;
  }
}
