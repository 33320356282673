@import '../styles/common/variables.css-module.css';

.AttributeAdjustmentBadge {
  background-color: var(--positive-change);
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  margin-left: 15px;
  padding: 4px 6px;
  text-align: center;
}

.TooltipLabel {
  font-size: 12px;
  font-weight: 900;
}
