@import '../styles/common/variables.css-module.css';

.LinkToggle {
  display: flex;
  position: relative;
}

.Link {
  white-space: nowrap;
  font-size: 14px;
}

.Spacer {
  height: 14px;
  width: 1px;
  margin-left: 10px;
  padding-right: 10px;
  border-left: 1px solid #e0e0e0;
  position: relative;
  top: 2px;
}

.SelectedBar {
  position: absolute;
  bottom: -7px;
  height: 2px;
  width: 100px;
  transition: left 0.3s, width 0.3s;
  background: var(--hc-orange);
}

.LinkWrapDisabled {
  cursor: not-allowed;
}

.disabled {
  pointer-events: none;
  color: var(--text-light-color);
}

.hidden {
}

.selected {
  font-weight: 900;
}
