@import '../../css-variables/variables.css';

.Label {
  font-size: 12px;
  line-height: 30px;
  font-weight: 600;
  color: var(--text-color);
}

.Date {
  font-size: 12px;
  color: var(--text-light-color);
  font-weight: 100;
}

.Value {
  font-size: 26px;
  line-height: 26px;
  font-weight: 900;
}

.active {
  color: var(--color-active);
}

.pending {
  color: var(--color-pending);
}

.sold {
  color: var(--color-sold);
}
