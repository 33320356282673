@import '../../styles/common/variables.css-module.css';
.HcsButton {
  &.button,
  &.button:hover {
    border-radius: 20px;
    font-weight: 800;
    font-size: 12px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 1px solid;
    box-shadow: none;
    transform: translate(0);
    background: #ffffff;
    color: var(--hc-blue);
    &:disabled {
      border-color: transparent !important;
    }
  }
  &.primary,
  &.primary:hover {
    background: var(--hc-blue);
    color: #ffffff;
    border-color: var(--hc-blue);
  }
}
