@import '../styles/common/variables.css-module.css';

.AttributeValue {
  font-size: 12px;
  font-weight: 800;
  color: var(--text-color);
  flex: 1;
  line-height: 21px;
  max-height: 100%;
}

.ExpandButton {
  margin-left: 5px;
  font-weight: 700;
  cursor: pointer;
}

.loading {
  text-align: left;
  content: '';
  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis steps(4, end) 2000ms infinite;
    content: '\2026'; /* ascii code for the ellipsis character */
    color: var(--text-light-color);
    width: 0px;
  }
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
