.CompsCompareSelected {
  width: 100%;
  max-width: 100%;
  overflow: auto;
}

.PropertyWrapper {
  max-height: calc(100% - 70px);
  height: calc(100% - 70px);
  overflow: auto;
}

.Header {
  z-index: 3;
  width: 100%;
}
