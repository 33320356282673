.hc-field-mulit-email-text {
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px 0 15px;
  font-family: 'Avenir', sans-serif;
}

@media (max-width: 400px) {
  .hc-field-mulit-email-text {
    font-size: 15px;
  }
}
