@import '../styles/common/variables.css-module.css';

.ResizableContainer {
  position: relative;
  transition: width 0.3s;
}

.Resizer {
  position: absolute;
  z-index: 9999999;
  top: 50%;
  width: 20px;
  height: 45px;
  background-color: #FFFFFF;
  border-radius: 4px;
  cursor: grab;
  transition: left 0.3s;
  border: 1px solid var(--hc-blue);
  border-radius: 45px;

  &.positioned-right {
    transform: translate(10px, -50%);
    right: 0;
  }
  &.positioned-left {
    transform: translate(-10px, -50%);
    left: 0;
  }
  &.resizeOnClick {
    cursor: pointer;
  }
}

.resizing {
  transition: width 0s, left 0s;
  cursor: grabbing;
}

.ResizerIcon {
  display: block;
  margin-top: 11px;
  margin-left: 1px;

  & svg {
    transform: scaleY(1.2);
    height: 24px;
  }

  & svg :global .element-to-fill {
    fill: var(--hc-blue);
  }

  &.ResizeIconRight {
    transform: scaleX(-1);
  }
}

/* For now, we're not supporting resizing on mobile */
@media (max-width: 480px) {
  .Resizer {
    display: none;
  }
}
