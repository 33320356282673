@import '../../styles/common/variables.css-module.css';

/* react-toolbox input underline */
.bar {
  display: none;
}
/* react-toolbox input character counter */
.counter {
  display: none;
}
/* react-toolbox input wrapper element */
.input {
  width: 100%;
  padding: 0 !important;
  > input {
    font-family: 'Avenir', sans-serif;
    border-radius: 0.1875rem;
    border: 0.0625rem solid #dedede;
    height: 27px;
    padding: 0 0.625rem;
    font-weight: 500;
    color: #4a4a4a;
    width: 100%;
    max-width: 200px;
    font-size: 12px;
  }
}
.header {
  background-color: var(--hc-blue) !important;
  * {
    color: #ffffff !important;
  }
}
.DatePicker {
  .active {
    > span {
      background-color: var(--hc-blue) !important;
    }
  }

  .day {
    &:hover {
      &:not(.active) {
        > span {
          /* --hc-blue at 0.5 opacity */
          background-color: #9fdcea;
        }
      }
    }
  }
}

.Input {
  width: 100%;
}

.months,
.years {
  color: var(--hc-blue) !important;
}
