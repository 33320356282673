.MapCluster {
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  border-radius: 100%;
  background-color: rgba(80, 184, 243, 0.5);
  position: relative;
  cursor: pointer;

  &:hover {
    .Inner {
      width: calc(100% - 2px);
      height: calc(100% - 2px);
    }

    .Label {
      font-size: 14px;
    }
  }
}

.Inner {
  background-color: #50b8fd;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.3s, height 0.3s;
}

.Label {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  transition: font-size 0.3s;
  pointer-events: none;
}
