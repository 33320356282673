@import '../styles/common/variables.css-module.css';

.action-link-buttons {
  margin-left: 10px;
  & > :not(:last-child) {
    margin-right: 10px;
  }
}

.comments-value {
  white-space: pre-line;
  min-height: 129px;
  border: 1px solid var(--text-color);
  border-radius: 3px;
  padding: 10px;
}

.SaveButtonContainer {
  float: right;
  margin: 28px 0px 40px 0px;
}

.CommentBoxContainer {
  margin: 20px 0px 0px 0px;
}
