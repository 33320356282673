@import '../../styles/common/variables.css-module.css';

.TextButton {
  color: var(--hc-blue);
  font-weight: bold;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  padding: 15px 16px;
  font-size: 14px;
  display: inline-block;
  &:hover {
    color: #02ccee;
  }
}

.TextButton.secondary {
  color: var(--text-color);
  &:hover {
    color: #645f5f;
  }
}

.TextButton.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
