@import 'src/legacy/styles/common/variables';

table.table-excel {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;

  &.hc-lg-padding {
    th,
    td {
      padding: $padding-10 $padding-5;
    }
  }

  thead.hc-table-head,
  tbody.hc-table-body,
  tr {
    display: table;
    table-layout: fixed;
  }

  thead.hc-table-head {
    th {
      padding: $padding-20 0;
      font-size: $font-size-13;
      color: rgba(0, 0, 0, 0.54);
    }
    tr {
      border-bottom: 1px $table-border solid;
    }
  }
  tbody.hc-table-body {
    td {
      border: none;
      border-bottom: 1px $table-border solid;
      color: rgba(0, 0, 0, 0.87);
      font-size: $font-size-14;
      padding: $padding-10 0;
    }
  }

  th,
  td {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  thead {
    th {
      text-align: left;
      font-size: $font-size-10;
      color: $table-header-text;
      background-color: $table-background;
    }
  }
  tbody {
    td {
      background-color: $table-background;
      border: 1px $table-border solid;
    }
  }
}
