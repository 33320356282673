.PropertyStatusChip {
  border-radius: 3px;
  background-color: #f9f9f9;
  padding: 3px 5px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
}

.Status {
  font-family: Avenir;
  font-size: 12px;
  font-weight: 900;
  line-height: 16px;
  margin-right: 10px;

  &.active {
    color: #70d48a;
  }

  &.pending {
    color: #feb910;
  }
}

.Icon {
  font-family: Avenir;
  font-size: 16px;
  font-weight: 900;
  line-height: 16px;
  margin-right: 5px;
  height: 20px;
  width: 20px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  color: #ffffff;

  &.active {
    background-color: #70d48a;
  }

  &.pending {
    background-color: #feb910;
  }
}

.Price {
  color: #4a4a4a;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 900;
  line-height: 22px;
}
