.SortHeaderCell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.SortHeaderCellLabel {
  padding-right: 5px;
}

.InfoTrigger {
  display: block;
  width: 12px;
  height: 12px;
  position: relative;
  top: -6px;
  margin: auto 3px;
}

.HeaderCellInfoIcon {
  > div {
    width: 14px;
    position: relative;
    top: -3px;
    left: 2px;
  }
}
