.CompCompare {
  display: flex;
  justify-content: flex-start;
}

.CompareCol {
  flex: 0 0 330px;
  padding: 15px;
}

.subjectCol {
  background-color: #f7f7f7;
}
