@import '../styles/common/variables.css-module.css';

.SavedFiltersSelector {
  flex: 0 0 234px;
  box-sizing: border-box;
  border-right: var(--hc-border);
  align-self: stretch;
  display: flex;
  position: relative;
  z-index: 4;
}

.SaveFiltersDropdown {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 0 0 6px 6px;
  padding-bottom: 6px;
  background-color: #ffffff;
  box-shadow: 0 10px 14px 0 rgba(0, 0, 0, 0.16);
}

.DropdownButton,
.DropdownLabel {
  height: 54px;
  width: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 24px;
  overflow: hidden;
  box-sizing: border-box;
}

.DropdownButton {
  justify-content: space-between;
  cursor: pointer;
}

.DropdownTitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
}

.DropdownSubtitle {
  font-size: 12px;
  color: var(--text-light-color);
  line-height: 16px;
}

.DropdownButtonLabel {
  font-size: 14px;
  font-weight: 700;
}

.unsaved {
  font-style: oblique;
  color: rgba(74, 74, 74, 0.8);
}

.AlertIcon {
  margin-left: 4px;
  position: relative;
  top: 2px;
}

.DefaultCell {
  flex: 0 0 24px;
}

.LabelCell {
  flex: 1;
  border-bottom: var(--hc-border);
}

.DeleteCell {
  flex: 0 0 24px;
  border-bottom: var(--hc-border);
  > * {
    display: none;
  }
}

.DropdownOption {
  display: flex;
  cursor: pointer;
  height: 40px;
  align-items: stretch;
  background-color: #ffffff;
  font-size: 12px;
  color: var(--text-color);
  > * {
    display: flex;
    align-items: center;
    &:not(.LabelCell) {
      > * {
        display: none;
      }
    }
    &.primary {
      > * {
        display: flex;
      }
    }
  }

  &:last-child {
    > * {
      border: none;
    }
  }

  &:hover {
    > * {
      > * {
        display: flex;
      }
    }
  }
}
