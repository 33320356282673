.wrapper {
  z-index: 1002 !important;
}
.Drawer.drawer {
  background-color: white;
}

.small {
  width: 320px;
}

.medium {
  width: 400px;
}

.large {
  width: 500px;
}

.full {
  width: 100%;
}

/* Mobile */
@media (max-width: 768px) {
  .drawer {
    width: 100%;
  }
}
