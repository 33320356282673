@import '../../css-variables/variables.css';

.ResizableContainer {
  position: relative;
  transition: width 0.3s;
}

.Resizer {
  position: absolute;
  z-index: 198;
  top: 50%;
  width: 20px;
  height: 45px;
  background-color: var(--hc-blue-dark);
  border-radius: 4px;
  cursor: grab;
  /* Change the timeout in the fullWidth and incrementWidth methods if the time here is changed */
  transition: left 0.3s;

  &.positioned-right {
    transform: translate(10px, -50%);
    right: 0;
  }
  &.positioned-left {
    transform: translate(-10px, -50%);
    left: 0;
  }
  &.resizeOnClick {
    cursor: pointer;
  }
}

.resizing {
  transition: width 0s, left 0s;
  cursor: grabbing;
  user-select: none;
}

.ResizerIcon {
  display: block;
  width: 20px;
  pointer-events: none;
  -webkit-touch-callout: none; /* iOS Safari */
  user-select: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -42%);
  &.right {
    transform: translate(-50%, -42%) scaleX(-1);
  }
}

/* For now, we're not supporting resizing on mobile */
@media (max-width: 480px) {
  .Resizer {
    display: none;
  }
}
