@import '../styles/common/variables.css-module.css';

.wrapper {
  position: relative;
}

.dataToggle {
  position: absolute;
  right: 5px;
  top: -53px;
}

.LoadingSpinner {
  svg {
    width: 70px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
}

.PropertyExplorerForecastChartInner {
  height: 100%;
  width: 100%;
}

.NullStateCard {
  margin: 100px auto 0 auto;
}

.ScaleContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.subHeading {
  font-size: 14px;
  color: var(--text-light-color);
  margin-top: 50px;
  line-height: 165%;
}

.PropertyExplorerForecast {
  display: inline-block;
  flex-direction: row;
  width: 100%;
  max-width: 100%;

  & .PropertyExplorerForecastChart {
    width: 100%;
    height: auto;
    min-height: 258px;
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;

    & .Grid line {
      stroke: lightgrey;
      stroke-opacity: 0.7;
      shape-rendering: crispEdges;
    }

    & .Grid path {
      stroke-width: 0;
    }
  }

  & svg text.legend {
    text-anchor: start;
  }

  text.title {
    font-size: 1.2em;
  }

  & h1 {
    margin-bottom: 30px;
  }

  & .ForecastOption {
    font-weight: 600;
    color: #0a62ff;
    cursor: pointer;
  }

  & .ForecastCallouts {
    display: flex;
    justify-content: flex-end;
    height: 75px;

    > div {
      width: 10%;
      min-width: 75px;

      &:last-child {
        margin-right: 15px;
      }
    }

    & .ForecastValueLabel {
      font-weight: bold;
      font-size: 12px;
      text-align: center;
      color: var(--text-color);
    }

    & .ForcastValueDollars {
      font-size: 12px;
      text-align: center;
      color: var(--text-color);
    }

    & .ForecastValue {
      height: 30px;
      width: 66px;
      margin: 8px auto 10px auto;
      font-weight: bold;
      text-align: center;
      display: block;
      vertical-align: bottom;
      box-sizing: border-box;
      padding: 8px 0 5px 0;
      border-radius: 3px;
      color: #fff;
    }

    & .ForecastValuePositive {
      background: var(--hc-green);
    }

    & .ForecastValueNegative {
      background: var(--negative-change);
    }
  }
}

.Tooltip {
  opacity: 0.9;
  border: 3px solid #ffffff;
  background-color: rgba(255, 255, 255, 0.84);
  border-radius: 5px;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  z-index: 1;

  & .TooltipContent {
    padding: 15px 25px;
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  & .InfoRow {
    font-weight: bold;
  }

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 13px 0 13px;
    border-color: white transparent transparent transparent;
    position: absolute;
    bottom: -10px;
    right: calc(50% - 9px);
  }
}

/* Mobile */
@media (max-width: 768px) {
  .ComponentWrapper {
    display: none;
  }
  .Tooltip {
    display: none !important;
  }
}

@media (max-width: 470px) {
  .dataToggle {
    position: relative;
    top: 0;
  }
}
