@import '../../../css-variables/variables.css';

.SearchByMlsNumber {
  display: flex;
  height: 40px;
  width: 100%;
  min-width: 230px;
  box-sizing: border-box;
  position: relative;

  /* > input {
    flex: 1;
    padding: 0 15px;
    height: 100%;
    border: none;
    font-family: var(--hc-font);
    &::placeholder {
      font-family: var(--hc-font);
      color: #d0d0d0;
    }
  } */

  > button {
    flex: 0 0 70px;
  }
}

.Input {
  flex: 1 0 200px;
  padding: 0;
}

.Hits {
  border-radius: 0 0 var(--hc-border-radius) var(--hc-border-radius);
  max-height: calc(100vh - 140px);
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #c7c7c7;
  text-align: left;
}

.Hit {
  display: flex;
  padding: 10px 15px;
  cursor: pointer;
  &.active {
    background-color: var(--border-color);
  }
}

.LoadingContainer {
  display: flex;
  align-items: center;
}

.Loading {
  margin: 5px auto;
}

.NoResults {
  color: #dadada;
  margin: 10px 15px;
}

.Error {
  color: var(--swatch-error);
  font-size: 11px;
  padding: 10px;
}

.Date {
  flex: 0 0 115px;
  padding-right: 30px;
  display: inline-block;
}
