@import '../styles/common/variables.css-module.css';

.Dialog {
  height: calc(100vh - 150px);
}

.DialogContent {
  padding: 0;
}

.DialogContent > div {
  height: calc(100vh - 230px);
}

.StreetViewIcon {
  background: white;
  padding: 5px;
  border-radius: var(--hc-border-radius);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
}

.StreetViewIcon svg {
  height: 18px;
  width: 18px;
}

.StreetView {
  height: calc(100vh - 230px);
}

.NullStateCard {
  height: 80px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .InnerWrapper {
    width: 100%;
    max-width: 100%;
  }
}

.LoadingSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 150px !important;
}
