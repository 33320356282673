@import '../styles/common/variables.css-module.css';

.SimilarityScoreTableCell {
  text-align: center;
  line-height: 17px;
  display: grid;
}

.SimilarityScore {
  font-weight: 600;
  font-size: 14px;
  padding-top: 3px;
  float: left;
  margin-left: 4px;
}
