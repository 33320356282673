.Row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  padding: 5px 10px;
  min-height: 38px;
  box-sizing: border-box;
  justify-content: flex-start;
}

.Label {
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  align-self: flex-start;
  padding-top: 3px;
}

.Value {
  font-size: 14px;
  font-weight: 300;
  flex: 1;
  line-height: 21px;
  max-height: 100%;
}
